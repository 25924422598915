<template>
  <v-card>
    <v-data-table
      class="table-striped"
      :loading-text="`${$t('Loading')}...`"
      sort-by="ID"
      :footer-props="{
        'items-per-page-text': `${$t('Records per page')}:`,
        'items-per-page-options': [15, 25, 50, 100],
      }"
      :headers="translatedTableHeaders"
      :header-props="{ 'sort-icon': '$swap' }"
      :items="objects"
      :items-per-page="-1"
      :loading="tableLoading"
      :no-data-text="$t('Viewing data is prohibited')"
      :no-results-text="$t('Data not found')"
      :sort-desc="true"
    >
      <template #[`item.ACTIONS`]="{ item }">
        <div class="d-inline-flex">
          <v-tooltip bottom color="primary">
            <template #activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                class="mr-4"
                color="primary"
                v-on="on"
                @click="$emit('showZones', item)"
              >
                $location
              </v-icon>
            </template>
            {{ $t("Access zones") }}
          </v-tooltip>
          <template v-if="type === 'local'">
            <v-icon class="mr-4" color="red" @click="$emit('delete', item)">
              $delete
            </v-icon>
            <v-icon
              class="mr-4"
              :color="item.STATUS != 2 ? 'red' : 'green'"
              @click="$emit('block', item)"
            >
              $lock
            </v-icon>
            <v-icon color="primary" @click="$emit('edit', item)">
              $edit
            </v-icon>
          </template>
        </div>
      </template>
      <template #[`item.STATUS`]="{ item }">
        <span :class="item.STATUS !== 1 ? 'red--text' : 'green--text'">
          {{ item.STATUS_NAME }}
        </span>
      </template>
      <template #[`item.LOGO`]="{ item }">
        <img
          :src="
            item.LOGO
              ? convertUserLogo(item.LOGO)
              : require('../../assets/images/default-avatar.png')
          "
          style="width: 25px; height: 25px"
          alt="logo"
        >
      </template>
      <template #[`item.LOGO_WALLET`]="{ item }">
        <img
          :src="
            item.LOGO_WALLET
              ? convertUserLogo(item.LOGO_WALLET)
              : require('../../assets/images/default-avatar.png')
          "
          style="width: 25px; height: 25px"
          alt="logo_wallet"
        >
      </template>
      <template #[`item.SYSTEM_ID`]="{ item }">
        {{ item.STATUS_NAME }}
      </template>
      <template #[`item.REQUEST_TYPE`]="{ item }">
        {{ $t(REQUEST_TYPES.find((elem) => elem.value == item.REQUEST_TYPE)?.text) }}
      </template>
      <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
        {{ pageStart }} - {{ pageStop }} {{  $t('of')  }} {{ itemsLength }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import api from '@/api';
import { mapState } from 'vuex';
import { OBJECT_STATUSES, REQUEST_TYPES } from '@/constants';

export default {
  name: 'LocalObjectsTable',
  props: {
    objects: {
      type: Array,
      default() {
        return [];
      },
    },
    type: {
      type: String,
      default: 'local',
    },
    tableHeaders: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      OBJECT_STATUSES,
      REQUEST_TYPES,
      tableLoading: false,
      externalSystems: [],
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.model,
    }),
    translatedTableHeaders() {
      return this.tableHeaders.map((elem) => ({ ...elem, text: this.$t(elem.text) }));
    },
  },
  mounted() {
    api.externalSystems.getAll().then((res) => {
      res.data.DATA.forEach((system) => this.externalSystems.push({
        text: system.NAME,
        value: system.ID,
      }));
    });
  },
  methods: {
    convertUserLogo(logo) {
      if (!logo) return '';
      if (logo.includes('data:image/png;base64,')) return logo;
      return `data:image/png;base64, ${logo}`;
    },
  },
};
</script>
