<template>
  <div>
    <v-form
      ref="configForm"
      v-model="configFormValid"
      @keyup.native.enter="saveConfig"
    >
      <div v-for="configItem in translatedConfigItems" :key="configItem.SID">
        <div class="headline font-weight-medium mt-5 mb-3">
          {{ configItem.NAME }}
        </div>
        <v-row
          v-for="item in configItem.CONFIGS"
          :key="item.SID"
          align="center"
        >
          <v-col cols="4">
            <span class="subtitle-1">{{
              $i18n.locale === "ru" ? item.NAME : $t(item.NAME)
            }}</span>
          </v-col>
          <v-col cols="3">
            <template v-if="item.TYPE === $options.CONFIG_TYPE.BOOLEAN">
              <v-checkbox
                v-model="item.VALUE"
                color="primary"
                hide-details="auto"
                class="mt-0 d-inline-flex"
              />
            </template>
            <template v-else-if="item.TYPE === $options.CONFIG_TYPE.TIME">
              <v-text-field
                v-model="item.VALUE"
                v-mask="'##:##'"
                :label="$i18n.locale === 'ru' ? item.NAME : $t(item.NAME)"
                hide-details="auto"
                outlined
                :rules="configFormRules[item.SID]"
              />
            </template>
            <template v-else-if="item.TYPE === $options.CONFIG_TYPE.SELECT">
              <v-select
                v-model="item.VALUE"
                :rules="configFormRules[item.SID]"
                :items="
                  transformPossibleValuesToSelectFormat(item.POSSIBLE_VALUE)
                "
                :label="$i18n.locale === 'ru' ? item.NAME : $t(item.NAME)"
                hide-details="auto"
                outlined
                :menu-props="{ offsetY: true }"
                append-icon="$down"
              />
            </template>
            <template v-else>
              <v-text-field
                v-model="item.VALUE"
                :label="$i18n.locale === 'ru' ? item.NAME : $t(item.NAME)"
                hide-details="auto"
                outlined
                :rules="configFormRules[item.SID]"
              />
            </template>
          </v-col>
        </v-row>
        <template v-if="configItem.SID === 'SYSTEM'">
          <v-row
            v-for="(file, index) in translatedConfigFiles"
            :key="file.type"
            align="center"
          >
            <v-col cols="4">
              <span class="subtitle-1">{{ file.name }}</span>
            </v-col>
            <v-col cols="3">
              <div class="grey--text pl-5 subtitle-1">
                {{
                  file.isLoading
                    ? $t("File is loading")
                    : file.value
                    ? $t("File uploaded")
                    : ""
                }}
              </div>
              <v-btn
                v-if="!file.value"
                depressed
                text
                color="primary"
                @click="pickNewFile(index)"
              >
                <v-icon class="mr-2">$upload</v-icon>
                {{ $t("Upload file") }}
              </v-btn>
              <div v-if="file.value" class="d-inline-flex">
                <v-btn
                  text
                  color="primary"
                  :to="'/' + file.value"
                  target="_blank"
                >
                  <v-icon class="mr-2">$paperDownload</v-icon>
                  {{ $t("Download") }}
                </v-btn>
                <v-btn text color="primary" @click="pickNewFile(index)">
                  <v-icon class="mr-2">$paperReplace</v-icon>
                  {{ $t("Replace") }}
                </v-btn>
                <v-btn text color="red" @click="deleteFile(file.type, index)">
                  <v-icon class="mr-2">$delete</v-icon> {{ $t("Delete") }}
                </v-btn>
              </div>
              <div v-if="file.errors.length > 0" class="error--text pl-5">
                {{ file.errors.join('<br />') }}
              </div>
            </v-col>
          </v-row>
        </template>
      </div>
      <input
        ref="newFile"
        type="file"
        style="display: none"
        @change="handleFileUpload"
      />
    </v-form>

    <template v-if="!configItems.length > 0">
      <v-row align="center">
        <v-col cols="3">
          <v-skeleton-loader type="text" max-width="250" />
        </v-col>
        <v-col cols="3">
          <v-skeleton-loader class="srp-field-skeleton" type="button" />
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="3">
          <v-skeleton-loader type="text" max-width="200" />
        </v-col>
        <v-col cols="3">
          <v-skeleton-loader class="srp-field-skeleton" type="button" />
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="3">
          <v-skeleton-loader type="text" max-width="300" />
        </v-col>
        <v-col cols="3">
          <v-skeleton-loader class="srp-field-skeleton" type="button" />
        </v-col>
      </v-row>
    </template>

    <v-divider class="my-5" />
    <template
      v-if="configItems.length && isValueStraightTreeMixin('settings_edit')"
    >
      <v-btn depressed color="primary" class="mb-5" @click="saveConfig">
        {{ $t("Save") }}
      </v-btn>
      <v-btn
        depressed
        color="primary"
        class="ml-5 mb-5"
        outlined
        @click="cancelConfig"
      >
        {{ $t("Cancel") }}
      </v-btn>
    </template>
  </div>
</template>

<script>
import api from "@/api";
import { COMMON_SET_LOADING } from "@/store/types/mutation-types";
import isValueStraightTreeMixin from "@/mixins/isValueStraightTreeMixin.js";
import { showSnackbarMessage } from '@/helpers/helpers';

export default {
  name: "SettingsSystem",
  CONFIG_TYPE: {
    IP: 1,
    BIGINT: 2,
    BOOLEAN: 3,
    TIME: 6,
    SELECT: 8,
  },
  mixins: [isValueStraightTreeMixin],
  data() {
    return {
      configItems: [],
      configFormValid: false,
      configFiles: [],
      editedFileIndex: null,
      configFormRules: {},
      rules: {
        required: (v) => !!v || v === 0 || this.$t("Required field"),
        number: (v) => {
          if (!v) return true;
          if (/^[\d.]*$/.test(v)) return true;
          return this.$t("This field must contain only numbers");
        },
      },
    };
  },
  computed: {
    translatedConfigFiles() {
      return this.configFiles.map((elem) => ({
        ...elem,
        name: this.$t(elem.name),
      }));
    },
    translatedConfigItems() {
      return this.configItems.map((elem) =>
        this.$i18n.locale === "ru"
          ? {
              ...elem,
              NAME: elem.NAME,
            }
          : {
              ...elem,
              NAME: this.$t(elem.NAME),
            }
      );
    },
  },
  mounted() {
    this.loadConfig();
    api.config.getFileLinks().then((res) => {
      if (!res.data.DATA) throw new Error();
      this.configFiles = [
        {
          name: "Logo",
          type: "main_logo",
          value: res.data.DATA.main_logo,
          isLoading: false,
          errors: [],
        },
        {
          name: "Agreement for the processing of personal data",
          type: "personal_agree",
          value: res.data.DATA.personal_agree,
          isLoading: false,
          errors: [],
        },
        {
          name: "Privacy Policy",
          type: "politic_conf",
          value: res.data.DATA.politic_conf,
          isLoading: false,
          errors: [],
        },
      ];
    });
  },
  methods: {
    pickNewFile(index) {
      this.$refs.newFile.click();
      this.editedFileIndex = index;
    },
    deleteFile(type, index) {
      this.clearFilesErrorTexts();
      this.$store.commit(COMMON_SET_LOADING);
      api.config
        .deleteFile(type)
        .then((res) => {
          this.configFiles[index].value = "";
          this.$store.commit(COMMON_SET_LOADING, false);
          showSnackbarMessage(res.data.MESSAGE);
        })
        .catch((error) => {
          this.$store.commit(COMMON_SET_LOADING, false);
          showSnackbarMessage(error.response.data.MESSAGE);
        });
    },
    handleFileUpload() {
      this.clearFilesErrorTexts();
      const newFile = this.$refs.newFile.files[0];
      const formData = new FormData();
      formData.append("TYPE", this.configFiles[this.editedFileIndex].type);
      formData.append("FILE", newFile);
      this.configFiles[this.editedFileIndex].isLoading = true;
      api.config
        .uploadFile(formData)
        .then((res) => {
          if (!res.data.DATA) throw new Error();
          this.configFiles[this.editedFileIndex].isLoading = false;
          this.configFiles[this.editedFileIndex].value = res.data.DATA;
          showSnackbarMessage(res.data.MESSAGE);
        })
        .catch((error) => {
          this.configFiles[this.editedFileIndex].isLoading = false;
          showSnackbarMessage(error.response.data.MESSAGE);
          this.addErrorTextsToConfigFile(error.response.data.ERRORS);
        });
    },
    addErrorTextsToConfigFile(ERRORS) {
      if (!ERRORS) return false;
      Object.keys(ERRORS).forEach((fieldKey) => {
        this.configFiles[this.editedFileIndex].errors.push(
          ERRORS[fieldKey].join("<br>")
        );
      });
    },
    clearFilesErrorTexts() {
      this.configFiles.forEach((file, fileKey) => {
        this.configFiles[fileKey].errors.splice(0);
      });
    },
    loadConfig() {
      api.config
        .getAllByGroup()
        .then((res) => {
          res.data.DATA.forEach((elem) => {
            elem.CONFIGS.forEach((item) => {
              this.configFormRules[item.SID] = [];
              if (item.TYPE === 3) {
                item.VALUE = item.VALUE === "TRUE";
              }
              if (item.IS_NOT_NULL)
                this.configFormRules[item.SID].push(this.rules.required);
              if (item.TYPE === this.$options.CONFIG_TYPE.BIGINT)
                this.configFormRules[item.SID].push(this.rules.number);
            });
          });
          this.configItems = res.data.DATA;
        })
        .catch((error) => {
          showSnackbarMessage(error.response.data.MESSAGE);
        });
    },
    cancelConfig() {
      this.loadConfig();
      this.$refs.configForm.resetValidation();
    },
    saveConfig() {
      this.clearInvalidRulesTexts();
      if (this.$refs.configForm.validate()) {
        this.$store.commit(COMMON_SET_LOADING);
        api.config
          .update(this.getSendParams(this.configItems))
          .then((res) => {
            this.$store.commit(COMMON_SET_LOADING, false);
            showSnackbarMessage(res.data.MESSAGE);
          })
          .catch((error) => {
            this.$store.commit(COMMON_SET_LOADING, false);
            showSnackbarMessage(error.response.data.MESSAGE);
            this.addInvalidRulesTexts(error.response.data.ERRORS);
            this.$refs.configForm.validate();
          });
      }
    },
    addInvalidRulesTexts(ERRORS) {
      if (!ERRORS) return false;
      Object.keys(ERRORS).forEach((fieldKey) => {
        this.configFormRules[fieldKey].push(ERRORS[fieldKey][0]);
      });
    },
    clearInvalidRulesTexts() {
      Object.keys(this.configFormRules).forEach((fieldKey) => {
        this.configFormRules[fieldKey].map((field, index) => {
          if (typeof field !== "function") {
            this.configFormRules[fieldKey].splice(index, 1);
          }
        });
      });
    },
    getSendParams(configItems) {
      const sendParams = [];
      configItems.forEach((elem) => {
        elem.CONFIGS.forEach((item) =>
          sendParams.push({
            SID: item.SID,
            VALUE: item.VALUE,
          })
        );
      });
      return sendParams;
    },
    transformPossibleValuesToSelectFormat(items) {
      return items.map((item) => ({ text: item.TEXT, value: String(item.ID) }));
    },
  },
};
</script>
