<template>
  <div>
    <v-row class="mb-5">
      <v-col cols="auto" align-self="center">
        <h2 class="text-h2">{{ $t("Equipment") }}</h2>
      </v-col>
      <v-col cols="auto">
        <v-btn
          v-if="isValueStraightTreeMixin('devices_edit')"
          depressed
          color="primary"
          @click="showNewEquipmentForm"
        >
          <v-icon size="18" left class="mr-2">$plus</v-icon>
          {{ $t("Add") }}
        </v-btn>
      </v-col>
      <v-spacer />
      <v-col cols="auto" class="d-flex flex-grow-1 justify-end">
        <v-btn
          depressed
          class="ml-4"
          color="primary"
          :outlined="filterIsEmpty"
          width="150"
          @click="showFilters = !showFilters"
        >
          <template v-if="!showFilters">
            <v-icon class="mr-2" size="20">$filter</v-icon>
            {{ $t("Filters") }}
          </template>
          <template v-else>
            <v-icon class="mr-2" size="20">$up</v-icon>
            {{ $t("Collapse") }}
          </template>
        </v-btn>
      </v-col>
    </v-row>

    <filter-component
      v-model="filterModel"
      :show="showFilters"
      :data="filterModel"
      @getDataFilters="applyFilter"
      @filterIsEmpty="filterIsEmpty = $event"
    />

    <v-data-table
      :headers="translatedTableHeaders"
      :items="tableItems"
      sort-by="ID"
      :sort-desc="true"
      :items-per-page="-1"
      :loading="tableLoading"
      :header-props="{ 'sort-icon': '$swap' }"
      :loading-text="`${$t('Loading')}...`"
      class="table-striped"
      :no-data-text="$t('Data not found')"
      :no-results-text="$t('Data not found')"
      :footer-props="{
        'items-per-page-text': $t('Lines per page'),
        'items-per-page-options': [15, 25, 50, 100],
      }"
    >
      <template #[`item.ACTIONS`]="{ item }">
        <div class="d-inline-flex">
          <v-tooltip bottom color="primary">
            <template #activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                class="mr-4"
                color="primary"
                v-on="on"
                @click="showExternalReaders(item)"
              >
                $device
              </v-icon>
            </template>
            {{ $t("External readers") }}
          </v-tooltip>
          <v-icon
            v-if="item.TYPE !== 0 && isValueStraightTreeMixin('devices_edit')"
            class="mr-4"
            color="red"
            @click="showRemoveEquipmentConfirm(item)"
          >
            $delete
          </v-icon>
          <v-icon
            v-if="isValueStraightTreeMixin('devices_edit')"
            class="mr-4"
            :color="item.STATUS != 2 ? 'red' : 'green'"
            @click="blockToggleEquipment(item)"
          >
            $lock
          </v-icon>
          <v-icon
            v-if="item.TYPE !== 0 && isValueStraightTreeMixin('devices_edit')"
            color="primary"
            @click="showEditEquipmentForm(item)"
          >
            $edit
          </v-icon>
        </div>
      </template>
      <template #[`item.STATUS`]="{ item }">
        <span :class="item.STATUS !== 1 ? 'red--text' : 'green--text'">
          {{
            $t(DEVICE_STATUSES.find((elem) => elem.value == item.STATUS)?.text)
          }}
        </span>
      </template>
      <template #[`item.TYPE`]="{ item }">
        {{
          translatedDeviceTypes.find((elem) => elem.value == item.TYPE)?.text
        }}
      </template>
      <template #[`item.CONFIRM_ENTRANCE`]="{ item }">
        {{
          $t(
            CONFIRM_ENTRANCE.find(
              (elem) => elem.value === item.CONFIRM_ENTRANCE
            )?.text
          )
        }}
      </template>
      <template #[`item.CONFIRM_EXIT`]="{ item }">
        {{
          $t(
            CONFIRM_EXIT.find((elem) => elem.value === item.CONFIRM_EXIT)?.text
          )
        }}
      </template>
      <template #[`item.GATE_ID`]="{ item }">
        {{ $t(gates.find((elem) => elem.value == item.GATE_ID)?.text) }}
      </template>
      <template #[`item.IS_EXTERNAL`]="{ item }">
        {{
          $t(
            DEVICE_IS_EXTERNAL.find((elem) => elem.value == item.IS_EXTERNAL)
              ?.text
          )
        }}
      </template>
      <template #[`item.IS_ENTRY`]="{ item }">
        {{
          $t(
            DEVICE_IS_ENTRY_in.find((elem) => elem.value == item.IS_ENTRY)?.text
          )
        }}
      </template>
      <template #[`item.USER_ID`]="{ item }">
        {{ allUsers.find((elem) => elem.value == item.USER_ID)?.text }}
      </template>
      <template #[`item.CONFIGURATION`]="{ item }">
        <span class="text-truncate d-inline-block" style="width: 180px">{{
          item.CONFIGURATION
        }}</span>
      </template>
      <template #[`item.INITIALIZATION`]="{ item }">
        <span class="text-truncate d-inline-block" style="width: 180px">{{
          item.INITIALIZATION
        }}</span>
      </template>
      <template #[`item.DATE_EDIT`]="{ item }">
        {{ dateHelpers.getDmyHmFormattedDate(item.DATE_EDIT) }}
      </template>
      <template #[`item.NO_PASS_EXIT`]="{ item }">
        {{
          $t(
            DEVICE_NO_PASS_EXIT_in.find(
              (elem) => elem.value == item.NO_PASS_EXIT
            )?.text
          )
        }}
      </template>
      <template #[`item.EXPIRED_EXIT`]="{ item }">
        {{
          $t(
            DEVICE_EXPIRED_EXIT_in.find(
              (elem) => elem.value == item.EXPIRED_EXIT
            )?.text
          )
        }}
      </template>
      <template #[`item.EXPIRED_OUT`]="{ item }">
        {{
          $t(
            DEVICE_EXPIRED_OUT_in.find((elem) => elem.value == item.EXPIRED_OUT)
              ?.text
          )
        }}
      </template>
      <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
        {{ pageStart }} - {{ pageStop }} {{ $t("of") }} {{ itemsLength }}
      </template>
    </v-data-table>

    <v-dialog v-model="equipmentFormDialog" max-width="890">
      <v-card>
        <v-icon
          size="16"
          class="card__close-btn"
          @click="equipmentFormDialog = false"
        >
          $close
        </v-icon>
        <v-card-title class="card__title-wrapper">
          <h3 class="card__title">
            {{
              isNewEquipmentForm
                ? $t("Adding equipment")
                : $t("Equipment editing")
            }}
          </h3>
        </v-card-title>
        <v-card-text class="text-center pb-16 px-12">
          <v-form
            ref="equipmentForm"
            v-model="equipmentFormValid"
            @keyup.native.enter="equipmentFormSubmit"
          >
            <v-card class="d-flex justify-space-between px-0 py-0" flat>
              <v-card
                class="equipment-form__card d-flex flex-column text-left px-0 py-0"
                flat
              >
                <v-text-field
                  v-model="equipmentForm.NAME"
                  :rules="equipmentFormRules.NAME"
                  hide-details="auto"
                  class="mb-5"
                  outlined
                >
                  <template #label>
                    {{ $t("Name") }}<span class="red--text">*</span>
                  </template>
                </v-text-field>
                <v-select
                  v-model="equipmentForm.STATUS"
                  :items="translateDeviceStatuses"
                  :rules="equipmentFormRules.STATUS"
                  :menu-props="{ offsetY: true }"
                  class="mb-5"
                  append-icon="$down"
                  hide-details="auto"
                  outlined
                >
                  <template #label>
                    {{ $t("Status") }}<span class="red--text">*</span>
                  </template>
                </v-select>
                <v-select
                  v-model="equipmentForm.GATE_ID"
                  :items="gates"
                  :rules="equipmentFormRules.GATE_ID"
                  :menu-props="{ offsetY: true }"
                  class="mb-5"
                  append-icon="$down"
                  hide-details="auto"
                  outlined
                >
                  <template #label>
                    {{ $t("Gate") }}<span class="red--text">*</span>
                  </template>
                </v-select>
                <v-select
                  v-model="equipmentForm.TYPE"
                  :items="translatedDeviceTypes"
                  :rules="equipmentFormRules.TYPE"
                  :menu-props="{ offsetY: true }"
                  class="mb-5"
                  append-icon="$down"
                  hide-details="auto"
                  outlined
                >
                  <template #label>
                    {{ $t("Device type") }}<span class="red--text">*</span>
                  </template>
                </v-select>
                <div
                  v-show="shoCheckbox"
                >
                  <div class="card__container-checkbox">
                    <v-checkbox
                      label="Подтверждение въезда"
                      v-model="equipmentForm.CONFIRM_ENTRANCE"
                      :hide-details="true"
                    />
                    <v-checkbox
                      label="Подтверждение выезда"
                      v-model="equipmentForm.CONFIRM_EXIT"
                      :hide-details="true"
                    />
                  </div>
                </div>
                <v-text-field
                  v-if="equipmentForm.TYPE === 1 || equipmentForm.TYPE === 3"
                  v-model="equipmentForm.SID"
                  :rules="equipmentFormRules.SID"
                  hide-details="auto"
                  class="mb-5"
                  outlined
                >
                  <template #label>
                    SID<span class="red--text">*</span>
                  </template>
                </v-text-field>
                <v-text-field
                  v-model="equipmentForm.ADDRESS"
                  hide-details="auto"
                  class="mb-5"
                  outlined
                >
                  <template #label>
                    {{ $t("Address") }}
                  </template>
                </v-text-field>

                <v-text-field
                  v-model="equipmentForm.NATS_ADDRESS"
                  hide-details="auto"
                  class="mb-5"
                  outlined
                >
                  <template #label>
                    {{ $t("NATS address") }}
                  </template>
                </v-text-field>

                <v-text-field
                  v-model="equipmentForm.NATS_CONFIGURATION"
                  hide-details="auto"
                  class="mb-5"
                  outlined
                >
                  <template #label>
                    {{ $t("Nats configuration") }}
                  </template>
                </v-text-field>
              </v-card>
              <v-card
                class="d-flex flex-column text-left px-0 py-0"
                style="width: 370px"
                flat
              >
                <span>
                  {{ $t("Outer perimeter") }}<span class="red--text">*</span>
                </span>
                <v-radio-group
                  v-model="equipmentForm.IS_EXTERNAL"
                  :rules="equipmentFormRules.IS_EXTERNAL"
                  hide-details="auto"
                  class="mb-5 mt-1"
                  row
                >
                  <v-radio
                    v-for="n in translateDeviceIsExternal"
                    :key="`DEVICE_IS_EXTERNAL-${n.value}`"
                    :value="n.value"
                  >
                    <template #label>
                      <span class="text--primary">{{ n.text }}</span>
                    </template>
                  </v-radio>
                </v-radio-group>
                <v-textarea
                  v-model="equipmentForm.DESCRIPTION"
                  :rules="equipmentFormRules.DESCRIPTION"
                  :label="$t('Description')"
                  class="mb-5"
                  rows="4"
                  row-height="30"
                  outlined
                />
                <span>
                  {{ $t("Let exit who did not enter from the outer perimeter")
                  }}<span class="red--text">*</span>
                </span>
                <v-radio-group
                  v-model="equipmentForm.NO_PASS_EXIT"
                  :rules="equipmentFormRules.NO_PASS_EXIT"
                  hide-details="auto"
                  class="mb-5 mt-1"
                  row
                >
                  <v-radio
                    v-for="n in translateDeviceNoPassExitIn"
                    :key="`DEVICE_NO_PASS_EXIT_in-${n.value}`"
                    :value="n.value"
                  >
                    <template #label>
                      <span class="text--primary">{{ n.text }}</span>
                    </template>
                  </v-radio>
                </v-radio-group>

                <span>
                  {{ $t("Let exit expired passes from the outer perimeter")
                  }}<span class="red--text">*</span>
                </span>
                <v-radio-group
                  v-model="equipmentForm.EXPIRED_EXIT"
                  :rules="equipmentFormRules.EXPIRED_EXIT"
                  hide-details="auto"
                  class="mb-5 mt-1"
                  row
                >
                  <v-radio
                    v-for="n in translateDeviceExpiredOutIn"
                    :key="`DEVICE_EXPIRED_OUT_in-${n.value}`"
                    :value="n.value"
                  >
                    <template #label>
                      <span class="text--primary">{{ n.text }}</span>
                    </template>
                  </v-radio>
                </v-radio-group>

                <span>
                  {{ $t("Let exit expired passes from the inner perimeter")
                  }}<span class="red--text">*</span>
                </span>
                <v-radio-group
                  v-model="equipmentForm.EXPIRED_OUT"
                  :rules="equipmentFormRules.EXPIRED_OUT"
                  hide-details="auto"
                  class="mb-5 mt-1"
                  row
                >
                  <v-radio
                    v-for="n in translateDeviceExpiredOutIn"
                    :key="`DEVICE_EXPIRED_OUT_in-${n.value}`"
                    :value="n.value"
                  >
                    <template #label>
                      <span class="text--primary">{{ n.text }}</span>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-card>
            </v-card>
            <v-btn
              depressed
              block
              color="primary"
              :disabled="!equipmentFormValid"
              @click="equipmentFormSubmit"
            >
              {{ isNewEquipmentForm ? $t("Add") : $t("Save") }}
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <confirm-remove-dialog
      v-if="equipmentRemoveConfirm"
      :loading="equipmentRemoveLoading"
      @closeDialog="equipmentRemoveConfirm = false"
      @confirm="removeEquipment"
    >
      <template #title>
        {{ $t("Delete equipment") }} «{{ equipmentForm.NAME }}»?
      </template>
      <template #default>
        {{
          $t("After removal, it will be impossible to restore the hardware")
        }}.
      </template>
    </confirm-remove-dialog>

    <checkbox-list-dialog
      v-if="showEquipmentZonesDialog"
      :objects="zones"
      :title="$t('Access zones')"
      :subtitle="equipmentForm.NAME"
      :headers="[{ text: 'NAME', value: 'NAME' }]"
      item-key="ID"
      @closeDialog="showEquipmentZonesDialog = false"
      @itemSelected="zoneToggle"
    />

    <ExternalReaderDialog
      :is-show="isExternalReaderDialogShow"
      :table-headers="externalReadersHeaders"
      :table-items="externalReaders"
      :device-id="equipmentForm.ID"
      @close-dialog="isExternalReaderDialogShow = false"
      @add-external-reader="openAddExternalReaderDialog"
    />

    <AddExternalReaderDialog
      :is-show="isAddExternalReaderDialogShow"
      :table-headers="addExternalReadersHeaders"
      :table-items="freeExternalReaders"
      :device-id="equipmentForm.ID"
      @close-dialog="isAddExternalReaderDialogShow = false"
    />
  </div>
</template>

<script>
import api from "@/api";
import { COMMON_SET_LOADING } from "@/store/types/mutation-types";
import { SNACK_ADD_MESSAGE } from "@/store/types/action-types";
import {
  DEVICE_STATUSES,
  DEVICE_TYPE,
  CONFIRM_ENTRANCE,
  CONFIRM_EXIT,
  DEVICE_IS_EXTERNAL,
  DEVICE_IS_ENTRY_in,
  DEVICE_NO_PASS_EXIT_in,
  DEVICE_EXPIRED_EXIT_in,
  DEVICE_EXPIRED_OUT_in,
} from "@/constants";
import dateHelpers from "@/helpers/dateHelpers";
import ConfirmRemoveDialog from "@/components/blocks/ConfirmRemoveDialog.vue";
import CheckboxListDialog from "@/components/blocks/CheckboxListDialog.vue";
import FilterComponent from "@/components/blocks/FilterComponent.vue";
import {
  tableHeaders,
  filterModel,
  externalReadersHeaders,
  addExternalReadersHeaders,
} from "./data.equipments";
import ExternalReaderDialog from "./ExternalReaderDialog.vue";
import AddExternalReaderDialog from "./AddExternalReaderDialog.vue";
import { isJsonString } from "@/helpers/helpers";
import { mapGetters } from "vuex";
import isValueStraightTreeMixin from "@/mixins/isValueStraightTreeMixin.js";
import { showSnackbarMessage } from '@/helpers/helpers';

export default {
  name: "EquipmentPage",
  components: {
    ConfirmRemoveDialog,
    CheckboxListDialog,
    FilterComponent,
    ExternalReaderDialog,
    AddExternalReaderDialog,
  },
  mixins: [isValueStraightTreeMixin],
  data() {
    return {
      DEVICE_STATUSES,
      DEVICE_TYPE,
      CONFIRM_ENTRANCE,
      CONFIRM_EXIT,
      DEVICE_IS_EXTERNAL,
      DEVICE_IS_ENTRY_in,
      DEVICE_NO_PASS_EXIT_in,
      DEVICE_EXPIRED_EXIT_in,
      DEVICE_EXPIRED_OUT_in,
      dateHelpers,
      externalReadersHeaders,
      addExternalReadersHeaders,
      filterModel,
      tableHeaders,
      filterIsEmpty: true,
      allUsers: [],
      isNewEquipmentForm: true,
      showFilters: false,
      showEquipmentZonesDialog: false,
      equipmentForm: this.createEquipmentFormModel(),
      equipmentFormDialog: false,
      equipmentFormValid: false,
      equipmentRemoveConfirm: false,
      equipmentRemoveLoading: false,
      tableLoading: false,
      tableItems: [],
      gates: [],
      zones: [],
      equipmentFormRules: {
        STATUS: [(v) => !!v || `${this.$t("You need to fill in «Status»")}.`],
        TYPE: [(v) => !!v || `${this.$t("You need to fill «Type»")}.`],
        SID: [
          (v) => {
            if (this.equipmentForm.TYPE !== 1) {
              return true;
            }
            return !!v || `${this.$t("You need to fill in «SID»")}.`;
          },
        ],
        NAME: [(v) => !!v || `${this.$t("You need to fill in «Name»")}.`],
        IS_EXTERNAL: [
          (v) =>
            v === null ||
            typeof v === "boolean" ||
            `${this.$t("You must select a value")}.`,
        ],
        IS_ENTRY: [
          (v) =>
            v === null ||
            typeof v === "boolean" ||
            `${this.$t("You must select a value")}.`,
        ],
        NO_PASS_EXIT: [
          (v) =>
            v === null ||
            typeof v === "boolean" ||
            `${this.$t("You must select a value")}.`,
        ],
        EXPIRED_EXIT: [
          (v) =>
            v === null ||
            typeof v === "boolean" ||
            `${this.$t("You must select a value")}.`,
        ],
        EXPIRED_OUT: [
          (v) =>
            v === null ||
            typeof v === "boolean" ||
            `${this.$t("You must select a value")}.`,
        ],
        ADDRESS: [
          (v) => !!v || `${this.$t("You need to fill in «IP address»")}.`,
        ],
        CONFIGURATION: [
          (v) => !!v || `${this.$t("You need to fill in «Configuration»")}.`,
        ],
      },

      // external readers dialog
      externalReaders: [],
      isExternalReaderDialogShow: false,

      // add external readers dialog
      freeExternalReaders: [],
      isAddExternalReaderDialogShow: false,
    };
  },
  computed: {
    ...mapGetters(["getEnums"]),
    shoCheckbox() {
      return this.equipmentForm.TYPE ===
      DEVICE_TYPE.find((item) => item.text === 'Barrier').value
    },
    translatedTableHeaders() {
      return this.tableHeaders.map((elem) => ({
        ...elem,
        text: this.$t(elem.text),
      }));
    },
    translateDeviceStatuses() {
      return DEVICE_STATUSES.map((elem) => ({
        ...elem,
        text: this.$t(elem.text),
      }));
    },
    translateDeviceTypes() {
      return DEVICE_TYPE.map((elem) => ({ ...elem, text: this.$t(elem.text) }));
    },
    translateDeviceIsExternal() {
      return DEVICE_IS_EXTERNAL.map((elem) => ({
        ...elem,
        text: this.$t(elem.text),
      }));
    },
    translateConfirmEntrance() {
      return CONFIRM_ENTRANCE.map((elem) => ({
        ...elem,
        text: this.$t(elem.text),
      }));
    },
    translateConfirmExit() {
      return CONFIRM_EXIT.map((elem) => ({
        ...elem,
        text: this.$t(elem.text),
      }));
    },
    translateDeviceIsEntryIn() {
      return DEVICE_IS_ENTRY_in.map((elem) => ({
        ...elem,
        text: this.$t(elem.text),
      }));
    },
    translateDeviceNoPassExitIn() {
      return DEVICE_NO_PASS_EXIT_in.map((elem) => ({
        ...elem,
        text: this.$t(elem.text),
      }));
    },
    translateDeviceExpiredExitIn() {
      return DEVICE_EXPIRED_EXIT_in.map((elem) => ({
        ...elem,
        text: this.$t(elem.text),
      }));
    },
    translateDeviceExpiredOutIn() {
      return DEVICE_EXPIRED_OUT_in.map((elem) => ({
        ...elem,
        text: this.$t(elem.text),
      }));
    },
    translatedDeviceTypes() {
      if (!this.$store.getters?.getEnums["ENUM.Device.TYPE"]?.length) {
        return [];
      }

      return this.$store.getters.getEnums["ENUM.Device.TYPE"].map((reader) => ({
        text: reader.NAME,
        value: reader.NUMBER,
      }));
    },
  },
  mounted() {
    this.applyFilter();
    api.users.getAll().then((res) => {
      res.data.DATA.forEach((user) =>
        this.allUsers.push({ text: user.NAME, value: user.ID })
      );
    }).catch((error) => {
      showSnackbarMessage(error.response.data.MESSAGE);
    });
    api.gates.getAll({ status: 1 }).then((res) => {
      this.gates = res.data.DATA.map((gate) => ({
        text: gate.NAME,
        value: gate.ID,
      }));
    });
  },
  methods: {
    applyFilter() {
      this.$store.commit(COMMON_SET_LOADING);
      const status = this.getValueFromFilter("DEVICE_STATUS");
      const type = this.getValueFromFilter("DEVICE_TYPE");
      const is_external = this.getValueFromFilter("DEVICE_IS_EXTERNAL");
      const no_pass_exit = this.getValueFromFilter("DEVICE_NO_PASS_EXIT");
      const expired_exit = this.getValueFromFilter("DEVICE_EXPIRED_EXIT");
      const expired_out = this.getValueFromFilter("DEVICE_EXPIRED_OUT");
      api.devices
        .getAll({
          status,
          type,
          is_external:
            typeof is_external === "boolean"
              ? Number(is_external)
              : is_external,
          no_pass_exit,
          expired_exit,
          expired_out,
        })
        .then((res) => (this.tableItems = res.data.DATA))
        .finally(() => this.$store.commit(COMMON_SET_LOADING, false));
    },
    getValueFromFilter(field) {
      return this.filterModel.find((item) => item.name === field).data;
    },
    showNewEquipmentForm() {
      if (this.$refs.equipmentForm) this.$refs.equipmentForm.resetValidation();
      this.equipmentForm = this.createEquipmentFormModel();
      this.isNewEquipmentForm = true;
      this.equipmentFormDialog = true;
    },
    showEditEquipmentForm(equipment) {
      if (!equipment) return false;
      this.equipmentForm = this.createEquipmentFormModel(equipment);
      this.isNewEquipmentForm = false;
      this.equipmentFormDialog = true;
    },
    showRemoveEquipmentConfirm(equipment) {
      this.equipmentForm = this.createEquipmentFormModel(equipment);
      this.equipmentRemoveConfirm = true;
    },
    async showExternalReaders(equipment) {
      this.equipmentForm = this.createEquipmentFormModel(equipment);
      const res = await api.devices.getExternalReaders(equipment.ID, 1);
      this.externalReaders = res.data.DATA;
      this.isExternalReaderDialogShow = true;
    },
    createEquipmentFormModel(equipment = {}) {
      return {
        ID: equipment.ID || null,
        SID: equipment.SID || "",
        STATUS: equipment.STATUS || 2,
        TYPE: equipment.TYPE || null,
        CONFIRM_ENTRANCE: equipment.CONFIRM_ENTRANCE,
        CONFIRM_EXIT: equipment.CONFIRM_EXIT,
        NAME: equipment.NAME || "",
        DESCRIPTION: equipment.DESCRIPTION || "",
        IS_EXTERNAL: equipment.IS_EXTERNAL,
        IS_ENTRY: equipment.IS_ENTRY,
        NO_PASS_EXIT: equipment.NO_PASS_EXIT,
        EXPIRED_EXIT: equipment.EXPIRED_EXIT,
        EXPIRED_OUT: equipment.EXPIRED_OUT,
        ADDRESS: equipment.ADDRESS || "",
        NATS_ADDRESS: equipment.NATS_ADDRESS || "",
        NATS_CONFIGURATION: equipment.NATS_CONFIGURATION || "",
        GATE_ID: equipment.GATE_ID || null,
      };
    },
    equipmentFormSubmit() {
      if (this.$refs.equipmentForm.validate()) {
        const formData = new FormData();
        if (!(this.equipmentForm.TYPE === 1 || this.equipmentForm.TYPE === 3))
          this.equipmentForm.SID = "";
        Object.keys(this.equipmentForm).forEach((key) => {
          let value = this.equipmentForm[key];
          // TODO: костыль для обхода типов в БД решить в сентябре с ДБ и беком
          value = value === null ? "" : value;
          value = typeof value === "boolean" ? Number(value) : value;

          if (key === "GATE_ID") {
            value = isJsonString(value) ? value : JSON.stringify(value);
          }
          formData.append(key.toLowerCase(), value);
        });

        this.$store.commit(COMMON_SET_LOADING);
        if (this.isNewEquipmentForm) {
          api.devices
            .create(formData)
            .then((res) => {
              this.equipmentFormDialog = false;
              this.tableItems.push(res.data.DATA);
              showSnackbarMessage(res.data.MESSAGE);
            })
            .catch((error) => {
              showSnackbarMessage(error.response.data.MESSAGE);
            })
            .finally(() => this.$store.commit(COMMON_SET_LOADING, false));
        } else {
          api.devices
            .update(this.equipmentForm.ID, formData)
            .then((res) => {
              this.equipmentFormDialog = false;
              const equipmentIndex = this.tableItems.findIndex(
                (item) => item.ID === this.equipmentForm.ID
              );
              Object.assign(
                this.tableItems[equipmentIndex],
                this.equipmentForm
              );
              showSnackbarMessage(res.data.MESSAGE);
            })
            .catch((error) => {
              showSnackbarMessage(error.response.data.MESSAGE);
            })
            .finally(() => this.$store.commit(COMMON_SET_LOADING, false));
        }
      }
    },
    blockToggleEquipment(equipment) {
      if (!equipment) return false;
      this.equipmentForm = this.createEquipmentFormModel(equipment);
      this.isNewEquipmentForm = false;
      this.equipmentForm.STATUS = this.equipmentForm.STATUS != 2 ? 2 : 1;
      const formData = new FormData();
      Object.keys(this.equipmentForm).forEach((key) => {
        let value = this.equipmentForm[key];
        value = value === null ? "" : value;
        value = typeof value === "boolean" ? Number(value) : value;
        formData.append(key.toLowerCase(), value);
      });
      this.$store.commit(COMMON_SET_LOADING);
      api.devices
        .update(this.equipmentForm.ID, formData)
        .then((res) => {
          this.$store.commit(COMMON_SET_LOADING, false);
          const equipmentIndex = this.tableItems.findIndex(
            (item) => item.ID === this.equipmentForm.ID
          );
          Object.assign(this.tableItems[equipmentIndex], this.equipmentForm);
          showSnackbarMessage(res.data.MESSAGE);
        })
        .catch((error) => {
          this.$store.commit(COMMON_SET_LOADING, false);
          showSnackbarMessage(error.response.data.MESSAGE);
        });
    },
    removeEquipment() {
      this.equipmentRemoveLoading = true;
      api.devices
        .remove(this.equipmentForm.ID)
        .then((res) => {
          if (res.data.DATA) {
            this.equipmentRemoveConfirm = false;
            this.equipmentRemoveLoading = false;
            const equipmentIndex = this.tableItems.findIndex(
              (item) => item.ID === this.equipmentForm.ID
            );
            this.tableItems.splice(equipmentIndex, 1);
            showSnackbarMessage(res.data.MESSAGE);
          }
        })
        .catch((error) => {
          this.equipmentRemoveConfirm = false;
          this.equipmentRemoveLoading = false;
          showSnackbarMessage(error.response.data.MESSAGE);
        });
    },
    zoneToggle({ item, value }) {
      this.$store.commit(COMMON_SET_LOADING);
      api.zones
        .changeDevice({
          id: item.ID,
          device_id: this.equipmentForm.ID,
          is_bind: value,
        })
        .then(() => this.loadZones())
        .finally(() => this.$store.commit(COMMON_SET_LOADING, false));
    },

    loadZones() {
      return api.zones
        .getAll()
        .then((res) => res.data.DATA)
        .then((allZones) =>
          api.devices.getZones(this.equipmentForm.ID).then((res) => {
            const accessZones = res.data.DATA.map((item) => item.ZONE_ID);
            allZones.forEach(
              (zone) => (zone.IS_ACCESS = accessZones.includes(zone.ID))
            );
            this.zones = allZones;
          })
        ).catch((error) => {
          showSnackbarMessage(error.response.data.MESSAGE);
        });
    },

    async openAddExternalReaderDialog() {
      const res = await api.devices.getExternalReaders(
        this.equipmentForm.ID,
        0
      );
      this.freeExternalReaders = res.data.DATA;
      this.isAddExternalReaderDialogShow = true;
    },
  },
};
</script>

<style lang="scss" >
.equipment-form {
  &__card {
    width: 370px;
  }
}
</style>
