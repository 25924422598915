<template>
  <div>
    <v-row class="mb-5">
      <v-col cols="auto" align-self="center">
        <h2 class="text-h2">
          {{ $t("Servers") }}
        </h2>
      </v-col>
      <v-col cols="auto">
        <v-btn
          v-if="isValueStraightTreeMixin('servers_edit')"
          depressed
          color="primary"
          @click="addServer"
        >
          <v-icon size="18" class="mr-2">$plus</v-icon>
          {{ $t("Add") }}
        </v-btn>
      </v-col>
      <v-col cols="auto" class="d-flex flex-grow-1 justify-end">
        <v-btn
          depressed
          class="ml-4"
          color="primary"
          :outlined="filterIsEmpty"
          width="150"
          @click="showFilters = !showFilters"
        >
          <template v-if="!showFilters">
            <v-icon class="mr-2" size="20">$filter</v-icon>
            {{ $t("Filters") }}
          </template>
          <template v-else>
            <v-icon class="mr-2" size="20">$up</v-icon>
            {{ $t("Collapse") }}
          </template>
        </v-btn>
      </v-col>
    </v-row>

    <filter-component
      v-model="filterModel"
      :show="showFilters"
      :data="filterModel"
      @getDataFilters="applyFilter"
      @filterIsEmpty="filterIsEmpty = $event"
    />

    <v-data-table
      :headers="translatedTableHeaders"
      :items="tableItems"
      sort-by="ID"
      :sort-desc="true"
      :items-per-page="-1"
      :loading="tableLoading"
      :header-props="{ 'sort-icon': '$swap' }"
      :loading-text="`${$t('Loading')}...`"
      class="table-striped"
      :no-data-text="$t('Data not found')"
      :no-results-text="$t('Data not found')"
      :footer-props="{
        'items-per-page-text': $t('Lines per page'),
        'items-per-page-options': [15, 25, 50, 100],
      }"
    >
      <template #[`item.STATUS`]="{ item }">
        <span :class="item.STATUS !== 1 ? 'red--text' : 'green--text'">
          {{ getStatusText(item.STATUS) }}
        </span>
      </template>
      <template #[`item.IS_MASTER`]="{ item }">
        <span>
          {{ item.IS_MASTER ? $t("Yes") : $t("No") }}
        </span>
      </template>
      <template #[`item.ACTIONS`]="{ item }">
        <div class="d-inline-flex">
          <v-tooltip bottom color="primary">
            <template #activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                class="mr-4"
                color="primary"
                v-on="on"
                @click="showGatesDialog(item)"
              >
                $gateIcon
              </v-icon>
            </template>
            {{ $t("Gate") }}
          </v-tooltip>
          <v-icon
            v-if="isValueStraightTreeMixin('servers_edit')"
            class="mr-4"
            color="red"
            @click="showRemoveRequestConfirm(item)"
          >
            $delete
          </v-icon>
          <v-icon
            v-if="isValueStraightTreeMixin('servers_edit')"
            class="mr-4"
            :color="item.STATUS !== 2 ? 'red' : 'green'"
            @click="toggleServerStatus(item)"
          >
            $lock
          </v-icon>
          <v-icon
            v-if="isValueStraightTreeMixin('servers_edit')"
            color="primary"
            @click="editServer(item)"
          >
            $edit
          </v-icon>
        </div>
      </template>
      <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
        {{ pageStart }} - {{ pageStop }} {{ $t("of") }} {{ itemsLength }}
      </template>
    </v-data-table>

    <ServerDialog
      :is-show="isServerFormActive"
      :is-new-server-form="isNewServerForm"
      :server-form-props="serverForm"
      @close-dialog="isServerFormActive = false"
      @add-server="addNewServerToTable"
      @change-server="applyServerChangeInTable"
    />

    <CheckboxListDialog
      v-if="isShowGatesDialog"
      :objects="currentServerGates"
      :subtitle="serverForm.NAME"
      :title="$t('Gates list')"
      :headers="[{ text: 'NAME', value: 'GATE_NAME' }]"
      item-key="GATE_ID"
      :is-list-dense="true"
      :show-checkboxes="false"
      @closeDialog="isShowGatesDialog = false"
    />
    <confirm-remove-dialog
      v-if="requestRemoveConfirm"
      @closeDialog="requestRemoveConfirm = false"
      @confirm="removeServer"
    >
      <template #title>
        {{ $t("Delete the server") }} «{{ activeItem.NAME }}»?
      </template>
      <template #default>
        {{
          $t(
            "After deletion, it will be impossible to restore the server data."
          )
        }}
      </template>
    </confirm-remove-dialog>
  </div>
</template>

<script>
import api from "@/api";
import { SNACK_ADD_MESSAGE } from "@/store/types/action-types";
import FilterComponent from "@/components/blocks/FilterComponent.vue";
import { getFilterModels, tableHeaders } from "./data.servers";
import CheckboxListDialog from "@/components/blocks/CheckboxListDialog.vue";
import ServerDialog from "./serverDialog.vue";
import ConfirmRemoveDialog from "@/components/blocks/ConfirmRemoveDialog.vue";
import isValueStraightTreeMixin from "@/mixins/isValueStraightTreeMixin.js";

export default {
  name: "Servers",
  components: {
    FilterComponent,
    ServerDialog,
    CheckboxListDialog,
    ConfirmRemoveDialog,
  },
  mixins: [isValueStraightTreeMixin],
  data() {
    return {
      tableHeaders,

      serverForm: this.createServerForm(),

      activeItem: null,
      requestRemoveConfirm: false,
      // filter
      filterModel: getFilterModels(),
      filterIsEmpty: true,
      showFilters: false,

      // table
      tableItems: [],
      tableLoading: false,

      // server dialog
      isServerFormActive: false,
      isNewServerForm: false,

      // devices dialog
      devices: [],
      isShowDevicesDialog: false,

      // gates dialog
      currentServerGates: [],
      isShowGatesDialog: false,
    };
  },
  computed: {
    translatedTableHeaders() {
      return this.tableHeaders.map((elem) => ({
        ...elem,
        text: this.$t(elem.text),
      }));
    },
  },
  async mounted() {
    await this.getServers();
    this.filterModel = getFilterModels();
  },
  methods: {
    createServerForm(server = {}) {
      return {
        ID: server.ID ?? null,
        NAME: server.NAME ?? "",
        STATUS: server.STATUS ?? null,
        LOAD_ADDRESS: server.LOAD_ADDRESS ?? "",
        NATS_ADDRESS: server.NATS_ADDRESS ?? null,
        NATS_CONFIGURATION: server.NATS_CONFIGURATION ?? null,
        SID: server.SID ?? "",
        IS_MASTER: server.IS_MASTER ?? null,
        DESCRIPTION: server.DESCRIPTION ?? "",
      };
    },
    async getServers(params = {}) {
      const res = await api.servers.getAll(params);
      this.tableItems = res.data.DATA;
    },
    showRemoveRequestConfirm(item) {
      this.activeItem = item;
      this.requestRemoveConfirm = true;
    },
    async removeServer() {
      try {
        await api.servers.remove(this.activeItem.ID);
        const currentServerIndex = this.tableItems.findIndex((server) => {
          return server.ID === this.activeItem.ID;
        });
        this.tableItems.splice(currentServerIndex, 1);
      } catch (error) {
        this.$store.dispatch(
          SNACK_ADD_MESSAGE,
          this.$t(error.response.data.MESSAGE)
        );
      } finally {
        this.requestRemoveConfirm = false;
      }
    },
    async toggleServerStatus(item) {
      try {
        const response = await api.servers.changeStatus(
          item.ID,
          item.STATUS === 1 ? 2 : 1
        );
        item.STATUS = response.data.DATA.STATUS;
      } catch (error) {
        this.$store.dispatch(
          SNACK_ADD_MESSAGE,
          this.$t(error.response.data.MESSAGE)
        );
      }
    },
    addServer() {
      this.serverForm = this.createServerForm();
      this.isNewServerForm = true;
      this.isServerFormActive = true;
    },
    editServer(server) {
      this.serverForm = this.createServerForm(server);
      this.isNewServerForm = false;
      this.isServerFormActive = true;
    },
    async applyFilter(filterData) {
      const name = filterData.find((item) => item.name === "NAME").data;
      const status = filterData.find((item) => item.name === "STATUS").data;
      this.getServers({ name, status });
    },
    getStatusText(statusNumber) {
      return this.$store.getters?.getEnums["ENUM.Server.STATUS"]?.find(
        (status) => status.NUMBER === statusNumber
      ).NAME;
    },
    addNewServerToTable(server) {
      this.tableItems.push(server);
    },
    applyServerChangeInTable(server) {
      const serverIndex = this.tableItems.findIndex(
        (item) => item.ID === server.ID
      );
      this.$set(this.tableItems, serverIndex, server);
    },
    async showGatesDialog(server) {
      this.serverForm = this.createServerForm(server);
      const res = await api.servers.getGates(server.ID);
      this.currentServerGates = res.data.DATA;
      this.isShowGatesDialog = true;
    },
  },
};
</script>
