<script>
import moment from 'moment';
import { mapState, mapGetters } from 'vuex';
import api from '@/api/index.js';
import DateTimePicker from '@/components/blocks/DateTimePicker.vue';
import AutoFiltersComponent from '@/components/blocks/AutoFiltersComponent.vue';
import ConfirmRemoveDialog from '@/components/blocks/ConfirmRemoveDialog.vue';
import RadioListDialog from '@/components/blocks/RadioListDialog.vue';
import { COMMON_SET_LOADING } from '@/store/types/mutation-types.js';
import { SNACK_ADD_MESSAGE } from '@/store/types/action-types.js';
import updateStateMixin from '@/mixins/updateStateMixin.js';
import {
  ALLOWED_PASS_TYPES,
  REQUEST_STATUSES,
  REQUEST_IS_MULTIPASS,
  FLAG_INSIDE,
  EMAIL_SEND_ERR_IN,
  OBJECT_STATUSES,
  REQUEST_TYPES,
  OBJECT_SYSTEM_ID,
} from '@/constants/index.js';
import MultiPassRadioBtnNew from '@/components/blocks/MultiPassRadioBtnNew.vue';
import dateHelpers from '@/helpers/dateHelpers.js';
import { showSnackbarMessage } from '@/helpers/helpers.js';
import { tableHeadersExternal, tableHeadersLocal } from './data.requests.js';
import TableObjects from './TableObjects.vue';
import PrintForm from './printForm.vue';
import ParkingDialog from './parkingDialog.vue';

const isActiveObject = (object) => {
  const [activeObjectData] = OBJECT_STATUSES.filter((v) => v.text === 'Active');
  return activeObjectData.value === object.status;
};
export default {
  name: 'RequestsPage',
  components: {
    TableObjects,
    DateTimePicker,
    AutoFiltersComponent,
    ConfirmRemoveDialog,
    RadioListDialog,
    ParkingDialog,
    MultiPassRadioBtnNew,
    PrintForm,
  },
  mixins: [updateStateMixin],
  data() {
    return {
      tableOptions: {
        page: 1, // Переменная для отслеживания текущей страницы
        itemsPerPage: 15, // Количество элементов на странице (можно изменить по необходимости)
      },
      printForm: {},
      tableHeadersExternal,
      tableHeadersLocal,
      REQUEST_STATUSES,
      REQUEST_IS_MULTIPASS,
      EMAIL_SEND_ERR_IN,
      FLAG_INSIDE,
      filterModel: [],
      dataFilters: AutoFiltersComponent.props.data.type,
      isFiltersLoading: false,
      systemType: 0,
      allRequests: [],
      localObjects: [],
      requestFormConfig: {},
      externalObjects: [],
      filterByStatus: [],
      filterByRole: [],
      filterIsEmpty: true,
      isLocalTab: true,
      isNewRequestForm: false,
      requestForm: this.createRequestFormModel(),
      parkingForm: this.createParkingRequestFormModel(),
      isObjectRolesDialogShow: false,
      requestFormDialog: false,
      requestFormValid: false,
      requestRemoveConfirm: false,
      requestRemoveLoading: false,
      showFilters: false,
      tabsModel: 'tab-1',
      vFileModel: [],
      vFileModelError: false,
      allowedPassType: null,
      defaultPassType: null,
      requestFormRules: {
        OBJECT_ID: [
          (v) => !!v || this.$t('You must select «Object of visit».'),
        ],
        MULTI_PASS: [
          () => {
            if (
              this.allowedPassType === ALLOWED_PASS_TYPES.singleAndMultiPass
            ) {
              return true;
            }
            const isPassTypeSinglePass =
              this.allowedPassType === ALLOWED_PASS_TYPES.multiPass;
            if (this.requestForm.MULTI_PASS === isPassTypeSinglePass) {
              return true;
            }
            return `${this.$t('It is necessary to «Change the value»')}.`;
          },
        ],
        DATE_BEGIN: [(v) => !!v || this.$t('You must enter «Start date»')],
        DATE_END: [(v) => !!v || this.$t('You must enter «End date»')],
        STATE: [(v) => !!v || v === 0 || this.$t('You must enter «Status»')],
        DATA: {
          V_PHONE: [
            (v) =>
              !!v ||
              !this.isRequireField('V_PHONE') ||
              this.$t('You must enter «Phone number»'),
          ],
          V_NAME: [
            (v) =>
              !!v ||
              this.$t(
                'It is necessary to fill in the "Full name of the Visitor"',
              ),
          ],
          V_EMAIL: [
            (v) =>
              !!v ||
              !this.isRequireField('V_EMAIL') ||
              this.$t('You must enter «Email»'),
          ],
        },
      },

      isEmptyDateBegin: false,
      isEmptyDateEnd: false,

      isParkingDialogShow: false,
      isSuggestionDialogShow: false,
      enumState: [],
    };
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.user.model,
      enumsState: (state) => state.enums.enums,
    }),
    ...mapGetters(['getEnums']),

    localRequests() {
      return this.allRequests.filter((request) => request.SYSTEM_ID === 0);
    },

    externalRequests() {
      return this.allRequests.filter((request) => request.SYSTEM_ID !== 0);
    },

    requestStatuses() {
      return this.enumState.filter(
        (status) =>
          this.requestForm.IS_MOD?.edit_status.includes(status.value) ||
          this.requestForm.STATE === status.value,
      );
    },
    filteredLocalObjects() {
      return this.isNewRequestForm
        ? this.localObjects.filter(isActiveObject)
        : this.localObjects;
    },

    translatedEmailStatuses() {
      return this.EMAIL_SEND_ERR_IN.map((email) => ({
        ...email,
        text: this.$t(email.text),
      }));
    },
    translatedInsideFlags() {
      return this.FLAG_INSIDE.map((flag) => ({
        ...flag,
        text: this.$t(flag.text),
      }));
    },
    queryRequestId() {
      return this.$route.query.identifier;
    },
  },
  watch: {
    tabsModel() {
      this.applyFilter();
    },

    requestForm: {
      handler(val) {
        if (val.OBJECT_ID) {
          this.updateCustomState(val.OBJECT_ID);
        }
      },
      deep: true,
    },
    'requestForm.DATE_BEGIN': function (newTime) {
      if (!newTime) {
        this.isEmptyDateBegin = true;
        return;
      }
      const dateBegin = new Date(newTime);
      const dateEnd = new Date(this.requestForm.DATE_END);
      if (dateBegin.getTime() > dateEnd.getTime()) {
        this.requestForm.DATE_END = dateHelpers.getTomorrowTime({
          date: dateBegin,
          hour: 19,
        });
      }
      this.isEmptyDateBegin = false;
    },
    'requestForm.DATE_END': function (newTime) {
      if (!newTime) {
        this.isEmptyDateEnd = true;
        return;
      }
      this.isEmptyDateEnd = false;
    },
  },
  mounted() {
    const params = this.$route.query;

    const paramsData = Object.entries(params).reduce((acc, [key, value]) => {
      acc[key] = { key, data: value };
      return acc;
    }, {});
    this.getFilterList();
    this.applyFilter(paramsData);

    api.objects.getAll().then((res) => {
      const allObjects = res.data.DATA;
      allObjects.forEach((object) => {
        if (object.SYSTEM_ID === OBJECT_SYSTEM_ID.local) {
          this.localObjects.push({
            text: object.NAME,
            value: object.ID,
            ID: object.ID,
            initialStatus: object.DEFAULT_PASS_STATUS_FINAL,
            allowedPassType: object.ALLOWED_PASS_TYPE_FINAL,
            defaultPassType: object.DEFAULT_PASS_TYPE_FINAL,
            status: object.STATUS,
            requestType: object.REQUEST_TYPE,
          });
        } else {
          this.externalObjects.push({ text: object.NAME, value: object.ID });
        }
      });
    });

    api.requestsConfig.getAll().then((res) => {
      this.requestFormConfig = res.data.DATA;
    });

    this.$nextTick(() => {
      const { getEnums } = this.$store.getters;
      const enumRequestState = getEnums['ENUM.Request.STATE'];

      if (enumRequestState?.length) {
        this.enumState = enumRequestState.map((item) => ({
          text: item.NAME,
          value: item.NUMBER,
        }));
      }
    });
    this.$store.dispatch('getCars');
  },
  methods: {
    isRequireField(sidValue) {
      return !!this.requestFormConfig?.find((item) => item.SID === sidValue)
        ?.IS_NOT_NULL;
    },
    printContent(item) {
      this.printForm = item;
      this.$nextTick(() => {
        const printContents = this.$refs.printable.$el.innerHTML;
        const originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
        window.location.reload();
      });
    },
    showNewRequestForm() {
      this.requestForm = this.createRequestFormModel();
      this.isNewRequestForm = true;
      this.isObjectRolesDialogShow = true;
    },
    showEditRequestForm(request) {
      if (!request) return false;
      this.isNewRequestForm = false;
      this.allowedPassType = request.ALLOWED_PASS_TYPE;
      if (
        request.TYPE_ID ===
        this.$store.getters.getEnums['ENUM.Request.TYPE'].find(
          (type) => type.NAME === 'Пропуск на парковку',
        )?.NUMBER
      ) {
        this.parkingForm = this.createParkingRequestFormModel(request);
        this.requestForm.OBJECT_ID = request.OBJECT_ID;
        this.isParkingDialogShow = true;
        this.requestForm = this.createRequestFormModel(request);
        return null;
      }
      this.requestForm = this.createRequestFormModel(request);
      this.requestFormDialog = true;
      return null;
    },
    showRemoveRequestConfirm(request) {
      this.requestForm = this.createRequestFormModel(request);
      this.requestRemoveConfirm = true;
    },
    setNewDateBegin(time) {
      this.requestForm.DATE_BEGIN = time;
    },
    setNewDateEnd(time) {
      this.requestForm.DATE_END = time;
    },
    createRequestFormModel(request = { DATA: {} }) {
      return {
        ID: request.ID || '',
        IS_MOD: request.IS_MOD || null,
        TYPE_ID: request.TYPE_ID || null,
        INSPECTION: request.INSPECTION || null,
        OBJECT_ID: request.OBJECT_ID || null,
        MULTI_PASS:
          typeof request.MULTI_PASS === 'boolean' ? request.MULTI_PASS : true,
        DATE_BEGIN:
          request.DATE_BEGIN || dateHelpers.getTomorrowTime({ hour: 7 }),
        DATE_END: request.DATE_END || dateHelpers.getTomorrowTime({ hour: 19 }),
        DATE_FIRST_PASS: request.DATE_FIRST_PASS
          ? moment(request.DATE_FIRST_PASS).toDate()
          : null,
        INSIDE: request.INSIDE ?? null,
        SEND_ERR: request.SEND_ERR ?? null,
        STATE: request.STATE || 0,
        CREATE_USER_ID: request.CREATE_USER_ID || null,
        CODE: request.CODE || null,
        DATA: {
          V_PHONE: request.DATA.V_PHONE || '',
          V_NAME: request.DATA.V_NAME || '',
          V_EMAIL: request.DATA.V_EMAIL || '',
          NOTICE: request.DATA.NOTICE || '',
        },
        lang_web: this.$i18n.locale,
      };
    },
    createParkingRequestFormModel(request = {}) {
      return {
        ID: request.ID || null,
        STATE: request.STATE ?? null,
        CAR_NUM: request.CODE || null,
        CURRENT_BRAND:
          {
            value: request.DATA?.CAR_BRAND,
            text: request.DATA?.CAR_BRAND_NAME,
          } || null,
        CURRENT_MODEL:
          {
            value: request.DATA?.CAR_MODEL,
            text: request.DATA?.CAR_MODEL_NAME,
          } || null,
        DATE_BEGIN: request.DATE_BEGIN || null,
        DATE_END: request.DATE_END || null,
        INSPECTION: request.INSPECTION,
        COMMENTS: request.NOTICE || null,
      };
    },
    uploadCsvFile(file) {
      if (!file.name?.match(/csv/)) {
        this.vFileModel = [];
        this.vFileModelError = false;

        return this.$store.dispatch(
          SNACK_ADD_MESSAGE,
          this.$t('You can upload .csv file format only'),
        );
      }

      const formData = new FormData();
      formData.append('file', file);

      api.requests
        .uploadPackFile(formData)
        .then((res) => {
          this.$store.dispatch(SNACK_ADD_MESSAGE, {
            type: 'success',
            text:
              this.$i18n.locale === 'ru'
                ? res.data.MESSAGE
                : this.$t(res.data.MESSAGE),
          });
          this.vFileModelError = false;
        })
        .catch((e) => {
          this.$store.dispatch(SNACK_ADD_MESSAGE, e.response.data.MESSAGE);
          this.vFileModelError = true;
        });

      return null;
    },
    requestFormSubmit() {
      if (this.$refs.requestForm.validate()) {
        const formData = new FormData();
        Object.keys(this.requestForm).forEach((key) => {
          let value = this.requestForm[key];
          switch (key) {
            case 'DATE_BEGIN':
              if (this.requestForm.DATE_BEGIN === undefined) {
                value = '';
                break;
              }
              value = moment(this.requestForm.DATE_BEGIN || value).format(
                'DD.MM.YYYY HH:mm:00',
              );
              break;
            case 'DATE_END':
              if (this.requestForm.DATE_END === undefined) {
                value = '';
                break;
              }
              value = moment(this.requestForm.DATE_END || value).format(
                'DD.MM.YYYY HH:mm:00',
              );
              break;
            case 'DATA':
              value = JSON.stringify(value);
              break;
            default:
              break;
          }
          value = value === null ? '' : value;
          value = typeof value === 'boolean' ? Number(value) : value;
          formData.append(key.toLowerCase(), value);
        });
        this.$store.commit(COMMON_SET_LOADING);
        if (this.isNewRequestForm) {
          api.requests
            .create(formData)
            .then((res) => {
              this.requestFormDialog = false;
              this.allRequests.push(res.data.DATA);
              showSnackbarMessage(res.data.MESSAGE);
            })
            .catch((error) => {
              showSnackbarMessage(error.response.data.MESSAGE);
            })
            .finally(() => this.$store.commit(COMMON_SET_LOADING, false));
        } else {
          api.requests
            .update(this.requestForm.ID, formData)
            .then((res) => {
              this.requestFormDialog = false;
              const requestIndex = this.allRequests.findIndex(
                (item) => item.ID === this.requestForm.ID,
              );
              Object.assign(this.allRequests[requestIndex], res.data.DATA);
              this.$store.dispatch(SNACK_ADD_MESSAGE, {
                type: 'success',
                text:
                  this.$i18n.locale === 'ru'
                    ? res.data.MESSAGE
                    : this.$t(res.data.MESSAGE),
              });
            })
            .catch((error) => {
              this.$store.dispatch(
                SNACK_ADD_MESSAGE,
                this.$t(error.response.data.MESSAGE),
              );
            })
            .finally(() => this.$store.commit(COMMON_SET_LOADING, false));
        }
      }
    },
    removeRequest() {
      this.requestRemoveLoading = true;
      api.requests
        .remove(this.requestForm.ID)
        .then((res) => {
          if (res.data.DATA) {
            this.requestRemoveConfirm = false;
            this.requestRemoveLoading = false;
            const requestIndex = this.allRequests.findIndex(
              (item) => item.ID === this.requestForm.ID,
            );
            this.allRequests.splice(requestIndex, 1);
            this.$store.dispatch(SNACK_ADD_MESSAGE, {
              type: 'success',
              text:
                this.$i18n.locale === 'ru'
                  ? res.data.MESSAGE
                  : this.$t(res.data.MESSAGE),
            });
          }
        })
        .catch((error) => {
          this.requestRemoveConfirm = false;
          this.requestRemoveLoading = false;
          this.$store.dispatch(
            SNACK_ADD_MESSAGE,
            this.$t(error.response.data.MESSAGE),
          );
        });
    },
    applyFilter(data = {}) {
      this.tableOptions.page = 1;
      this.dataFilters = data;
      this.$store.commit(COMMON_SET_LOADING);

      const searchData = Object.fromEntries(
        Object.keys(data).map((key) => [data[key].key, data[key].data]),
      );

      api.requests
        .search(searchData)
        .then((res) => {
          this.allRequests = res.data.DATA;
        })
        .finally(() => this.$store.commit(COMMON_SET_LOADING, false));
    },
    applyFilterRequestId() {
      api.requests
        .search({
          code: this.queryRequestId,
        })
        .then((res) => {
          this.allRequests = res.data.DATA;
        })
        .finally(() => this.$store.commit(COMMON_SET_LOADING, false));
    },
    getValueFromFilter(field) {
      return (this.dataFilters || this.filterModel)?.find(
        (item) => item.name === field,
      ).data;
    },
    getDateTime(field) {
      const dateTime = this.getValueFromFilter(field);
      return dateTime && moment(dateTime).format('YYYY-MM-DD HH:mm:00');
    },
    getIndexFromFilter(field) {
      return this.filterModel.findIndex((item) => item.name === field);
    },
    updateCustomState(objectId) {
      const objectData = this.localObjects.find(
        (item) => item.value === objectId,
      );

      if (objectData) {
        if (this.isNewRequestForm) {
          this.requestForm.STATE = objectData.initialStatus;
          this.allowedPassType = objectData.allowedPassType;
          this.defaultPassType = objectData.defaultPassType;
          return;
        }

        this.defaultPassType = objectData.defaultPassType;
      }
    },
    canEditFields() {
      if (
        this.isNewRequestForm ||
        this.$store.getters?.user?.PERMISSION?.web_admin
      )
        return true;
      if (
        this.requestForm.CREATE_USER_ID === this.currentUser.ID &&
        this.requestForm.STATE === 0
      ) {
        return true;
      }
      return this.requestForm.IS_MOD?.parameters;
    },
    saveObjectRole(item) {
      this.requestForm.OBJECT_ID = item.ID;
      this.requestForm.TYPE_ID = item.requestType;
      this.updateCustomState(this.requestForm.OBJECT_ID);
      if (
        item.requestType ===
        REQUEST_TYPES.find((type) => type.text === 'Admission to the visit')
          .value
      ) {
        if (this.$refs.requestForm) this.$refs.requestForm.resetValidation();
        this.isObjectRolesDialogShow = false;
        this.requestFormDialog = true;
        if (this.isShow && this.isNewRequestForm) {
          const isActiveInput =
            this.defaultPassType === ALLOWED_PASS_TYPES.multiPass;
          this.requestForm.MULTI_PASS = isActiveInput;
        }
      }
      if (
        item.requestType ===
        REQUEST_TYPES.find((type) => type.text === 'Parking pass').value
      ) {
        this.isObjectRolesDialogShow = false;
        this.isParkingDialogShow = true;
      }
    },
    updateMultiPass(value) {
      this.requestForm.MULTI_PASS = value;
    },
    submitSuggestionDialog() {
      this.isSuggestionDialogShow = false;
      this.isParkingDialogShow = true;
    },
    pushNewParkingRequest(request) {
      this.allRequests.push(request);
    },
    editParkingRequest(request) {
      const requestIndex = this.allRequests.findIndex(
        (item) => item.ID === request.ID,
      );
      this.$set(this.allRequests, requestIndex, request);
    },
    closeDialog() {
      this.requestFormDialog = false;
    },
    keydownHandler(e) {
      if (('key', e.key === 'Escape')) {
        this.closeDialog();
      }
    },
    receiveColorStates(value) {
      return value ? '#ff5252' : 'primary';
    },
    async getFilterList() {
      try {
        this.isFiltersLoading = true;
        const { data } = await api.filters.getData({
          i_section: 'requests',
          i_system: true,
        });
        this.filterModel = JSON.parse(data.DATA);
      } catch (error) {
        this.$store.dispatch(
          SNACK_ADD_MESSAGE,
          this.$t(error.response.data.MESSAGE),
        );
      } finally {
        this.isFiltersLoading = false;
      }
    },
  },
};
</script>

<template>
  <div class="requests scrolling-tables-static">
    <v-tabs v-model="tabsModel" class="srp-tabs d-flex flex-column">
      <v-row class="mb-4">
        <v-col cols="auto" align-self="center">
          <h2 class="text-h2 text--primary">{{ $t('Passes') }}</h2>
        </v-col>
        <!-- <v-tab :href="`#tab-1`" @click="systemType = 0">{{ $t("Local") }}</v-tab>
        <v-tab :href="`#tab-2`" @click="systemType = 1">{{ $t("External") }}</v-tab> -->
        <v-col cols="auto">
          <v-btn
            v-if="tabsModel === 'tab-1'"
            color="primary"
            depressed
            @click="showNewRequestForm"
          >
            <v-icon size="18" class="mr-2"> $plus </v-icon>
            {{ $t('Add') }}
          </v-btn>
        </v-col>
        <v-file-input
          v-model="vFileModel"
          class="csv-file-upload-input d-flex flex-grow-0 align-start align-self-center m-0 ml-7 p-0 border-0 text-sm"
          :class="vFileModelError ? 'csv-file-upload-input_error' : ''"
          :placeholder="`${$t('Upload from file')} .csv`"
          density="compact"
          prepend-icon="$upload"
          variant="filled"
          @change="uploadCsvFile($event)"
        />
        <v-spacer />
        <v-col cols="auto" class="d-flex flex-grow-1 justify-end">
          <v-btn
            depressed
            class="ml-4"
            color="primary"
            :outlined="filterIsEmpty"
            width="150"
            @click="showFilters = !showFilters"
          >
            <template v-if="!showFilters">
              <v-icon class="mr-2" size="20"> $filter </v-icon>
              {{ $t('Filters') }}
            </template>
            <template v-else>
              <v-icon class="mr-2" size="20"> $up </v-icon>
              {{ $t('Collapse') }}
            </template>
          </v-btn>
        </v-col>
      </v-row>

      <v-tab-item class="pt-8" :value="`tab-1`">
        <auto-filters-component
          v-if="filterModel.length > 0"
          ref="refAutoFiltersComponent"
          v-model="filterModel"
          :show="showFilters"
          :data="filterModel"
          :is-filters-loading="isFiltersLoading"
          @getDataFilters="applyFilter"
          @filterIsEmpty="filterIsEmpty = $event"
        />
        <table-objects
          ref="refTableObjects"
          :objects="localRequests"
          :table-headers="tableHeadersLocal"
          :table-options-props="tableOptions"
          @table-options="tableOptions = $event"
          @showZones="() => {}"
          @delete="showRemoveRequestConfirm"
          @edit="showEditRequestForm"
          @print="printContent"
        />
      </v-tab-item>
      <!-- <v-tab-item class="pt-8" :value="`tab-2`">
        <filter-component
          v-model="filterModel"
          :show="showFilters"
          :data="filterModel"
          @getDataFilters="applyFilter"
          @filterIsEmpty="filterIsEmpty = $event"
        />
        <table-objects
          :objects="externalRequests"
          :table-headers="tableHeadersExternal"
          :search="filterRequests"
        />
      </v-tab-item> -->
    </v-tabs>
    <v-dialog
      v-model="requestFormDialog"
      max-width="890"
      @keydown="keydownHandler"
      @click:outside="closeDialog()"
    >
      <v-card>
        <v-icon size="16" class="card__close-btn" @click="closeDialog()">
          $close
        </v-icon>
        <h3 class="text-h2 mb-5">
          {{ isNewRequestForm ? $t('Adding a pass') : $t('Editing a pass') }}
        </h3>
        <v-card-text class="text-center pb-16 px-12">
          <v-form
            ref="requestForm"
            v-model="requestFormValid"
            class="requests__form"
            @keyup.native.enter="requestFormSubmit"
          >
            <v-card class="d-flex justify-space-between px-0 py-0" flat>
              <v-card class="requests__dialog-card" flat>
                <v-autocomplete
                  :value="requestForm.OBJECT_ID"
                  :rules="requestFormRules.OBJECT_ID"
                  disabled
                  outlined
                  :items="filteredLocalObjects"
                  :menu-props="{ offsetY: true }"
                  append-icon="$down"
                  hide-details="auto"
                  class="mb-5"
                >
                  <template #label>
                    {{ $t('Name of visited objects')
                    }}<span class="red--text">*</span>
                  </template>
                </v-autocomplete>
                <v-select
                  v-model="requestForm.STATE"
                  :items="requestStatuses"
                  :rules="requestFormRules.STATE"
                  :disabled="isNewRequestForm || !(requestStatuses.length > 1)"
                  :menu-props="{ offsetY: true }"
                  class="mb-5"
                  append-icon="$down"
                  hide-details="auto"
                  outlined
                >
                  <template #label>
                    {{ $t('Status') }}<span class="red--text">*</span>
                  </template>
                </v-select>
                <div class="d-flex">
                  <date-time-picker
                    v-model="requestForm.DATE_BEGIN"
                    :disabled="!canEditFields()"
                    class="mb-5 mr-3"
                    :class="{
                      'date-time-picker__error': isEmptyDateBegin,
                    }"
                    :color="receiveColorStates(isEmptyDateBegin)"
                    :placeholder="$t('Start actions') + '*'"
                    @getDate="setNewDateBegin"
                    @closeCalendar="setNewDateBegin"
                  />
                  <date-time-picker
                    v-model="requestForm.DATE_END"
                    :disabled="!canEditFields()"
                    class="mb-5"
                    :class="{
                      'date-time-picker__error': isEmptyDateEnd,
                    }"
                    :color="receiveColorStates(isEmptyDateEnd)"
                    :placeholder="$t('End actions') + '*'"
                    @getDate="setNewDateEnd"
                    @closeCalendar="setNewDateEnd"
                  />
                </div>
                <date-time-picker
                  v-show="!isNewRequestForm"
                  :value="requestForm.DATE_FIRST_PASS"
                  class="mb-5"
                  :placeholder="$t('Date and time of the first pass')"
                  @getDate="setNewDateEnd"
                />
                <v-text-field
                  v-model="requestForm.DATA.V_NAME"
                  :disabled="!canEditFields()"
                  :rules="requestFormRules.DATA.V_NAME"
                  hide-details="auto"
                  class="mb-5"
                  outlined
                >
                  <template #label>
                    {{ $t('Name of visitor') }}
                    <span class="red--text">*</span>
                  </template>
                </v-text-field>
              </v-card>
              <v-card class="requests__dialog-card" flat>
                <v-select
                  v-show="!isNewRequestForm"
                  :value="requestForm.INSIDE"
                  :items="translatedInsideFlags"
                  disabled
                  :menu-props="{ offsetY: true }"
                  append-icon="$down"
                  hide-details="auto"
                  outlined
                >
                  <template #label>
                    {{ $t('Pass inside status') }}
                  </template>
                </v-select>
                <v-text-field
                  v-model="requestForm.DATA.V_PHONE"
                  :rules="requestFormRules.DATA.V_PHONE"
                  :disabled="!canEditFields()"
                  hide-details="auto"
                  outlined
                >
                  <template #label>
                    {{ $t('Phone number') }}
                    <span v-if="isRequireField('V_PHONE')" class="red--text"
                      >*</span
                    >
                  </template>
                </v-text-field>
                <v-text-field
                  v-model="requestForm.DATA.V_EMAIL"
                  :rules="requestFormRules.DATA.V_EMAIL"
                  :disabled="!canEditFields()"
                  hide-details="auto"
                  outlined
                  type="email"
                >
                  <template #label>
                    {{ $t('Email') }}
                    <span v-if="isRequireField('V_EMAIL')" class="red--text"
                      >*</span
                    >
                  </template>
                </v-text-field>

                <v-textarea
                  v-model="requestForm.DATA.NOTICE"
                  :disabled="!canEditFields()"
                  :label="$t('Comments')"
                  :class="{ 'mt-1': !isNewRequestForm }"
                  :rows="1"
                  row-height="30"
                  hide-details="auto"
                  outlined
                  no-resize
                />
                <span :class="{ 'text--disabled': !canEditFields() }">
                  {{ $t('Reusable pass') }} <span class="red--text">*</span>
                </span>
                <multi-pass-radio-btn-new
                  :is-multi-pass-prop="requestForm.MULTI_PASS"
                  :allowed-pass-type-prop="allowedPassType"
                  :default-pass-type-prop="defaultPassType"
                  :is-new-request-form-prop="isNewRequestForm"
                  :is-disabled-all="!canEditFields()"
                  :rules-props="requestFormRules.MULTI_PASS"
                  @update-state-fabric="updateMultiPass"
                />
              </v-card>
            </v-card>
            <v-btn
              depressed
              block
              color="primary"
              :disabled="
                !(
                  requestFormValid &&
                  !!requestForm.DATE_BEGIN &&
                  !!requestForm.DATE_END
                )
              "
              @click="requestFormSubmit"
            >
              {{ isNewRequestForm ? $t('Add') : $t('Save') }}
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <confirm-remove-dialog
      v-if="requestRemoveConfirm"
      :loading="requestRemoveLoading"
      @closeDialog="requestRemoveConfirm = false"
      @confirm="removeRequest"
    >
      <template #title>
        {{ $t('Delete local application') }} «{{ requestForm.DATA.V_NAME }}»?
      </template>
      <template #default>
        {{
          $t(
            'After deletion, it will be impossible to restore the data of the local pass.',
          )
        }}
      </template>
    </confirm-remove-dialog>
    <RadioListDialog
      v-if="isObjectRolesDialogShow"
      :objects="filteredLocalObjects"
      :title="$t('Adding a pass')"
      button-text="Select"
      :headers="[{ text: 'NAME', value: 'text' }]"
      :single-select="true"
      :selected-item-id-prop="requestForm.OBJECT_ID"
      :dialog-description="$t('Select an object to visit')"
      subtitle=""
      item-key="value"
      @closeDialog="isObjectRolesDialogShow = false"
      @saveForm="saveObjectRole"
    />

    <ParkingDialog
      :is-show="isParkingDialogShow"
      :object-id="requestForm.OBJECT_ID"
      :is-new-request-form="isNewRequestForm"
      :car-form-props="parkingForm"
      :request-form="requestForm"
      :request-form-config="requestFormConfig"
      :filtered-local-objects="filteredLocalObjects"
      :allowed-pass-type="allowedPassType"
      :default-pass-type="defaultPassType"
      @requestStatuses="requestStatuses()"
      @close-dialog="isParkingDialogShow = false"
      @add-request="pushNewParkingRequest"
      @edit-request="editParkingRequest"
    />
    <print-form
      v-if="Object.keys(printForm).length"
      ref="printable"
      :print-form="printForm"
    />
    <v-dialog v-model="isSuggestionDialogShow" max-width="520">
      <v-card>
        <v-icon
          size="16"
          class="card__close-btn"
          @click="isSuggestionDialogShow = false"
        >
          $close
        </v-icon>
        <v-card-title class="card__title-wrapper">
          <h3 class="card__title">
            {{ $t('Parking pass') }}
          </h3>
        </v-card-title>

        <v-card-text class="mb-6">
          {{ $t('Create a parking request') }}?
        </v-card-text>

        <v-card-actions class="card__actions">
          <v-btn
            class="card__action-btn"
            color="primary"
            outlined
            depressed
            @click="isSuggestionDialogShow = false"
          >
            {{ $t('No') }}
          </v-btn>
          <v-btn
            class="card__action-btn card__action-btn_white"
            color="primary"
            depressed
            @click="submitSuggestionDialog"
          >
            {{ $t('Yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style lang="scss">
@import './requests';
</style>
