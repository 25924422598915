var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}]},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isFiltersLoading),expression:"!isFiltersLoading"}],staticClass:"mb-4 pa-1",style:(`background-color: ${_vm.backgroundColor}`)},[_c('div',{staticClass:"filter-grid"},_vm._l((_vm.preparedFilters),function(filter,index){return _c('div',{key:index,class:{ 'hidden-field': !filter.is_active || filter.type === null }},[(_vm.isNumericOrTextField(filter.type))?_c('v-text-field',{staticClass:"filter-item white",attrs:{"value":filter?.data,"label":filter.name,"dense":"","outlined":"","hide-details":"auto","clearable":"","clear-icon":"$closeCircle","type":_vm.getFieldType(filter.type),"rules":_vm.getFieldRules(filter),"disabled":!!filter.disabled},on:{"input":function($event){return _vm.updateFilterData($event, filter)},"click:clear":function($event){return _vm.clearField(filter)}}}):_vm._e(),(filter.type === _vm.FILTER_TYPES.TIMESTAMP)?_c('date-time-picker',{staticClass:"filter-item__date",attrs:{"value":filter?.data,"placeholder":filter.name,"dense":"","disabled":!!filter.disabled},on:{"getDate":(date) => (filter.data = date)}}):_vm._e(),(filter.type === _vm.FILTER_TYPES.DATE)?_c('date-time-picker',{staticClass:"filter-item__date",attrs:{"value":filter?.data,"placeholder":filter.name,"dense":"","setting":{
            format: 'YYYY.MM.DD',
          },"disabled":!!filter.disabled},on:{"getDate":(date) => (filter.data = date)}}):_vm._e(),(filter.type === _vm.FILTER_TYPES.TIME)?_c('date-time-picker',{staticClass:"filter-item__date",attrs:{"value":filter?.data,"placeholder":filter.name,"dense":"","setting":{
            type: 'time',
            format: 'HH:mm',
            iconCalendar: '$timeIcon',
          },"disabled":!!filter.disabled},on:{"getDate":(date) => (filter.data = date)}}):_vm._e(),(
            filter.type === _vm.FILTER_TYPES.ENUM ||
            filter.type === _vm.FILTER_TYPES.SELECT
          )?_c('v-select',{staticClass:"filter-item white",attrs:{"value":filter?.data,"items":filter.possible_values,"label":filter.name,"item-text":"name","item-value":"id","menu-props":{
            offsetY: true,
            closeOnClick: true,
            closeOnContentClick: !filter.is_array,
          },"append-icon":"$down","hide-details":"auto","multiple":filter.is_array,"outlined":"","dense":"","clearable":"","clear-icon":"$closeCircle","disabled":!!filter.disabled},on:{"input":function($event){return _vm.onSelect($event, filter.key)}}}):_vm._e()],1)}),0),_c('div',{staticClass:"filter__actions"},[_c('v-btn',{staticClass:"filter-item",attrs:{"depressed":"","color":"primary"},on:{"click":_vm.getDataFilters}},[_vm._v(" "+_vm._s(_vm.$t('Apply'))+" ")]),(true)?_c('v-btn',{attrs:{"depressed":"","color":"primary","text":""},on:{"click":_vm.clearFilters}},[_c('v-icon',{staticClass:"mr-2",attrs:{"size":"18"}},[_vm._v(" $close ")]),_vm._v(" "+_vm._s(_vm.$t('Clear'))+" ")],1):_vm._e()],1)]),(_vm.isFiltersLoading)?_c('div',{staticClass:"filters__skeleton"},_vm._l((16),function(_,index){return _c('v-skeleton-loader',{key:index,attrs:{"type":"image","width":"calc(25% - 10px)","height":"40px"}})}),1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }