var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-data-table',{staticClass:"table-striped",attrs:{"loading-text":`${_vm.$t('Loading')}...`,"sort-by":"ID","footer-props":{
      'items-per-page-text': `${_vm.$t('Records per page')}:`,
      'items-per-page-options': [15, 25, 50, 100],
    },"headers":_vm.translatedTableHeaders,"header-props":{ 'sort-icon': '$swap' },"items":_vm.objects,"items-per-page":-1,"loading":_vm.tableLoading,"no-data-text":_vm.$t('Viewing data is prohibited'),"no-results-text":_vm.$t('Data not found'),"sort-desc":true},scopedSlots:_vm._u([{key:`item.ACTIONS`,fn:function({ item }){return [_c('div',{staticClass:"d-inline-flex"},[_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$emit('showZones', item)}}},'v-icon',attrs,false),on),[_vm._v(" $location ")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t("Access zones"))+" ")]),(_vm.type === 'local')?[_c('v-icon',{staticClass:"mr-4",attrs:{"color":"red"},on:{"click":function($event){return _vm.$emit('delete', item)}}},[_vm._v(" $delete ")]),_c('v-icon',{staticClass:"mr-4",attrs:{"color":item.STATUS != 2 ? 'red' : 'green'},on:{"click":function($event){return _vm.$emit('block', item)}}},[_vm._v(" $lock ")]),_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$emit('edit', item)}}},[_vm._v(" $edit ")])]:_vm._e()],2)]}},{key:`item.STATUS`,fn:function({ item }){return [_c('span',{class:item.STATUS !== 1 ? 'red--text' : 'green--text'},[_vm._v(" "+_vm._s(item.STATUS_NAME)+" ")])]}},{key:`item.LOGO`,fn:function({ item }){return [_c('img',{staticStyle:{"width":"25px","height":"25px"},attrs:{"src":item.LOGO
            ? _vm.convertUserLogo(item.LOGO)
            : require('../../assets/images/default-avatar.png'),"alt":"logo"}})]}},{key:`item.LOGO_WALLET`,fn:function({ item }){return [_c('img',{staticStyle:{"width":"25px","height":"25px"},attrs:{"src":item.LOGO_WALLET
            ? _vm.convertUserLogo(item.LOGO_WALLET)
            : require('../../assets/images/default-avatar.png'),"alt":"logo_wallet"}})]}},{key:`item.SYSTEM_ID`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.STATUS_NAME)+" ")]}},{key:`item.REQUEST_TYPE`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.$t(_vm.REQUEST_TYPES.find((elem) => elem.value == item.REQUEST_TYPE)?.text))+" ")]}},{key:"footer.page-text",fn:function({ pageStart, pageStop, itemsLength }){return [_vm._v(" "+_vm._s(pageStart)+" - "+_vm._s(pageStop)+" "+_vm._s(_vm.$t('of'))+" "+_vm._s(itemsLength)+" ")]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }