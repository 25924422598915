<template>
  <v-dialog
    max-width="775"
    :value="isShow"
    @click:outside="$emit('close-dialog')"
  >
    <v-card>
      <v-icon size="16" class="card__close-btn" @click="$emit('close-dialog')">
        $close
      </v-icon>
      <v-card-title class="card__title-wrapper">
        <div class="card__title-with-btn">
          <h3 class="card__title">
            {{ $t("External readers") }}
          </h3>
          <v-btn
            v-if="isValueStraightTreeMixin('devices_edit_readers')"
            depressed
            color="primary"
            @click="addExternalReader"
          >
            <v-icon size="18" left class="mr-2">$plus</v-icon>
            {{ $t("Add") }}
          </v-btn>
        </div>
      </v-card-title>

      <v-data-table
        class="table-striped"
        :headers="translatedTableHeaders"
        :items="tableItems"
        :no-data-text="$t('Data not found')"
        :no-results-text="$t('Data not found')"
        :footer-props="{
          'items-per-page-text': $t('Lines per page'),
          'items-per-page-options': [5, 10],
        }"
      >
        <template #[`item.IS_ENTRY`]="{ item }">
          {{ item.IS_ENTRY ? $t("Entry") : $t("Exit") }}
        </template>
        <template #[`item.ACTIONS`]="{ item }">
          <div
            class="d-inline-flex"
            v-if="isValueStraightTreeMixin('devices_edit_readers')"
          >
            <v-icon color="red" @click="removeExternalReader(item)">
              $delete
            </v-icon>
          </div>
        </template>
        <template
          v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }"
        >
          {{ pageStart }} - {{ pageStop }} {{ $t("of") }} {{ itemsLength }}
        </template>
      </v-data-table>
    </v-card>
  </v-dialog>
</template>

<script>
import api from "@/api";
import isValueStraightTreeMixin from "@/mixins/isValueStraightTreeMixin.js";

export default {
  props: {
    isShow: {
      type: Boolean,
      required: true,
    },
    tableHeaders: {
      type: Array,
      required: true,
    },
    tableItems: {
      type: Array,
      required: true,
    },
    deviceId: {
      type: [Number, null],
      default: null,
    },
  },
  mixins: [isValueStraightTreeMixin],
  data() {
    return {
      externalReaders: this.tableItems,
    };
  },
  computed: {
    translatedTableHeaders() {
      return this.tableHeaders.map((elem) => ({
        ...elem,
        text: this.$t(elem.text),
      }));
    },
  },
  methods: {
    async removeExternalReader(externalReader) {
      await api.devices.removeExternalReaders(
        this.deviceId,
        externalReader.READER_ID
      ).catch((error) => {
        this.$store.dispatch(SNACK_ADD_MESSAGE, error.response.data.MESSAGE);
      });
      const currentReaderIndex = this.externalReaders.findIndex(
        (reader) => reader.ID === externalReader.READER_ID
      );
      this.externalReaders.splice(currentReaderIndex, 1);
    },
    addExternalReader() {
      this.$emit("add-external-reader");
      this.$emit("close-dialog");
    },
  },
  watch: {
    tableItems() {
      this.externalReaders = this.tableItems;
    },
  },
};
</script>
