<template>
  <div>
    <v-row class="mb-5">
      <v-col cols="auto" align-self="center">
        <h2 class="text-h2">
          {{ $t("Gate") }}
        </h2>
      </v-col>
      <v-col cols="auto">
        <v-btn
          v-if="isValueStraightTreeMixin('gates_edit')"
          depressed
          color="primary"
          @click="addGate"
        >
          <v-icon size="18" class="mr-2">$plus</v-icon>
          {{ $t("Add") }}
        </v-btn>
      </v-col>
      <v-col cols="auto" class="d-flex flex-grow-1 justify-end">
        <v-btn
          depressed
          class="ml-4"
          color="primary"
          :outlined="filterIsEmpty"
          width="150"
          @click="showFilters = !showFilters"
        >
          <template v-if="!showFilters">
            <v-icon class="mr-2" size="20">$filter</v-icon>
            {{ $t("Filters") }}
          </template>
          <template v-else>
            <v-icon class="mr-2" size="20">$up</v-icon>
            {{ $t("Collapse") }}
          </template>
        </v-btn>
      </v-col>
    </v-row>

    <filter-component
      v-model="filterModel"
      :show="showFilters"
      :data="filterModel"
      @getDataFilters="applyFilter"
      @filterIsEmpty="filterIsEmpty = $event"
    />

    <v-data-table
      :headers="translatedTableHeaders"
      :items="tableItems"
      sort-by="ID"
      :sort-desc="true"
      :items-per-page="-1"
      :loading="tableLoading"
      :header-props="{ 'sort-icon': '$swap' }"
      :loading-text="`${$t('Loading')}...`"
      class="table-striped"
      :no-data-text="
        userInfo.PERMISSION.gate_view
          ? $t('Data not found')
          : $t('Viewing data is prohibited')
      "
      :no-results-text="$t('Data not found')"
      :footer-props="{
        'items-per-page-text': $t('Lines per page'),
        'items-per-page-options': [15, 25, 50, 100],
      }"
    >
      <template #[`item.STATUS`]="{ item }">
        <span :class="item.STATUS !== 1 ? 'red--text' : 'green--text'">
          {{ getStatusText(item.STATUS) }}
        </span>
      </template>
      <template #[`item.ACTIONS`]="{ item }">
        <div class="d-inline-flex">
          <v-tooltip bottom color="primary">
            <template #activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                class="mr-4"
                color="primary"
                v-on="on"
                @click="showDevicesDialog(item)"
              >
                $device
              </v-icon>
            </template>
            {{ $t("Devices") }}
          </v-tooltip>
          <v-icon
            v-if="isValueStraightTreeMixin('gates_edit')"
            class="mr-4"
            color="red"
            @click="showRemoveGateDialog(item)"
          >
            $delete
          </v-icon>
          <v-icon
            v-if="isValueStraightTreeMixin('gates_edit')"
            class="mr-4"
            :color="item.STATUS !== 2 ? 'red' : 'green'"
            @click="toggleGateStatus(item)"
          >
            $lock
          </v-icon>
          <v-icon
            v-if="isValueStraightTreeMixin('gates_edit')"
            color="primary"
            @click="editGate(item)"
          >
            $edit
          </v-icon>
        </div>
      </template>
      <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
        {{ pageStart }} - {{ pageStop }} {{ $t("of") }} {{ itemsLength }}
      </template>
    </v-data-table>

    <GateDialog
      :is-show="isGateFormActive"
      :is-new-gate-form="isNewGateForm"
      :gate-form-props="gateForm"
      :server-list="formattedServers"
      @close-dialog="isGateFormActive = false"
      @add-gate="addNewGateToTable"
      @change-gate="applyGateChangeInTable"
    />

    <CheckboxListDialog
      v-if="isShowDevicesDialog"
      :objects="devices"
      :title="$t('Devices')"
      :subtitle="gateForm.NAME"
      :headers="[{ text: 'NAME', value: 'DEVICE_NAME' }]"
      :max-width="520"
      item-key="DEVICE_ID"
      :show-checkboxes="false"
      @closeDialog="isShowDevicesDialog = false"
    />

    <ConfirmRemoveDialog
      v-if="isShowGateRemoveDialog"
      :loading="gateRemoveLoading"
      @closeDialog="isShowGateRemoveDialog = false"
      @confirm="removeGate(gateForm)"
    >
      <template #title>
        <h3 class="card__title card__title_small">
          {{ $t("Delete gate") }} «{{ gateForm.NAME }}»?
        </h3>
      </template>
      <template #default>
        {{ $t("After deletion, it will be impossible to restore gate data") }}.
      </template>
    </ConfirmRemoveDialog>
  </div>
</template>

<script>
import api from "@/api";
import { mapState } from "vuex";
import { SNACK_ADD_MESSAGE } from "@/store/types/action-types";
import FilterComponent from "@/components/blocks/FilterComponent.vue";
import { tableHeaders, getFilterModels } from "./data.gates";
import GateDialog from "./gateDialog.vue";
import CheckboxListDialog from "@/components/blocks/CheckboxListDialog.vue";
import ConfirmRemoveDialog from "@/components/blocks/ConfirmRemoveDialog.vue";
import isValueStraightTreeMixin from "@/mixins/isValueStraightTreeMixin.js";
import { showSnackbarMessage } from '@/helpers/helpers';

export default {
  name: "Gates",
  components: {
    FilterComponent,
    GateDialog,
    CheckboxListDialog,
    ConfirmRemoveDialog,
  },
  mixins: [isValueStraightTreeMixin],
  data() {
    return {
      tableHeaders,

      gates: [],
      gateForm: this.createGateForm(),
      servers: [],

      // filter
      filterModel: getFilterModels(),
      filterIsEmpty: true,
      showFilters: false,

      // table
      tableItems: [],
      tableLoading: false,

      // gate dialog
      isNewGateForm: false,
      isGateFormActive: false,

      // devices dialog
      devices: [],
      isShowDevicesDialog: false,

      // delete dialog
      isShowGateRemoveDialog: false,
      gateRemoveLoading: false,
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.model,
    }),
    translatedTableHeaders() {
      return this.tableHeaders.map((elem) => ({
        ...elem,
        text: this.$t(elem.text),
      }));
    },
    formattedServers() {
      return this.servers.map((server) => ({
        text: server.NAME,
        value: server.ID,
      }));
    },
  },
  async mounted() {
    this.getServers();
    await this.getGates();
    this.tableItems = this.gates;
    this.filterModel = getFilterModels();
  },
  methods: {
    createGateForm(gate = {}) {
      return {
        ID: gate.ID ?? null,
        NAME: gate.NAME ?? "",
        STATUS: gate.STATUS ?? null,
        SERVER_ID: gate.SERVER_ID ?? null,
        DESCRIPTION: gate.DESCRIPTION ?? "",
      };
    },
    async applyFilter(filterData) {
      const name = filterData.find((item) => item.name === "NAME").data;
      const status = filterData.find((item) => item.name === "STATUS").data;
      const res = await api.gates.getAll({ name, status });
      this.tableItems = res.data.DATA;
    },
    getStatusText(statusNumber) {
      return this.$store.getters?.getEnums["ENUM.Gate.STATUS"]?.find(
        (status) => status.NUMBER === statusNumber
      ).NAME;
    },
    showRemoveGateDialog(gate) {
      this.gateForm = this.createGateForm(gate);
      this.isShowGateRemoveDialog = true;
    },
    async getGates() {
      const res = await api.gates.getAll();
      this.gates = res.data.DATA;
    },
    async getServers() {
      const res = await api.servers.getAll();
      this.servers = res.data.DATA;
    },
    async getGateDevices(gateId) {
      const response = await api.gates.getDevices(gateId);
      const devices = response.data.DATA;

      if (typeof devices === "boolean") {
        return [];
      }

      if (!Array.isArray(devices) && typeof devices === "object") {
        return [devices];
      }

      return devices;
    },
    async removeGate(item) {
      try {
        await api.gates.remove(item.ID);
        const currentGateIndex = this.tableItems.findIndex((gate) => {
          return gate.ID === item.ID;
        });
        this.tableItems.splice(currentGateIndex, 1);
        this.isShowGateRemoveDialog = false;
      } catch (error) {
        showSnackbarMessage(error.response.data.MESSAGE);
      }
    },
    async toggleGateStatus(item) {
      try {
        const response = await api.gates.changeStatus(
          item.ID,
          item.STATUS === 1 ? 2 : 1
        );
        item.STATUS = response.data.DATA.STATUS;
      } catch (error) {
        showSnackbarMessage(error.response.data.MESSAGE);``
      }
    },

    // методы для работы с модалкой КПП
    addGate() {
      this.gateForm = this.createGateForm();
      this.isNewGateForm = true;
      this.isGateFormActive = true;
    },
    editGate(gate) {
      this.gateForm = this.createGateForm(gate);
      this.isNewGateForm = false;
      this.isGateFormActive = true;
    },
    addNewGateToTable(gate) {
      this.tableItems.push(gate);
    },
    applyGateChangeInTable(gate) {
      const gateIndex = this.tableItems.findIndex(
        (item) => item.ID === gate.ID
      );
      this.$set(this.tableItems, gateIndex, gate);
    },

    // Методы для работы с модалкой Устройств
    async showDevicesDialog(gate) {
      this.gateForm = this.createGateForm(gate);
      const devices = await this.getGateDevices(this.gateForm.ID);
      this.devices = devices;
      this.isShowDevicesDialog = true;
    },
  },
};
</script>
