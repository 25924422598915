import {
  showDefaultDialog,
  isValueStraightTree,
} from '@/helpers/helpers';
import { generalKeyCheck } from './data.middleware';
import i18n from '@/plugins/vueI18n';

export default async function common({ next, store, to }) {
  if (!store.getters.isLoggedIn) {
    return next();
  }
  for (const item of generalKeyCheck) {
    if (isValueStraightTree(item.treeKey) && to.path === item.redirect) {
      return next();
    }
  }

  for (const item of generalKeyCheck) {
    if (isValueStraightTree(item.treeKey)) {
      return next(item.redirect);
    }
  }
  showAccessDeniedDialog(store, next);
}

function showAccessDeniedDialog(store, next) {
  showDefaultDialog({
    title: i18n.t('Attention'),
    text: i18n.t('Access denied, contact administrator'),
    functionOnClose: () => {
      store.dispatch('logout');
      next('/login');
    },
  });
}
