<template>
  <div>
    <div v-show="!isRoleResolution">
      <v-tabs class="srp-tabs mb-5 border-bottom-0 d-flex flex-column">
        <v-row class="mb-5">
          <v-col cols="auto" align-self="center">
            <h2 class="text-h2">
              {{ $t("Roles") }}
            </h2>
          </v-col>
          <v-col cols="auto">
            <v-btn
              v-if="isValueStraightTreeMixin('roles_edit')"
              depressed
              color="primary"
              @click="showNewRoleForm"
            >
              <v-icon size="18" class="mr-2">$plus</v-icon>
              {{ $t("Add") }}
            </v-btn>
          </v-col>
          <v-col cols="auto" class="d-flex flex-grow-1 justify-end">
            <v-btn
              depressed
              class="ml-4"
              color="primary"
              :outlined="filterIsEmpty"
              width="150"
              @click="showFilters = !showFilters"
            >
              <template v-if="!showFilters">
                <v-icon class="mr-2" size="20">$filter</v-icon>
                {{ $t("Filters") }}
              </template>
              <template v-else>
                <v-icon class="mr-2" size="20">$up</v-icon>
                {{ $t("Collapse") }}
              </template>
            </v-btn>
          </v-col>
        </v-row>
      </v-tabs>
      <filter-component
        v-model="filterModel"
        :show="showFilters"
        :data="filterModel"
        @getDataFilters="applyFilter"
        @filterIsEmpty="filterIsEmpty = $event"
      />

      <v-data-table
        :headers="translatedTableHeaders"
        :items="tableItems"
        sort-by="ID"
        :sort-desc="true"
        :items-per-page="-1"
        :loading="tableLoading"
        :header-props="{ 'sort-icon': '$swap' }"
        :loading-text="`${$t('Loading')}...`"
        class="table-striped"
        :no-data-text="$t('Data not found')"
        :no-results-text="$t('Data not found')"
        :footer-props="{
          'items-per-page-text': $t('Lines per page'),
          'items-per-page-options': [15, 25, 50, 100],
        }"
      >
        <template #[`item.ACTIONS`]="{ item }">
          <div class="d-inline-flex">
            <v-tooltip
              bottom
              color="primary"
              v-if="
                isValueStraightTreeMixin('roles_view_permissions') ||
                isValueStraightTreeMixin('roles_edit_permissions')
              "
            >
              <template #activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  class="mr-4"
                  color="primary"
                  v-on="on"
                  @click="showPermissionsAndProhibitionDialog(item)"
                >
                  $writingIcon
                </v-icon>
              </template>
              {{ $t("Permissions") }}
            </v-tooltip>
            <v-icon
              class="mr-4"
              color="red"
              @click="showRemoveRoleConfirm(item)"
              v-if="isValueStraightTreeMixin('roles_edit')"
            >
              $delete
            </v-icon>
            <v-icon
              class="mr-4"
              :color="item.STATUS !== 2 ? 'red' : 'green'"
              @click="toggleRoleStatus(item)"
              v-if="isValueStraightTreeMixin('roles_edit')"
            >
              $lock
            </v-icon>
            <v-icon
              v-if="isValueStraightTreeMixin('roles_edit')"
              color="primary"
              @click="showEditRoleForm(item)"
            >
              $edit
            </v-icon>
          </div>
        </template>
        <template #[`item.STATUS`]="{ item }">
          <span :class="item.STATUS !== 1 ? 'red--text' : 'green--text'">
            {{
              $t(ROLE_STATUSES.find((elem) => elem.value == item.STATUS)?.text)
            }}
          </span>
        </template>
        <template
          v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }"
        >
          {{ pageStart }} - {{ pageStop }} {{ $t("of") }} {{ itemsLength }}
        </template>
      </v-data-table>
    </div>

    <FunctionalRolesDialog
      :is-dialog-active="isDialogActive"
      :is-new-role-form="isNewRoleForm"
      :role-form-prop="roleForm"
      @close-dialog="isDialogActive = false"
      @form-submit="onFormSubmit"
    />

    <ConfirmRemoveDialog
      v-if="roleRemoveConfirm"
      :loading="roleRemoveLoading"
      @closeDialog="roleRemoveConfirm = false"
      @confirm="removeRole"
    >
      <template #title>
        {{ $t("Delete role") }} «{{ roleForm.NAME }}»?
      </template>
      <template #default>
        {{ $t("After deletion, it will be impossible to restore role data") }}.
      </template>
    </ConfirmRemoveDialog>

    <RoleResolution
      v-show="isRoleResolution"
      :roleStructure="roleStructure"
      :roleStructureNotEditable="roleStructureNotEditable"
      :roleForm="roleForm"
      @hide-role-resolution="isRoleResolution = false"
      @saveRoleStructureNotEditable="saveRoleStructureNotEditable"
    ></RoleResolution>

    <ObjectsRolesDialog
      :is-show="isObjectsRolesDialogActive"
      :title="$t('Objects')"
      :headers="objectTableHeaders"
      item-key="NAME"
      :id="roleForm.ID"
      @close-dialog="isObjectsRolesDialogActive = false"
    />
  </div>
</template>

<script>
import api from "@/api";
import { ROLE_STATUSES, ROLE_TYPES } from "@/constants";
import { SNACK_ADD_MESSAGE } from "@/store/types/action-types";
import { COMMON_SET_LOADING } from "@/store/types/mutation-types";
import PermissionsAndProhibition from "@/components/blocks/PermissionsAndProhibition.vue";
import FilterComponent from "@/components/blocks/FilterComponent.vue";
import FunctionalRolesDialog from "./functionalRolesDialog.vue";
import ConfirmRemoveDialog from "@/components/blocks/ConfirmRemoveDialog.vue";
import ObjectsRolesDialog from "./objectsRolesDialog.vue";
import { filterModel, tableHeaders, objectTableHeaders } from "./data.roles";
import RoleResolution from "./RoleResolution.vue";
import { mapMutations, mapState } from "vuex";
import isValueStraightTreeMixin from "@/mixins/isValueStraightTreeMixin.js";

export default {
  name: "Roles",
  components: {
    FilterComponent,
    FunctionalRolesDialog,
    ConfirmRemoveDialog,
    PermissionsAndProhibition,
    ObjectsRolesDialog,
    RoleResolution,
  },
  mixins: [isValueStraightTreeMixin],
  data() {
    return {
      ROLE_STATUSES,
      ROLE_TYPES,
      tableHeaders,
      objectTableHeaders,

      // roleForm
      roleForm: this.createRoleFormModel(),

      // filter
      filterModel,
      filterIsEmpty: true,
      showFilters: false,

      // table
      tableItems: [],
      tableLoading: false,

      // remove dialog
      roleRemoveConfirm: false,
      roleRemoveLoading: false,

      isNewRoleForm: true,
      isDialogActive: false,
      functionalRoles: [],
      objectRoles: [],

      isRoleResolution: false,
      isObjectsRolesDialogActive: false,

      roleStructure: [],
      roleStructureNotEditable: [],
    };
  },
  computed: {
    ...mapState({
      roleListCommon: (state) => state.role.roleListCommon,
    }),
    translatedTableHeaders() {
      return this.tableHeaders.map((elem) => ({
        ...elem,
        text: this.$t(elem.text),
      }));
    },
  },
  async mounted() {
    this.$store.commit(COMMON_SET_LOADING);

    try {
      await this.updateRoleList();
      this.tableItems = this.functionalRoles;
    } finally {
      this.$store.commit(COMMON_SET_LOADING, false);
    }
  },
  watch: {
    roleListCommon(newValue) {
      this.roleStructure = newValue;
    },
  },
  methods: {
    ...mapMutations(["updateRoleListCommon"]),
    saveRoleStructureNotEditable() {
      this.roleStructureNotEditable = _.cloneDeep(this.roleListCommon);
    },
    getRolePermission(id) {
      this.$store.commit(COMMON_SET_LOADING);
      this.roleStructure = [];
      this.roleStructureNotEditable = [];
      api.roles
        .getRolePermission(id)
        .then((res) => {
          const data = res.data.DATA;
          if (data) {
            const parsedData = JSON.parse(data);
            this.roleStructure = parsedData;
            this.roleStructureNotEditable = _.cloneDeep(parsedData);
            this.updateRoleListCommon(parsedData);
          } else {
            console.warn("Получены некорректные данные от API");
          }
        })
        .catch((error) => {
          console.error("Ошибка при загрузке структуры ролей:", error);
        })
        .finally(() => {
          this.$store.commit(COMMON_SET_LOADING, false);
        });
    },
    async getRoles(params = {}) {
      const response = await api.roles.getAll(params);
      return response.data.DATA;
    },
    async updateRoleList(params = {}) {
      this.functionalRoles = await this.getRoles(params);
      this.tableItems = this.functionalRoles;
    },
    createRoleFormModel(role = {}) {
      return {
        ID: role.ID ?? null,
        NAME: role.NAME ?? "",
        STATUS: role.STATUS ?? null,
        DESCRIPTION: role.DESCRIPTION ?? "",
      };
    },

    applyFilter() {
      const status = this.getValueFromFilter("STATUS");
      const name = this.getValueFromFilter("NAME");
      this.updateRoleList({ name, status });
    },
    getValueFromFilter(field) {
      return this.filterModel.find((item) => item.name === field).data;
    },

    async toggleRoleStatus(item) {
      const status = item.STATUS === 1 ? 2 : 1;

      this.$store.commit(COMMON_SET_LOADING);
      try {
        const res = await api.roles.edit({
          id: item.ID,
          name: item.NAME,
          status,
          description: item.DESCRIPTION,
        });
        await this.updateRoleList();
        this.$store.dispatch(SNACK_ADD_MESSAGE, {
          type: "success",
          text:
            this.$i18n.locale === "ru"
              ? res.data.MESSAGE
              : this.$t(res.data.MESSAGE),
        });
      } catch (error) {
        this.$store.dispatch(
          SNACK_ADD_MESSAGE,
          this.$t(error.response.data.MESSAGE)
        );
      } finally {
        this.$store.commit(COMMON_SET_LOADING, false);
      }
    },

    showPermissionsAndProhibitionDialog(role) {
      this.getRolePermission(role.ID);
      this.roleForm = this.createRoleFormModel({ ...role });
      this.isRoleResolution = true;
    },

    // Add, Edit dialog
    showNewRoleForm() {
      this.isNewRoleForm = true;
      this.isDialogActive = true;
    },
    showEditRoleForm(role) {
      this.roleForm = this.createRoleFormModel(role);
      this.isNewRoleForm = false;
      this.isDialogActive = true;
    },
    async onFormSubmit(formData) {
      const roleData = {
        name: formData.NAME,
        status: formData.STATUS,
        description: formData.DESCRIPTION,
        roletype: formData.ROLETYPE,
      };
      const apiMethod = this.isNewRoleForm ? api.roles.add : api.roles.edit;
      if (!this.isNewRoleForm) {
        roleData.id = formData.ID;
      }
      try {
        await apiMethod(roleData);
        await this.updateRoleList();
      } catch (error) {
        this.$store.dispatch(SNACK_ADD_MESSAGE, error.response.data.MESSAGE);
      }
      this.isDialogActive = false;
    },

    // Remove dialog
    showRemoveRoleConfirm(item) {
      this.roleForm = this.createRoleFormModel(item);
      this.roleRemoveConfirm = true;
    },
    async removeRole() {
      try {
        await api.roles.remove(this.roleForm.ID);
        await this.updateRoleList();
      } catch (error) {
        this.$store.dispatch(SNACK_ADD_MESSAGE, error.response.data.MESSAGE);
      }
      this.roleRemoveConfirm = false;
    },
  },
};
</script>
