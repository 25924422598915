<template>
  <div>
    <v-card class="add-roles-dialog">
      <button
        @click="clickReturn()"
        class="mb-3 d-flex align-center text-color-primary"
      >
        <v-icon class="mr-1 text-color-primary" size="20">$back</v-icon>
        {{ $t("Return to all roles") }}
      </button>
      <v-icon size="16" class="card__close-btn" @click="closeDialog()">
        $close
      </v-icon>
      <v-card-title class="card__title-wrapper">
        <div class="card__title-with-btn">
          <h3 class="card__title">{{ $t("Adding a role") }}</h3>
        </div>
      </v-card-title>
      <v-data-table
        class="table-striped"
        :headers="translatedTableHeaders"
        :items="tableItems"
        :no-data-text="$t('Data not found')"
        :no-results-text="$t('Data not found')"
        checkbox-color="primary"
        :footer-props="{
          'items-per-page-text': $t('Lines per page'),
          'items-per-page-options': [5, 10],
        }"
        v-model="selectedReaders"
        item-key="ID"
        show-select
      >
        <template #[`item.STATUS_NAME`]="{ item }">
          <div :class="item.STATUS !== 1 ? 'red--text' : 'green--text'">
            {{ item.STATUS_NAME }}
          </div>
        </template>
        <template #[`header.data-table-select`] />
        <template
          v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }"
        >
          {{ pageStart }} - {{ pageStop }} {{ $t("of") }} {{ itemsLength }}
        </template>
      </v-data-table>
      <v-btn
        class="mt-5"
        depressed
        block
        color="primary"
        @click="addExternalReader"
        :disabled="!selectedReaders.length"
      >
        <v-icon size="18" left class="mr-2">$plus</v-icon>
        {{ $t("Add") }}
      </v-btn>
    </v-card>
  </div>
</template>

<script>
import { addRolessHeaders } from "./data.user";
export default {
  props: {
    tableItems: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      addRolessHeaders,
      selectedReaders: [],
    };
  },
  methods: {
    addExternalReader() {
      const selectedReadersId = this.selectedReaders.map((reader) => reader.ID);
      this.$emit("addRoleUser", selectedReadersId);
      this.$emit("go-back");
      this.clearSelectedReaders();
    },
    clearSelectedReaders() {
      this.selectedReaders = [];
    },
    closeDialog() {
      this.clearSelectedReaders();
      this.$emit("close-dialog");
    },
    clickReturn() {
      this.clearSelectedReaders();
      this.$emit("go-back");
    },
  },
  computed: {
    translatedTableHeaders() {
      return this.addRolessHeaders.map((elem) => ({
        ...elem,
        text: this.$t(elem.text),
      }));
    },
  },
};
</script>

<style lang="scss">
.add-roles-dialog {
  .text-color-primary {
    color: $primary;
  }
}
</style>
