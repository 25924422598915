<template>
<svg viewBox="0 0 16 3" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g id="Group 3785">
    <rect id="Rectangle 79" x="16" y="0.5" width="2" height="16" rx="1" transform="rotate(90 16 0.5)" fill="currentColor"/>
  </g>
</svg>

</template>

<script>
export default {
  name: 'MinusIcon',
};
</script>
