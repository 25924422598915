import axios from 'axios';

export const getAll = (params) => axios({
  url: '/users',
  method: 'GET',
  params,
});

export const getShortList = () => axios({
  url: '/users/short-list',
  method: 'GET',
});

export const getUserForVisitor = (login) => axios({
  url: `/users/visitor/?LOGIN=${login}`,
  method: 'GET',
});

export const create = (userFormData) => axios({
  url: '/users',
  method: 'POST',
  data: userFormData,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

export const update = (id, userFormData) => {
  // обход проблем с  Laravel
  userFormData.append('_method', 'PUT');
  return axios({
    url: `/users/${id}`,
    method: 'POST',
    data: userFormData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const remove = (id) => axios({
  url: `/users/${id}`,
  method: 'DELETE',
});

export const getObjects = (id) => axios({
  url: `/users/${id}/objects`,
  method: 'GET',
});

export const changeObjects = ({ id, object_id, is_bind }) => axios({
  url: `/users/${id}/bind-object`,
  method: 'PATCH',
  data: { object_id, is_bind },
});

export const visitor = () => axios({
  url: '/users/visitor',
  method: 'GET',
});

export const getUserRole = ({ id, is_bind }) => axios({
  url: `/users/${id}/get-role`,
  method: 'GET',
  params: { is_bind },
});

export const unbindUserRole = ({ id, role_id }) => axios({
  url: `/users/${id}/unbind-role`,
  method: 'PUT',
  data: { role_id },
});

export const bindUserRole = ({ id, role_id }) => axios({
  url: `/users/${id}/bind-role`,
  method: 'PUT',
  data: { role_id },
});

