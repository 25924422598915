<template>
  <div class="role-resolution__items">
    <role-resolution-item
      v-for="(item, index) in roleStructure"
      :key="index"
      :roleStructureItem="item"
      :parentElement="getParentElement(item)"
      :editing="editing"
      @requestRemoveConfirm="requestRemoveConfirm"
      @openObjectsToVisitDialog="openObjectsToVisitDialog"
    >
    </role-resolution-item>
  </div>
</template>

<script>
import { MEANING_RIGHTS } from "@/constants/index.js";
import RoleResolutionItem from "./RoleResolutionItem.vue";
export default {
  components: { RoleResolutionItem },
  name: "RoleResolutionList",
  props: {
    roleStructure: {
      type: Array,
      default: () => [],
    },
    parentElement: {
      type: [Object, null],
      default: null,
    },
    editing: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      MEANING_RIGHTS,
    };
  },
  methods: {
    openObjectsToVisitDialog(roleElement) {
      this.$emit("openObjectsToVisitDialog", roleElement);
    },
    getParentElement(item) {
      const structureItem = item.element_id ? item : null;
      return this.parentElement ?? structureItem;
    },
    requestRemoveConfirm(show, roleRemove) {
      this.$emit("requestRemoveConfirm", show, roleRemove);
    },
  },
};
</script>

<style lang="scss">
.role-resolution {
  &__items {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden auto;
  }
}
</style>
