<template>
  <div>
    <v-card class="add-roles-dialog">
      <button
        @click="clickReturn()"
        class="mb-3 d-flex align-center text-color-primary"
      >
        <v-icon class="mr-1 text-color-primary" size="20">$back</v-icon>
        {{ $t("Return to all roles") }}
      </button>
      <v-icon size="16" class="card__close-btn" @click="closeDialog()">
        $close
      </v-icon>
      <v-card-title class="card__title-wrapper">
        <div class="card__title-with-btn">
          <h3
            class="card__title"
            v-if="roleDealogTypeProps === roleDealogType.viewingTotalRoleRights"
          >
            {{ $t("Total rights for") }}
            {{ user.NAME }}
          </h3>
          <h3
            class="card__title"
            v-if="roleDealogTypeProps === roleDealogType.viewingRoleRights"
          >
            {{ $t("Rights for") }}
            {{ role.NAME }}
          </h3>
        </div>
      </v-card-title>
      <role-resolution-list :roleStructure="roleStructure" :editing="false">
      </role-resolution-list>
    </v-card>
  </div>
</template>

<script>
import RoleResolutionList from "@/components/tree/RoleResolutionList.vue";
import { addRolessHeaders, roleDealogType } from "./data.user";
export default {
  components: { RoleResolutionList },
  props: {
    roleStructure: {
      type: Array,
      required: true,
    },
    user: {
      type: Object,
      default: {},
    },
    roleDealogTypeProps: {
      type: Number,
      default: 3,
    },
    role: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      addRolessHeaders,
      selectedReaders: [],
      roleDealogType,
    };
  },
  methods: {
    addExternalReader() {
      const selectedReadersId = this.selectedReaders.map((reader) => reader.ID);
      this.$emit("addRoleUser", selectedReadersId);
      this.$emit("go-back");
      this.clearSelectedReaders();
    },
    clearSelectedReaders() {
      this.selectedReaders = [];
    },
    closeDialog() {
      this.clearSelectedReaders();
      this.$emit("close-dialog");
    },
    clickReturn() {
      this.clearSelectedReaders();
      this.$emit("go-back");
    },
  },
  computed: {
    translatedTableHeaders() {
      return this.addRolessHeaders.map((elem) => ({
        ...elem,
        text: this.$t(elem.text),
      }));
    },
  },
};
</script>

<style lang="scss">
.add-roles-dialog {
  .text-color-primary {
    color: $primary;
  }
}
</style>
