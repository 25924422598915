<script>
import api from "@/api";
import dateHelpers from '@/helpers/dateHelpers';
import FilterComponent from "@/components/blocks/FilterComponent.vue";
import { tableHeaders, tableHeaders2, filterModel } from "./data.reportEvent";
import { REPORT_TYPES } from "@/constants";
import { mapGetters } from 'vuex'
import { COMMON_SET_LOADING } from '@/store/types/mutation-types.js';
import AppDialogImg from "@/components/blocks/AppDialogImg.vue";
import { SNACK_ADD_MESSAGE } from '@/store/types/action-types';
import _ from 'lodash';
export default {
  name: "ReportEvent",
  components: { FilterComponent, AppDialogImg },

  data() {
    return {
      dateHelpers,
      tabsModel: "tab-1",
      REPORT_TYPES,
      tableItems: [],
      statisticData: [],
      applicationData: [],
      tableHeaders,
      tableHeaders2,
      amountRequest: 0,
      amountApply: 0,
      isFilterApplied: false,
      filterModel: filterModel,
      filterIsEmpty: true,
      showFilters: true,
      filterGates: "",
      filterObjects: "",
      isDialogImgShow:false,
      urlItemDialogImg: '',
      optionsTable: {
        itemsPerPage: 15,
      },
      objectToAdd: [],
      amountAutomatical: 0,
      amountEmergency: 0,
      amountGrz: 0,
      amountManual: 0,
      amountPass: 0,
      tableOptions: {
        page: 1, // Переменная для отслеживания текущей страницы
        itemsPerPage: 15, // Количество элементов на странице (можно изменить по необходимости)
      },
    };
  },
  computed: {
    ...mapGetters([
      'carsBrands',
      'getEmergencyServices',
    ]),
    filterModelForTab() {
      return this.tabsModel === "tab-1"
        ? [this.filterModel[2]]
        : this.filterModel;
    },
    translatedTableHeaders() {
      return this.tableHeaders.map((elem) => ({
        ...elem,
        text: this.$t(elem.text),
      }));
    },

    translatedTableHeaders2() {
      return this.tableHeaders2.map((elem) => ({
        ...elem,
        text: this.$t(elem.text),
      }));
    },
  },
  mounted() {
    api.referenceBooks.getEmergency().then((res) => {
      res.data.DATA.forEach((object) => {
        filterModel[this.getIndexFromFilter("COLOR_SCHEMA")]?.items.push({
          text: object.NAME,
          value: object.ID,
        });
      });
    });
    api.gates.getAll().then((res) => {
      res.data.DATA.forEach((object) => {
        if (object.STATUS === 1) {
          filterModel[this.getIndexFromFilter("GATE")]?.items.push({
            text: object.NAME,
            value: object.ID,
          });
        }
      });
    });
    api.devices.getAll().then((res) => {
      res.data.DATA.forEach((object) => {
        if (object.STATUS === 1) {
          filterModel[this.getIndexFromFilter("DEVICES")]?.items.push({
            text: object.NAME,
            value: object.ID,
          });
        }
      });
    });
    if (this.carsBrands.length) {
      this.addBrandToFilter()
    } else {
      this.$store.dispatch('getCars').then(() => this.addBrandToFilter());
    }

    if (this.getEmergencyServices.length) {
      this.addEmergencyServicesToFilter()
    } else {
      this.$store.dispatch('getEmergencyServices').then(() => this.addEmergencyServicesToFilter());
    }
  },
  methods: {
    applyFilter() {
      this.tableOptions.page = 1;
      this.$store.commit(COMMON_SET_LOADING);
      const filter = {
        gates: this.getValueFromFilter("GATE"),
        begin: dateHelpers.getFormattedDateCustom(this.getValueFromFilter("START_S"), 'DD-MM-YYYY HH:mm'),
        end: dateHelpers.getFormattedDateCustom(this.getValueFromFilter("START_PO"), 'DD-MM-YYYY HH:mm'),
        brand: this.getValueFromFilter('CURRENT_BRAND_ID'),
        model: this.getValueFromFilter('CURRENT_MODEL_ID'),
        emergency: this.getValueFromFilter('EMERGENCY_SERVICES'),
        open_type: this.getValueFromFilter('OPEN_TYPE'),
        is_entry: this.getValueFromFilter('IS_ENTRY'),
        is_passed: this.getValueFromFilter('IS_PASSED'),
        is_request: this.getValueFromFilter('IS_REQUEST'),
        identifier: this.getValueFromFilter('IDENTIFIER'),
      };
      api.reports
        .getReport(filter, REPORT_TYPES.recognitionsReportId)
        .then((res) => {
          const resData = res.data.DATA[0].DATA
          this.statisticData = [];
          this.applicationData = [];
          this.amountAutomatical = 0;
          this.amountEmergency = 0;
          this.amountGrz = 0;
          this.amountManual = 0;
          this.amountPass = 0;
          resData.total.forEach((object) => {
            this.amountAutomatical += object.all_automatical;
            this.amountEmergency += object.all_emergency;
            this.amountGrz += object.all_grz;
            this.amountManual += object.all_manual;
            this.amountPass += object.all_pass;
            this.statisticData.push({
              GATE: object.gate,
              DEVICE: object.device,
              AMOUNT_AUTOMATICAL: object.all_automatical,
              AMOUNT_EMERGENCY: object.all_emergency,
              AMOUNT_GRZ: object.all_grz,
              AMOUNT_MANUAL: object.all_manual,
              AMOUNT_PASS: object.all_pass,
            });
          });
          resData.read.forEach((object) => {
            this.applicationData.push({
              GATE: object.gate,
              CODE : object.code,
              DATE: object.date,
              BRAND : object.brand,
              MODEL : object.model,
              EMERGENCY: object.emergency,
              OPEN_TYPE : object.open_type,
              ENTRY: object.entry,
              PHOTO: { link: object.photo },
              PASSED: object.passed,
              STATE : object.state,
            });
          });
          this.objectToAdd=[
            {
              GATE: this.$t('Total'),
              AMOUNT_AUTOMATICAL: this.amountAutomatical,
              AMOUNT_EMERGENCY: this.amountEmergency,
              AMOUNT_GRZ: this.amountGrz,
              AMOUNT_MANUAL: this.amountManual,
              AMOUNT_PASS: this.amountPass,
            },
            {
              GATE: this.$t('Number of cars in the territory at the time of the report formation'),
              AMOUNT_PASS: resData.cars,
            },
          ],
          this.untouchableStatisticData = _.cloneDeep(this.statisticData);
          this.statisticData = this.insertObjectEveryNElements(this.untouchableStatisticData, this.objectToAdd, this.optionsTable.itemsPerPage - this.objectToAdd.length)
          this.isFilterApplied = true;
        })
        .catch((error) => {
          this.$store.dispatch(SNACK_ADD_MESSAGE, error.response.data.MESSAGE);
        }).finally( () => this.$store.commit(COMMON_SET_LOADING, false));
    },
    changeFilterElement(element){
      if (element.name === 'CURRENT_BRAND_ID') {
        this.selectedBrandElement = element;
        this.addModelToFilter();
          filterModel[this.getIndexFromFilter('CURRENT_MODEL_ID')].data = null
      }
    },
    addBrandToFilter() {
      this.carsBrands.forEach((system) => {
        filterModel[this.getIndexFromFilter('CURRENT_BRAND_ID')]?.items.push({
          text: system.text,
          value: system.value,
          translate: system.translate,
        });
      });
    },
    carsModels() {
      if (!this.selectedBrandElement.data) {
        filterModel[this.getIndexFromFilter('CURRENT_MODEL_ID')].disabled = true;
        return []
      }

      filterModel[this.getIndexFromFilter('CURRENT_MODEL_ID')].disabled = false;
      return this.$store.getters.carsGroupedByBrand[this.selectedBrandElement.data].map(car => ({
        text: car.NAME,
        value: car.ID,
        translate: car.MULTI_DATA,
      }));
    },
    addModelToFilter() {
      filterModel[this.getIndexFromFilter('CURRENT_MODEL_ID')].items = this.carsModels(this.selectedBrandElement);
    },
    addEmergencyServicesToFilter() {
      this.getEmergencyServices.forEach((system) => {
        filterModel[this.getIndexFromFilter('EMERGENCY_SERVICES')]?.items.push({
          text: system.NAME,
          value: system.ID,
        });
      });
    },
    getValueFromFilter(field) {
      return (this.dataFilters || this.filterModel)?.find(
        (item) => item.name === field
      ).data;
    },
    getIndexFromFilter(field) {
      return this.filterModel.findIndex((item) => item.name === field);
    },
    downloadExcel() {
      this.$store.commit(COMMON_SET_LOADING);
      const filter = {
        gates: this.getValueFromFilter("GATE"),
        begin: dateHelpers.getFormattedDateCustom(this.getValueFromFilter("START_S"), 'DD-MM-YYYY HH:mm'),
        end: dateHelpers.getFormattedDateCustom(this.getValueFromFilter("START_PO"), 'DD-MM-YYYY HH:mm'),
        brand: this.getValueFromFilter('CURRENT_BRAND_ID'),
        model: this.getValueFromFilter('CURRENT_MODEL_ID'),
        emergency: this.getValueFromFilter('EMERGENCY_SERVICES'),
        open_type: this.getValueFromFilter('OPEN_TYPE'),
        is_entry: this.getValueFromFilter('IS_ENTRY'),
        is_passed: this.getValueFromFilter('IS_PASSED'),
        is_request: this.getValueFromFilter('IS_REQUEST'),
        identifier: this.getValueFromFilter('IDENTIFIER'),
      };
      api.reports
        .getExcel(filter, REPORT_TYPES.recognitionsReportId)
        .then((res) => {
          const outputFilename = `${this.$t("Report recognitions")}.xls`;
          // If you want to download file automatically using link attribute.
          const url = URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", outputFilename);
          document.body.appendChild(link);
          link.click();
          link.remove();
        }).finally(() => this.$store.commit(COMMON_SET_LOADING, false));
    },
    showImgDialog(item) {
      this.urlItemDialogImg = item;
      this.isDialogImgShow = true;
    },
    receiveOptionsTable(options){
      if (this.optionsTable.itemsPerPage === options.itemsPerPage) {
        return
      }
      this.optionsTable = options;
      this.statisticData = this.insertObjectEveryNElements(this.untouchableStatisticData, this.objectToAdd, this.optionsTable.itemsPerPage - this.objectToAdd.length);
    },
    insertObjectEveryNElements(array, objects, interval) {
      const newArray = _.cloneDeep(array);
      if (!Array.isArray(objects)) {
        objects = [objects]; // Преобразуем в массив, если это не массив
    }
      for (let i = interval; i < newArray.length; i += interval + objects.length) {
          newArray.splice(i, 0, ...objects);
      }
      newArray.push(...objects);
      return newArray;
    },
  },
};
</script>
<template>
  <div class="report-event scrolling-tables-static scrolling-tables-static_mini">
    <v-row class="mb-5">
      <v-col cols="auto" align-self="center">
        <router-link to="main" class="d-flex align-center">
          <v-icon class="mr-1" size="20">$back</v-icon>
          {{ $t("To all reports") }}
        </router-link>
      </v-col>
    </v-row>
    <v-tabs
      v-model="tabsModel"
      class="srp-tabs d-flex flex-column"
    >
      <v-row class="mb-5">
        <v-col cols="auto" align-self="center">
          <h2 class="text-h2 text--primary">{{ $t("Event Report") }}</h2>
        </v-col>
        <v-tab v-if="isFilterApplied" :href="`#tab-1`">{{
          $t("Statistics")
        }}</v-tab>
        <v-tab v-if="isFilterApplied" :href="`#tab-2`">{{
          $t("List of detections")
        }}</v-tab>
        <v-spacer />
        <v-col cols="auto" class="d-flex flex-grow-1 justify-end">
          <v-btn depressed @click="downloadExcel" class="ml-4 btn__export">
            <v-icon class="mr-2" size="20">$download</v-icon>
            {{ $t("Export") }}
          </v-btn>
          <v-btn
            depressed
            class="ml-4"
            color="primary"
            :outlined="filterIsEmpty"
            width="150"
            @click="showFilters = !showFilters"
          >
            <template v-if="!showFilters">
              <v-icon class="mr-2" size="20">$filter</v-icon>
              {{ $t("Filters") }}
            </template>
            <template v-else>
              <v-icon class="mr-2" size="20">$up</v-icon>
              {{ $t("Collapse") }}
            </template>
          </v-btn>
        </v-col>
      </v-row>
      <v-tab-item class="pt-8" :value="`tab-1`">
        <filter-component
          v-model="filterModel"
          :show="showFilters"
          :data="filterModel"
          @getDataFilters="applyFilter"
          @filterIsEmpty="filterIsEmpty = $event"
          @changeFilterElement="changeFilterElement"
          ref="refFilterComponentOne"
        />
        <v-data-table
          v-if="isFilterApplied"
          :headers="translatedTableHeaders"
          :items="statisticData"
          sort-by="NAME"
          :sort-desc="true"
          :items-per-page="-1"
          :header-props="{ 'sort-icon': '$swap' }"
          :loading-text="`${$t('Loading')}...`"
          class="table-striped"
          :no-data-text="$t('Data not found')"
          :no-results-text="$t('Data not found')"
          :options.sync="tableOptions"
          :footer-props="{
            'items-per-page-text': $t('Lines per page'),
            'items-per-page-options': [15, 25, 50, 100],
          }"
           @update:options="receiveOptionsTable"
           ref="refTableObjectsOne"
        >
          <template #[`item.GATE`]="{ item }">
            <div class="report-event__footer-item_total">
              {{ item.GATE }}
            </div>
          </template>
          <template #[`item.AMOUNT_AUTOMATICAL`]="{ item }">
            <div class="report-event__footer-item">
              {{ item.AMOUNT_AUTOMATICAL }}
            </div>
          </template>
          <template #[`item.AMOUNT_EMERGENCY`]="{ item }">
            <div class="report-event__footer-item">
              {{ item.AMOUNT_EMERGENCY }}
            </div>
          </template>
          <template #[`item.AMOUNT_GRZ`]="{ item }">
            <div class="report-event__footer-item">
              {{ item.AMOUNT_GRZ }}
            </div>
          </template>
          <template #[`item.AMOUNT_MANUAL`]="{ item }">
            <div class="report-event__footer-item">
              {{ item.AMOUNT_MANUAL }}
            </div>
          </template>
          <template #[`item.AMOUNT_PASS`]="{ item }">
            <div class="report-event__footer-item">
              {{ item.AMOUNT_PASS }}
            </div>
          </template>
          <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
            {{ pageStart }} - {{ pageStop }} {{  $t('of')  }} {{ itemsLength }}
          </template>
        </v-data-table>
      </v-tab-item>
      <v-tab-item class="pt-8" :value="`tab-2`">
        <filter-component
          v-model="filterModel"
          :show="showFilters"
          :data="filterModel"
          @getDataFilters="applyFilter"
          @filterIsEmpty="filterIsEmpty = $event"
          ref="refFilterComponentSecond"
        />
        <v-data-table
          v-if="isFilterApplied"
          :headers="translatedTableHeaders2"
          :items="applicationData"
          sort-by="NAME"
          :sort-desc="true"
          :items-per-page="-1"
          :header-props="{ 'sort-icon': '$swap' }"
          :loading-text="`${$t('Loading')}...`"
          class="table-striped"
          :no-data-text="$t('Data not found')"
          :no-results-text="$t('Data not found')"
          :options.sync="tableOptions"
          :footer-props="{
            'items-per-page-text': $t('Lines per page'),
            'items-per-page-options': [15, 25, 50, 100],
          }"
           @update:options="receiveOptionsTable"
           ref="refTableObjectsSecond"
        >
          <template #[`item.DATE`]="{ item }">
            {{ dateHelpers.getFormattedDateCustom(item.DATE, 'DD.MM.YYYY HH:mm:ss') }}
          </template>
          <template #[`item.ACTIONS`]="{ item }">
            <div class="d-inline-flex">
              <div
                @click="showImgDialog(item.PHOTO.link)"
                v-if="item.PHOTO.link"
                class="d-block"
              >
                <v-tooltip bottom color="primary">
                  <template #activator="{ on, attrs }">
                    <v-icon
                      class="mr-4"
                      v-bind="attrs"
                      color="primary"
                      size="24"
                      v-on="on"
                    >
                      $pictureIcon
                    </v-icon>
                  </template>
                  {{ $t("Car photo") }}
                </v-tooltip>
                </div>
            </div>
          </template>
          <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
            {{ pageStart }} - {{ pageStop }} {{  $t('of')  }} {{ itemsLength }}
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs>
    <AppDialogImg
      :is-dialog-img-show="isDialogImgShow"
      @close-dialog="isDialogImgShow = false"
      :img-url="urlItemDialogImg"/>
  </div>
</template>
