import store from "@/store";

export const tableHeaders = [
  {
    text: 'ID',
    value: 'ID',
    filterable: false,
    sortable: true,
    width: '80px',
  },
  {
    text: 'Name',
    value: 'NAME',
    cellClass: 'font-weight-medium',
    sortable: true,
    width: '200px'
  },
  {
    text: 'Status',
    value: 'STATUS',
    filterable: false,
    sortable: true,
    width: '150px',
  },
  {
    text: 'Symbolic identifier',
    value: 'SID',
    filterable: true,
    sortable: false,
    width: '150px',
  },
  {
    text: 'Parameter set',
    value: 'PARAMETERS',
    filterable: false,
    sortable: false,
    width: '400px',
  },
  {
    text: 'Reader type',
    value: 'TYPE',
    filterable: false,
    sortable: false,
    width: '200px',
  },
  {
    text: 'Reader address',
    value: 'ADDRESS',
    filterable: false,
    sortable: false,
    width: '150px',
  },
  {
    text: 'Direction',
    value: 'IS_ENTRY',
    filterable: false,
    sortable: false,
  },
  {
    text: 'Device',
    value: 'DEVICE_NAME',
    filterable: false,
    sortable: false,
    width: '150px',
  },
  {
    text: 'Actions',
    value: 'ACTIONS',
    align: 'end',
    sortable: false,
    class: 'fixed-right-cel',
    cellClass: 'fixed-right-cel',
  }
];

export function getFilterModels() {
  return [
    {
      name: 'NAME',
      type: 'input-field',
      data: '',
      label: 'Name',
    },
    {
      name: 'STATUS',
      type: 'select',
      data: null,
      items: store.getters?.getEnums['ENUM.ReaderExternal.STATUS']?.map(status => ({ text: status.NAME, value: status.NUMBER })) ?? [],
      label: 'Status',
    },
    {
      name: 'SID',
      type: 'input-field',
      data: '',
      label: 'Symbolic identifier',
    },
    {
      name: 'IS_ENTRY',
      type: 'select',
      data: null,
      items: [{ text: 'Entry', value: 1 }, { text: 'Exit', value: 0 }],
      label: 'Direction',
    },
    {
      name: 'DEVICE',
      type: 'select',
      data: null,
      items: [],
      label: 'Device',
    },
  ];
}
