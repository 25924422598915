<template>
  <v-dialog max-width="520" :value="isShow" @click:outside="$emit('close-dialog')">
    <v-card>
      <v-icon size="16" class="card__close-btn" @click="$emit('close-dialog')">
        $close
      </v-icon>
      <v-card-title class="card__title-wrapper">
        <h3 class="card__title">
          {{ $t("Select readers") }}
        </h3>
      </v-card-title>

      <v-text-field
        v-model="searchReaders"
        class="mb-5"
        append-icon="mdi-magnify"
        :label="$t('Name')"
        outlined
        dense
        hide-details
      >
        <template #prepend-inner>
          <v-icon class="pt-1 mr-1 grey--text" size="20">
            $search
          </v-icon>
        </template>
      </v-text-field>
      <v-data-table
        v-model="selectedReaders"
        class="table-striped mb-5"
        :headers="translatedTableHeaders"
        :items="tableItems"
        :search="searchReaders"
        item-key="READER_ID"
        show-select
        checkbox-color="primary"
        :no-data-text="$t('Data not found')"
        :no-results-text="$t('Data not found')"
        :footer-props="{
          'items-per-page-text': $t('Lines per page'),
          'items-per-page-options': [5, 10],
        }"
      >
        <template #[`item.IS_ENTRY`]="{ item }">
          {{ item.IS_ENTRY ? $t('Entry') : $t('Exit') }}
        </template>
        <template #[`item.ACTIONS`]="{ item }">
          <div class="d-inline-flex">
            <v-icon color="red" @click="removeExternalReader(item)">
              $delete
            </v-icon>
          </div>
        </template>
        <template #[`header.data-table-select`] />
        <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
          {{ pageStart }} - {{ pageStop }} {{  $t('of')  }} {{ itemsLength }}
        </template>
      </v-data-table>

      <v-btn
        depressed
        block
        color="primary"
        @click="addExternalReaders"
        :disabled="!selectedReaders.length"
      >
        {{ $t("Add") }}
      </v-btn>
    </v-card>
  </v-dialog>
</template>

<script>
import api from '@/api';

export default {
  props: {
    isShow: {
      type: Boolean,
      required: true
    },
    tableHeaders: {
      type: Array,
      required: true
    },
    tableItems: {
      type: Array,
      required: true
    },
    deviceId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      externalReaders: this.tableItems,
      selectedReaders: [],
      searchReaders: ''
    }
  },
  computed: {
    translatedTableHeaders() {
      return this.tableHeaders.map((elem) => ({ ...elem, text: this.$t(elem.text) }));
    },
  },
  methods: {
    async removeExternalReader(externalReader) {
      await api.devices.removeExternalReaders(this.deviceId, externalReader.READER_ID);
      const currentReaderIndex = this.externalReaders.findIndex(reader => reader.ID === externalReader.READER_ID);
      this.externalReaders.splice(currentReaderIndex, 1);
    },
    async addExternalReaders() {
      const selectedReadersId = this.selectedReaders.map(reader => reader.READER_ID);
      await api.devices.addExternalReaders(this.deviceId, selectedReadersId);
      this.$emit('close-dialog');
    }
  },
  watch: {
    tableItems() {
      this.externalReaders = this.tableItems;
    }
  }
}
</script>
