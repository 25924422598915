import axios from 'axios';

export const getAll = (params) => axios({
  url: '/role',
  method: 'GET',
  params
});

export const add = (data) => axios({
  url: '/role',
  method: 'POST',
  data
});
export const edit = (data) => axios({
  url: `/role/${data.id}`,
  method: 'PUT',
  data
});
export const changeStatus = (id, status) => axios({
  url: `/role/${id}/status`,
  method: 'PUT',
  data: {
    id,
    status
  }
});


export const remove = (id) => axios({
  url: `/role/${id}`,
  method: 'DELETE',
  data: {
    id,
  }
});

export const functionalRoleGetPermission = (id) => axios({
  url: `/role/${id}/permission`,
  method: 'GET',
  data: {
    id
  }
});

export const functionalRoleChangePermission = (
  id,
  object
) => axios({
  url: `/role/${id}/permission`,
  method: 'PUT',
  data: {
    id,
    permission: object
  }
});

export const objectRoleGetElements = (id) => axios({
  url: `/role/${id}/objects`,
  method: 'GET',
  data: {
    id
  }
});

export const objectRoleChangeElements = (id, objectsData) => axios({
  url: `/role/${id}/objects`,
  method: 'PUT',
  data: {
    id,
    objects_data: objectsData
  }
});

export const getRolePermission = (id) => axios({
  url: `/role/${id}/role-permission`,
  method: 'GET',
});

export const putRoleStructure = (id, data) => axios({
  url: `/role/${id}/role-permission`,
  method: 'PUT',
  data: { permissions_data: JSON.stringify(data) }
});

export const getUserPermission = (user_id) => axios({
  url: `/role/user-permission`,
  method: 'GET',
  params: { user_id }
});
