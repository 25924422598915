import { ROLE_STATUSES } from '@/constants';
import { MEANING_RIGHTS } from "@/constants/index.js";

export const tableHeaders = [
  {
    text: 'ID',
    value: 'ID',
    filterable: false,
    sortable: true,
    width: '80px',
  },
  {
    text: 'Name',
    value: 'NAME',
    cellClass: 'font-weight-medium',
    sortable: true,
    width: '200px'
  },
  {
    text: 'Status',
    value: 'STATUS',
    sortable: true,
    width: '150px',
  },
  {
    text: 'Description',
    value: 'DESCRIPTION',
    sortable: false,
  },
  {
    text: 'Actions',
    value: 'ACTIONS',
    align: 'end',
    filterable: false,
    sortable: false,
  },
];

export const filterModel = [
  {
    name: 'NAME',
    type: 'input-field',
    data: '',
    label: 'Name',
  },
  {
    name: 'STATUS',
    type: 'select',
    data: null,
    items: ROLE_STATUSES,
    label: 'Status',
  },
];

export const permissionList = [
  {
    // Таблица «Отображение разделов интерфейса»
    web_admin: {
      name: 'Administration',
      value: false
    },
    web_objects: {
      name: 'Objects to visit',
      value: false
    },
    web_requests: {
      name: 'Pass',
      value: false
    },
    web_pass: {
      name: 'Passages',
      value: false
    }
  },
  {
    // Таблица «Работа с КПП»
    gate_view: {
      name: 'Viewing',
      value: false
    },
    gate_add: {
      name: 'Creation',
      value: false
    },
    gate_del: {
      name: 'Removal',
      value: false
    },
    gate_block: {
      name: 'Blocking',
      value: false
    },
  },
  {
    // Таблица «Работа с зонами доступа»
    zone_view: {
      name: 'Viewing',
      value: false
    },
    zone_add: {
      name: 'Creation',
      value: false
    },
    zone_del: {
      name: 'Removal',
      value: false
    },
    zone_block: {
      name: 'Blocking',
      value: false
    },
  },
  {
    // Таблица «Работа с объектами посещения»
    object_view: {
      name: 'Viewing',
      value: false
    },
    object_add: {
      name: 'Creation',
      value: false
    },
    object_del: {
      name: 'Removal',
      value: false
    },
    object_block: {
      name: 'Blocking',
      value: false
    },
  },
  {
    // Таблица «Изменение статуса заявок»
    request_new_to_reviewed: {
      name: 'New > Reviewed',
      value: false
    },
    request_reviewed_to_agreed: {
      name: 'Reviewed > Agreed',
      value: false
    },
    request_agreed_to_approved: {
      name: 'Agreed > Approved',
      value: false
    },
    request_approved_to_agreed: {
      name: 'Approved > Agreed',
      value: false
    },
    request_agreed_to_reviewed: {
      name: 'Agreed > Reviewed',
      value: false
    },
    request_reviewed_to_new: {
      name: 'Reviewed > New',
      value: false
    },
  },
  {
    // Таблица «Работа с заявками»
    request_view: {
      name: 'Viewing',
      value: false
    },
    request_add: {
      name: 'Creation',
      value: false
    },
    request_del: {
      name: 'Removal',
      value: false
    },
    request_dismiss: {
      name: 'Dismiss',
      value: false
    },
    request_load: {
      name: 'Load',
      value: false
    }
  },
  {
    // Таблица «Внешний пользователь»
    web_security: {
      name: 'Security workstation',
      value: false
    },
    access_api: {
      name: 'API acces',
      value: false
    }
  },
  {
    //Тип пользователя
    type_local: {
      name: 'Local user',
      value: true
    },
    type_load_object: {
      name: 'Loading objects and passes',
      value: false
    },
    type_external_skd: {
      name: 'External SKD',
      value: false
    }
  }
]

export const objectTableHeaders = [
  {
    text: 'Object',
    value: 'OBJECT_NAME',
    filterable: false,
    sortable: false,
    width: '250px',
    class: 'font-weight-regular',
  },
  {
    text: `Radios`,
    value: 'RADIOS',
    sortable: false,
    width: '80px',
    align: 'end',
    class: 'px-0 object-roles__small-header',
    cellClass: 'px-0'
  },
]

export const buttonsRole = [
  {
    label: "Yes",
    value: MEANING_RIGHTS.allowed,
    class: "role-resolution__button",
    activeClass: "role-resolution__button_green",
  },
  {
    label: "No",
    value: MEANING_RIGHTS.forbidden,
    class: "role-resolution__button",
    activeClass: "role-resolution__button_red",
  },
  {
    label: "Not installed",
    value: MEANING_RIGHTS.notSpecified,
    class: "role-resolution__button",
    activeClass: "role-resolution__button_gray",
  },
]
