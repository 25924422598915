var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.roleStructureItem.element_id !== -1)?_c('div',{staticClass:"role-resolution__item role-resolution-item"},[_c('div',{staticClass:"role-resolution__box"},[(_vm.roleStructureItem.child.length)?_c('v-icon',{staticClass:"role-resolution-item__item-icon mr-3",class:{
        'role-resolution-item__item-icon-rotate': _vm.showList,
      },attrs:{"size":"24"},on:{"click":function($event){_vm.showList = !_vm.showList}}},[_vm._v(" $roundArrow ")]):_c('div',{staticClass:"role-resolution__item-circle mr-3"}),_c('div',{staticClass:"role-resolution__item-content"},[_c('div',{staticClass:"role-resolution__item-name",class:{
          'role-resolution__item-name_click': _vm.roleStructureItem.child.length,
        },on:{"click":function($event){return _vm.toggleShowList()}}},[_vm._v(" "+_vm._s(_vm.roleStructureItem.title)+" "),(_vm.roleStructureItem.description)?_c('v-tooltip',{attrs:{"bottom":"","color":"white","content-class":"role-resolution__item-tooltip-shadow"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$emit('showZones', _vm.item)}}},'v-icon',attrs,false),on),[_vm._v(" $informationIcon ")])]}}],null,false,231740720)},[_vm._v(" "+_vm._s(_vm.roleStructureItem.description)+" ")]):_vm._e()],1),_c('div',{staticClass:"role-resolution__item-buttons"},[(_vm.roleStructureItem.is_element && _vm.editing)?_c('div',[_c('v-btn',{attrs:{"outlined":"","color":"primary","height":"24px"},on:{"click":function($event){return _vm.$emit('openObjectsToVisitDialog', _vm.roleStructureItem)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary","size":"12"}},[_vm._v(" $plus ")]),_vm._v(" "+_vm._s(_vm.$t("Add an object"))+" ")],1)],1):_vm._e(),(_vm.showDelete)?_c('v-icon',{attrs:{"color":"red"},on:{"click":function($event){return _vm.deleteRoleStructureItem(_vm.roleStructureItem)}}},[_vm._v(" $delete ")]):_vm._e(),(_vm.roleStructureItem.is_value)?[_c('div',{staticClass:"role-resolution__hr-vertically"}),_c('div',{staticClass:"role-resolution__buttons"},[_vm._l((_vm.buttonsRole),function(button,index){return [(_vm.editing || _vm.roleStructureItem.value === button.value)?_c('button',{key:index,class:[
                  'role-resolution__button',
                  button.class,
                  {
                    [button.activeClass]:
                      _vm.roleStructureItem.value === button.value,
                  },
                ],on:{"click":function($event){return _vm.toggleRole(button.value)}}},[_vm._v(" "+_vm._s(_vm.$t(button.label))+" ")]):_vm._e()]})],2)]:_vm._e()],2)])],1),(_vm.showChild(_vm.roleStructureItem.child))?_c('div',{staticClass:"role-resolution__item-child"},[_c('role-resolution-list',{attrs:{"roleStructure":_vm.roleStructureItem.child,"parentElement":_vm.getParentElement(),"editing":_vm.editing},on:{"openObjectsToVisitDialog":_vm.openObjectsToVisitDialog,"requestRemoveConfirm":_vm.requestRemoveConfirm}})],1):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }