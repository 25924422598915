<script>
import { RouterView } from 'vue-router';
import isValueStraightTreeMixin from '@/mixins/isValueStraightTreeMixin.js';
import { tableHeaders } from './data.reports.js';

export default {
  name: 'ReportsMain',
  components: { RouterView },
  mixins: [isValueStraightTreeMixin],
  data() {
    return {
      tableHeaders,
      tableItems: [
        {
          NAME: this.$t('Report passes'),
          link: 'passes',
          key: 'reports_report_2',
        },
        {
          NAME: this.$t('Event Report'),
          link: 'event',
          key: 'reports_report_1',
        },
      ],
      tableLoading: false,
    };
  },
  computed: {
    translatedTableHeaders() {
      return this.tableHeaders.map((elem) => ({
        ...elem,
        text: this.$t(elem.text),
      }));
    },
    filteredTableItems() {
      return this.tableItems.filter((item) => this.isValueStraightTreeMixin(item.key));
    },
  },
};
</script>
<template>
  <div>
    <v-row class="mb-5">
      <v-col
        cols="auto"
        align-self="center"
      >
        <h2 class="text-h2">{{ $t("Reports") }}</h2>
      </v-col>
      <v-spacer />
      <v-col
        cols="auto"
        class="d-flex flex-grow-1 justify-end"
      />
    </v-row>
    <v-data-table
      :headers="translatedTableHeaders"
      :items="filteredTableItems"
      sort-by="NAME"
      :sort-desc="true"
      :items-per-page="-1"
      :loading="tableLoading"
      :header-props="{ 'sort-icon': '$swap' }"
      :loading-text="`${$t('Loading')}...`"
      class="table-striped"
      :no-data-text="$t('Data not found')"
      :no-results-text="$t('Data not found')"
      :footer-props="{
        'items-per-page-text': $t('Lines per page'),
        'items-per-page-options': [15, 25, 50, 100],
      }"
    >
      <template #[`item.NAME`]="{ item }">
        <a :href="item.link">
          {{ item.NAME }}
        </a>
      </template>
      <template #footer.page-text="{ pageStart, pageStop, itemsLength }">
        {{ pageStart }} - {{ pageStop }} {{ $t("of") }} {{ itemsLength }}
      </template>
    </v-data-table>
  </div>
</template>
<style></style>
