<template>
  <v-dialog max-width="450" :value="isDialogActive" @click:outside="$emit('close-dialog')">
    <v-card>
      <v-icon size="16" class="card__close-btn" @click="$emit('close-dialog')">
        $close
      </v-icon>
      <v-card-title class="card__title-wrapper">
        <h3 class="card__title">
          {{ isNewRoleForm ? $t("Adding a role") : $t("Editing a role") }}
        </h3>
      </v-card-title>

      <v-card-text style="max-width: 370px; margin: 0 auto" class="text-center pb-16">
        <v-form ref="roleForm" v-model="formValid" @keyup.native.enter="formSubmit">
          <v-text-field
            v-model="roleForm.NAME"
            :rules="userFormRules.NAME"
            hide-details="auto"
            class="mb-5"
            outlined
          >
            <template #label>
              {{ $t("Name") }}<span class="red--text">*</span>
            </template>
          </v-text-field>

          <v-select
            v-model="roleForm.STATUS"
            :items="translateUserStatuses"
            :rules="userFormRules.STATUS"
            :menu-props="{ offsetY: true }"
            class="mb-5"
            append-icon="$down"
            hide-details="auto"
            outlined
          >
            <template #label>
              {{ $t("Status") }}<span class="red--text">*</span>
            </template>
          </v-select>

          <v-textarea
            v-model="roleForm.DESCRIPTION"
            :label="$t('Description')"
            class="mb-5"
            rows="4"
            row-height="30"
            no-resize
            hide-details="auto"
            outlined
          />

          <v-btn
            depressed
            block
            color="primary"
            :class="{'btn_disabled': !formValid}"
            @click="formSubmit"
          >
          {{ isNewRoleForm ? $t("Add") : $t("Save") }}
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { ROLE_STATUSES } from '@/constants';

export default {
  name: 'FunctionalRolesDialog',
  props: {
    isDialogActive: {
      type: Boolean,
      default: false,
    },
    isNewRoleForm: {
      type: Boolean,
      default: true
    },
    roleFormProp: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      ROLE_STATUSES,

      roleForm: {
        ID: null,
        NAME: '',
        STATUS: null,
        DESCRIPTION: ''
      },

      userFormRules: {
        NAME: [(v) => !!v || `${this.$t('You need to fill in «Name»')}.`],
        STATUS: [(v) => !!v || `${this.$t('You need to fill in «Status»')}.`]
      },

      formValid: false,
    }
  },
  computed: {
    translateUserStatuses() {
      return ROLE_STATUSES.map((elem) => ({ ...elem, text: this.$t(elem.text) }));
    },
  },
  watch: {
    roleFormProp() {
      this.roleForm.ID = this.roleFormProp.ID;
      this.roleForm.NAME = this.roleFormProp.NAME;
      this.roleForm.STATUS = this.roleFormProp.STATUS;
      this.roleForm.DESCRIPTION = this.roleFormProp.DESCRIPTION;
    },
    isDialogActive() {
      if (this.isDialogActive && this.$refs.roleForm) {
        this.$refs.roleForm.resetValidation();
      }
    }
  },
  methods: {
    formSubmit() {
      this.$emit('form-submit', this.roleForm);
    },
  }
}
</script>
