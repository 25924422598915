<template>
  <v-dialog max-width="450" :value="isShow" @click:outside="$emit('close-dialog')" @keydown="keydownHandler">
    <v-card>
      <v-icon size="16" class="card__close-btn" @click="$emit('close-dialog')">
        $close
      </v-icon>
      <v-card-title class="card__title-wrapper">
        <h3 class="card__title">
          {{ isNewServerForm ? $t("Adding a server") : $t("Editing a server") }}
        </h3>
      </v-card-title>

      <v-card-text style="max-width: 370px; margin: 0 auto" class="text-center pb-16">
        <v-form ref="serverForm" v-model="formValid" @keyup.native.enter="formSubmit">
          <v-text-field
            v-model="serverForm.NAME"
            :rules="serverFormRules.NAME"
            hide-details="auto"
            class="mb-5"
            outlined
          >
            <template #label>
              {{ $t("Name") }}<span class="red--text">*</span>
            </template>
          </v-text-field>

          <v-select
            v-model="serverForm.STATUS"
            :items="translatedStatuses"
            :rules="serverFormRules.STATUS"
            :menu-props="{ offsetY: true }"
            class="mb-5"
            append-icon="$down"
            hide-details="auto"
            outlined
          >
            <template #label>
              {{ $t("Status") }}<span class="red--text">*</span>
            </template>
          </v-select>

          <v-text-field
            v-model="serverForm.LOAD_ADDRESS"
            :rules="serverFormRules.LOAD_ADDRESS"
            hide-details="auto"
            class="mb-5"
            outlined
          >
            <template #label>
              {{ $t("Load address") }}<span class="red--text">*</span>
            </template>
          </v-text-field>

          <v-text-field
            v-model="serverForm.NATS_ADDRESS"
            :rules="serverFormRules.NATS_ADDRESS"
            hide-details="auto"
            class="mb-5"
            outlined
          >
            <template #label>
              {{ $t("Nats address") }}<span class="red--text">*</span>
            </template>
          </v-text-field>

          <v-text-field
            v-model="serverForm.SID"
            :rules="serverFormRules.SID"
            hide-details="auto"
            class="mb-5"
            outlined
          >
            <template #label>
              {{ $t("Symbolic identifier") }}<span class="red--text">*</span>
            </template>
          </v-text-field>

          <v-textarea
            v-model="serverForm.NATS_CONFIGURATION"
            :label="$t('Nats configuration')"
            class="mb-5"
            rows="4"
            row-height="30"
            no-resize
            hide-details="auto"
            outlined
          />

          <div class="mb-1 text-left">
            {{ $t('Central server') }}
          </div>
          <v-radio-group
            v-model="serverForm.IS_MASTER"
            hide-details="auto"
            class="mb-5 mt-0"
            row
          >
            <v-radio
              :value="true"
              class="mr-15"
            >
              <template #label>
                <span class="text--primary">Да</span>
              </template>
            </v-radio>

            <v-radio
              :value="false"
            >
              <template #label>
                <span class="text--primary">Нет</span>
              </template>
            </v-radio>
          </v-radio-group>

          <v-textarea
            v-model="serverForm.DESCRIPTION"
            :label="$t('Description')"
            class="mb-5"
            rows="4"
            row-height="30"
            no-resize
            hide-details="auto"
            outlined
          />

          <v-btn
            depressed
            block
            color="primary"
            :class="{'btn_disabled': !formValid}"
            @click="formSubmit"
          >
            {{ isNewServerForm ? $t("Add") : $t("Save") }}
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import api from '@/api';
import { SNACK_ADD_MESSAGE } from '@/store/types/action-types';

export default {
  props: {
    isShow: {
      type: Boolean,
      required: true
    },
    isNewServerForm: {
      type: Boolean,
      required: true
    },
    serverFormProps: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      serverForm: {},

      serverFormRules: {
        NAME: [(v) => !!v || `${this.$t('You need to fill in «Name»')}.`],
        STATUS: [(v) => !!v || `${this.$t('You need to fill in «Status»')}.`],
        LOAD_ADDRESS: [(v) => !!v || `${this.$t('You need to fill in «Load address»')}.`],
        NATS_ADDRESS: [(v) => !!v || `${this.$t('You need to fill in «NATS address»')}.`],
        SID: [(v) => !!v || `${this.$t('You need to fill in «Symbolic identifier»')}.`],
      },
      formValid: false,
    }
  },
  computed: {
    translatedStatuses() {
      if (!this.$store.getters?.getEnums['ENUM.Server.STATUS']?.length) {
        return [];
      }

      return this.$store.getters.getEnums['ENUM.Server.STATUS'].map((reader) => ({
        text: reader.NAME,
        value: reader.NUMBER
      }));
    },
  },
  watch: {
    serverFormProps() {
      this.serverForm = this.serverFormProps;
    },
    isShow() {
      if (this.isShow && this.$refs.serverForm) {
        this.$refs.serverForm.resetValidation();
      }
    }
  },
  methods: {
    async addNewServer() {
      try {
        const res = await api.servers.create(this.serverForm);
        this.$emit('add-server', res.data.DATA);
      } catch (error) {
        this.$store.dispatch(SNACK_ADD_MESSAGE, error.response.data.MESSAGE);
      }
    },
    async editServer() {
      try {
        const res = await api.servers.edit(this.serverForm);
        this.$emit('change-server', res.data.DATA);
      } catch (error) {
        this.$store.dispatch(SNACK_ADD_MESSAGE, error.response.data.MESSAGE);
      }
    },
    formSubmit() {
      if (this.isNewServerForm) {
        this.addNewServer();
      } else {
        this.editServer();
      }

      this.$emit('close-dialog');
    },
    keydownHandler(e) {
      if (('key', e.key === 'Escape')) this.$emit('close-dialog');
    },
  }
}
</script>
