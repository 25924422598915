<template>
  <div>
    <v-row class="mb-5">
      <v-col cols="auto" align-self="center">
        <h2 class="text-h2">{{ $t("Users") }}</h2>
      </v-col>
      <v-col cols="auto">
        <v-btn
          depressed
          color="primary"
          v-if="isValueStraightTreeMixin('user_edit')"
          @click="showNewUserForm"
        >
          <v-icon size="18" class="mr-2">$plus</v-icon>
          {{ $t("Add") }}
        </v-btn>
      </v-col>
      <v-spacer />
      <v-col cols="auto" class="d-flex flex-grow-1 justify-end">
        <v-btn
          depressed
          class="ml-4"
          color="primary"
          :outlined="filterIsEmpty"
          width="150"
          @click="showFilters = !showFilters"
        >
          <template v-if="!showFilters">
            <v-icon class="mr-2" size="20">$filter</v-icon>
            {{ $t("Filters") }}
          </template>
          <template v-else>
            <v-icon class="mr-2" size="20">$up</v-icon>
            {{ $t("Collapse") }}
          </template>
        </v-btn>
      </v-col>
    </v-row>

    <filter-component
      v-model="filterModel"
      :show="showFilters"
      :data="filterModel"
      @getDataFilters="applyFilter"
      @filterIsEmpty="filterIsEmpty = $event"
    />

    <v-data-table
      :headers="translatedTableHeaders"
      :items="tableItems"
      sort-by="ID"
      :sort-desc="true"
      :items-per-page="-1"
      :loading="tableLoading"
      :header-props="{ 'sort-icon': '$swap' }"
      :loading-text="`${$t('Loading')}...`"
      class="table-striped"
      :no-data-text="$t('Data not found')"
      :no-results-text="$t('Data not found')"
      :footer-props="{
        'items-per-page-text': $t('Lines per page'),
        'items-per-page-options': [15, 25, 50, 100],
      }"
    >
      <template #[`item.ACTIONS`]="{ item }">
        <div v-if="userId !== item.ID">
          <v-menu transition="slide-y-transition" bottom offset-y left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                size="24"
                class="ml-4"
                color="primary"
              >
                $ellipsisIcon
              </v-icon>
            </template>
            <v-list>
              <v-list-item
                v-if="isValueStraightTreeMixin('user_view_roles')"
                @click="showPermissionsAndProhibitionDialog(item)"
              >
                <v-icon class="mr-2" color="primary"> $writingIcon </v-icon>
                {{ $t("Roles") }}
              </v-list-item>
              <v-list-item
                v-if="isValueStraightTreeMixin('user_edit')"
                @click="showEditUserForm(item)"
              >
                <v-icon class="mr-2" color="primary"> $edit </v-icon>
                {{ $t("Edit") }}
              </v-list-item>

              <v-list-item
                v-if="isValueStraightTreeMixin('user_edit')"
                @click="blockToggleUser(item)"
              >
                <v-icon
                  class="mr-2"
                  :color="item.STATUS != 2 ? 'red' : 'green'"
                >
                  $lock
                </v-icon>
                {{ item.STATUS !== 2 ? $t("Block") : $t("Activate") }}
              </v-list-item>

              <v-list-item
                v-if="isValueStraightTreeMixin('user_edit')"
                @click="showRemoveUserConfirm(item)"
              >
                <v-icon class="mr-2" color="red"> $delete </v-icon>
                {{ $t("Delete") }}
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
      <template #[`item.STATUS`]="{ item }">
        <span :class="item.STATUS !== 1 ? 'red--text' : 'green--text'">
          {{ item.STATUS_NAME }}
        </span>
      </template>
      <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
        {{ pageStart }} - {{ pageStop }} {{ $t("of") }} {{ itemsLength }}
      </template>
    </v-data-table>

    <v-dialog v-model="userFormDialog" max-width="450">
      <v-card>
        <v-icon
          size="16"
          class="card__close-btn"
          @click="userFormDialog = false"
        >
          $close
        </v-icon>
        <v-card-title class="card__title-wrapper">
          <h3
            class="card__title"
            :class="{ card__title_narrow: !isNewUserForm }"
          >
            {{ isNewUserForm ? $t("Adding a user") : $t("Editing a user") }}
          </h3>
        </v-card-title>
        <v-card-text
          style="max-width: 410px; margin: 0 auto"
          class="text-center p-0 administration-users"
        >
          <v-form
            ref="userForm"
            v-model="userFormValid"
            @keyup.native.enter="userFormSubmit"
          >
            <v-text-field
              v-model="userForm.NAME"
              :rules="userFormRules.NAME"
              hide-details="auto"
              class="mb-5"
              outlined
            >
              <template #label>
                {{ $t("Full name") }}<span class="red--text">*</span>
              </template>
            </v-text-field>
            <v-select
              v-model="userForm.STATUS"
              :items="translateUserStatuses"
              :rules="userFormRules.STATUS"
              :menu-props="{ offsetY: true }"
              class="mb-5"
              append-icon="$down"
              hide-details="auto"
              outlined
            >
              <template #label>
                {{ $t("Status") }}<span class="red--text">*</span>
              </template>
            </v-select>

            <v-text-field
              v-model="userForm.LOGIN"
              :rules="userFormRules.LOGIN"
              hide-details="auto"
              class="mb-5"
              outlined
            >
              <template #append>
                <v-icon
                  v-if="userForm.LOGIN"
                  @click="copyField(userForm.LOGIN, $t('Username'))"
                >
                  $copy
                </v-icon>
              </template>
              <template #label>
                {{ $t("Username") }}<span class="red--text">*</span>
              </template>
            </v-text-field>
            <v-text-field
              v-model="userForm.PASSWORD"
              :rules="isNewUserForm ? userFormRules.PASSWORD : []"
              hide-details="auto"
              class="mb-5"
              outlined
            >
              <template #append>
                <v-btn
                  v-if="!userForm.PASSWORD"
                  height="20px"
                  class="pa-1 mr-2"
                  color="primary"
                  text
                  @click="userForm.PASSWORD = generatePass()"
                >
                  {{ $t("Generate") }}
                </v-btn>
                <v-icon
                  v-if="userForm.PASSWORD"
                  @click="copyField(userForm.PASSWORD, $t('Password'))"
                >
                  $copy
                </v-icon>
              </template>
              <template #label>
                {{ $t("New password") }}
                <span v-if="isNewUserForm" class="red--text">*</span>
              </template>
            </v-text-field>
            <v-textarea
              v-model="userForm.DESCRIPTION"
              :label="$t('Description')"
              class="mb-5"
              rows="4"
              row-height="30"
              no-resize
              hide-details="auto"
              outlined
            />
            <v-btn
              depressed
              block
              color="primary"
              :class="{ btn_disabled: !userFormValid }"
              @click="userFormSubmit"
            >
              {{ isNewUserForm ? $t("Add") : $t("Save") }}
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <confirm-remove-dialog
      v-if="userRemoveConfirm"
      :loading="userRemoveLoading"
      @closeDialog="userRemoveConfirm = false"
      @confirm="removeUser"
    >
      <template #title>
        <h3 class="card__title card__title_small">
          {{ $t("Delete user") }} «{{ userForm.NAME }}»?
        </h3>
      </template>
      <template #default>
        {{
          $t("After deletion, it will be impossible to restore the user data")
        }}.
      </template>
    </confirm-remove-dialog>

    <checkbox-list-dialog
      v-if="showUserObjectsDialog"
      :objects="objects"
      :title="$t('Objects to visit')"
      :subtitle="userForm.NAME"
      :headers="[{ text: 'NAME', value: 'OBJECT_NAME' }]"
      item-key="OBJECT_ID"
      :is-list-dense="true"
      :show-checkboxes="false"
      @closeDialog="showUserObjectsDialog = false"
    />
    <LinkedRolesDialog
      :is-show="isExternalReaderDialogShow"
      :table-headers="linkedRolesHeaders"
      :table-items="linkedRoles"
      :user="activeUser"
      @close-dialog="isExternalReaderDialogShow = false"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import api from "@/api";
import { COMMON_SET_LOADING } from "@/store/types/mutation-types";
import { SNACK_ADD_MESSAGE } from "@/store/types/action-types";
import { USER_ROLES, USER_STATUSES } from "@/constants";
import CheckboxListDialog from "@/components/blocks/CheckboxListDialog.vue";
import PermissionsAndProhibition from "@/components/blocks/PermissionsAndProhibition.vue";
import RadioListDialog from "@/components/blocks/RadioListDialog.vue";
import ConfirmRemoveDialog from "@/components/blocks/ConfirmRemoveDialog.vue";
import FilterComponent from "@/components/blocks/FilterComponent.vue";
import { filterModel, tableHeaders, linkedRolesHeaders } from "./data.user";
import { copyToClipboard } from "@/helpers/helpers";
import LinkedRolesDialog from "./LinkedRolesDialog.vue";
import isValueStraightTreeMixin from "@/mixins/isValueStraightTreeMixin.js";

export default {
  name: "UsersPage",
  components: {
    ConfirmRemoveDialog,
    CheckboxListDialog,
    FilterComponent,
    PermissionsAndProhibition,
    LinkedRolesDialog,
  },
  mixins: [isValueStraightTreeMixin],
  data() {
    return {
      filterModel,
      tableHeaders,
      USER_STATUSES,
      USER_ROLES,

      filterIsEmpty: true,
      isNewUserForm: true,
      objects: [],
      functionalRoles: [],
      objectRoles: [],
      showFilters: false,
      showUserObjectsDialog: false,
      isPermissionsAndProhibitionDialogShow: false,
      tableItems: [],
      tableLoading: false,
      userRemoveConfirm: false,
      userRemoveLoading: false,
      userFormDialog: false,
      userFormValid: false,
      userForm: this.createUserFormModel(),
      userFormRules: {
        NAME: [
          (v) =>
            !!v || `${this.$t("It is necessary to fill in the «full name»")}.`,
        ],
        LOGIN: [(v) => !!v || `${this.$t("You need to fill in «Login»")}.`],
        PASSWORD: [
          (v) => !!v || `${this.$t("You need to fill in «Password»")}.`,
        ],
        STATUS: [
          (v) =>
            !!v || v === 0 || `${this.$t("You need to fill in «Status»")}.`,
        ],
      },

      isExternalReaderDialogShow: false,
      linkedRoles: [],
      linkedRolesHeaders,
      activeUser: {},
    };
  },
  computed: {
    ...mapState({
      userId: (state) => state.user.model.ID,
    }),
    translatedTableHeaders() {
      return this.tableHeaders.map((elem) => ({
        ...elem,
        text: this.$t(elem.text),
      }));
    },
    translateUserRoles() {
      return USER_ROLES.map((elem) => ({ ...elem, text: this.$t(elem.text) }));
    },
    translateUserStatuses() {
      return USER_STATUSES.map((elem) => ({
        ...elem,
        text: this.$t(elem.text),
      }));
    },
  },
  mounted() {
    this.applyFilter();

    api.roles.getAll(1).then((res) => {
      this.functionalRoles = res.data.DATA;
    });

    api.roles.getAll(2).then((res) => {
      this.objectRoles = res.data.DATA;
    });
  },
  methods: {
    async showPermissionsAndProhibitionDialog(user) {
      this.activeUser = user;
      await api.users
        .getUserRole({ id: user.ID, is_bind: true })
        .then((e) => {
          this.linkedRoles = e.data.DATA;
        })
        .catch((error) => {
          this.$store.dispatch(SNACK_ADD_MESSAGE, error.response.data.MESSAGE);
        });
      this.isExternalReaderDialogShow = true;
    },
    showNewUserForm() {
      if (this.$refs.userForm) this.$refs.userForm.resetValidation();
      this.userForm = this.createUserFormModel();
      this.isNewUserForm = true;
      this.userFormDialog = true;
    },
    showEditUserForm(user) {
      if (!user) return false;
      this.userForm = this.createUserFormModel(user);
      this.isNewUserForm = false;
      this.userFormDialog = true;
    },
    showRemoveUserConfirm(user) {
      this.userForm = this.createUserFormModel(user);
      this.userRemoveConfirm = true;
    },
    createUserFormModel(user = {}) {
      return {
        ID: user.ID || null,
        NAME: user.NAME || "",
        STATUS: user.STATUS || 1,
        LOGIN: user.LOGIN || "",
        PASSWORD: "",
        DESCRIPTION: user.DESCRIPTION || "",
      };
    },
    userFormSubmit() {
      if (this.$refs.userForm.validate()) {
        const formData = new FormData();
        Object.keys(this.userForm).forEach((key) => {
          let value = this.userForm[key];
          formData.append(key.toLowerCase(), value);
        });
        this.$store.commit(COMMON_SET_LOADING);
        if (this.isNewUserForm) {
          api.users
            .create(formData)
            .then((res) => {
              this.userFormDialog = false;
              this.tableItems.push(res.data.DATA);
              this.addMessage(res);
            })
            .catch((error) => {
              this.$store.dispatch(
                SNACK_ADD_MESSAGE,
                this.$t(error.response.data.MESSAGE)
              );
            })
            .finally(() => this.$store.commit(COMMON_SET_LOADING, false));
        } else {
          api.users
            .update(this.userForm.ID, formData)
            .then((res) => {
              this.userFormDialog = false;
              const userIndex = this.tableItems.findIndex(
                (item) => item.ID === this.userForm.ID
              );
              Object.assign(this.tableItems[userIndex], this.userForm);
              this.addMessage(res);
            })
            .catch((error) => {
              this.$store.dispatch(
                SNACK_ADD_MESSAGE,
                this.$t(error.response.data.MESSAGE)
              );
            })
            .finally(() => this.$store.commit(COMMON_SET_LOADING, false));
        }
      }
    },
    addMessage(res) {
      this.$store.dispatch(SNACK_ADD_MESSAGE, {
        type: "success",
        text:
          this.$i18n.locale === "ru"
            ? res.data.MESSAGE
            : this.$t(res.data.MESSAGE),
      });
    },
    blockToggleUser(user) {
      if (!user || this.userId == user.ID) return false;
      this.userForm = this.createUserFormModel(user);
      this.isNewUserForm = false;
      this.userForm.STATUS = this.userForm.STATUS != 2 ? 2 : 1;
      const formData = new FormData();
      Object.keys(this.userForm).forEach((key) => {
        let value = this.userForm[key];
        value = value === null ? "" : value;
        formData.append(key.toLowerCase(), value);
      });
      this.$store.commit(COMMON_SET_LOADING);
      api.users
        .update(this.userForm.ID, formData)
        .then((res) => {
          this.$store.commit(COMMON_SET_LOADING, false);
          const userIndex = this.tableItems.findIndex(
            (item) => item.ID === this.userForm.ID
          );
          Object.assign(this.tableItems[userIndex], this.userForm);
          this.addMessage(res);
        })
        .catch((error) => {
          this.$store.commit(COMMON_SET_LOADING, false);
          this.$store.dispatch(
            SNACK_ADD_MESSAGE,
            this.$t(error.response.data.MESSAGE)
          );
        });
    },
    removeUser() {
      this.userRemoveLoading = true;
      api.users
        .remove(this.userForm.ID)
        .then((res) => {
          if (res.data.DATA) {
            this.userRemoveConfirm = false;
            this.userRemoveLoading = false;
            const userIndex = this.tableItems.findIndex(
              (item) => item.ID === this.userForm.ID
            );
            this.tableItems.splice(userIndex, 1);
            this.addMessage(res);
          }
        })
        .catch((error) => {
          this.userRemoveConfirm = false;
          this.userRemoveLoading = false;
          this.$store.dispatch(
            SNACK_ADD_MESSAGE,
            this.$t(error.response.data.MESSAGE)
          );
        });
    },
    copyField(item, label) {
      copyToClipboard(item);
      this.$store.dispatch(SNACK_ADD_MESSAGE, {
        type: "success",
        text: `${label} ${this.$t("copied")}`,
      });
    },
    applyFilter() {
      this.$store.commit(COMMON_SET_LOADING);
      const status = this.getValueFromFilter("STATUS");
      api.users
        .getAll({ status })
        .then((res) => (this.tableItems = res.data.DATA))
        .catch((error) => {
          this.$store.dispatch(SNACK_ADD_MESSAGE, {
            type: "success",
            text: error.response.data.MESSAGE,
          });
        })
        .finally(() => this.$store.commit(COMMON_SET_LOADING, false));
    },
    getValueFromFilter(field) {
      return this.filterModel.find((item) => item.name === field).data;
    },
    generatePass() {
      const CharacterSet =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!%&*$#^@";
      const lengthPass = 12;
      let password = "";
      for (let i = 0; i < lengthPass; i++) {
        password += CharacterSet.charAt(
          Math.floor(Math.random() * CharacterSet.length)
        );
      }
      return password;
    },
  },
};
</script>

<style lang="scss" >
.administration-users {
  .v-input__append-inner {
    z-index: 10;
  }
}
</style>
