import { groupBy } from 'lodash-es';
import api from '@/api';

export default {
  state: {
    showListСommon: false,
    expandAllCounter: 0,
    roleListCommon: [],
  },
  getters: {
    getShowListСommon(state) {
      return state.showListСommon;
    },
    getExpandAllCounter(state) {
      return state.expandAllCounter;
    },
    getRoleListCommon(state) {
      return state.roleListCommon;
    }
  },
  mutations: {
    updateShowList(state, newShowListСommon) {
      state.showListСommon = newShowListСommon;
    },
    updateExpandAllCounter(state, newExpandAllCounter) {
      state.expandAllCounter = newExpandAllCounter;
    },
    updateRoleListCommon(state, newRoleListCommon) {
      state.roleListCommon = newRoleListCommon;
    },
  },
};
