export const keyCheck = [
  { treeKey: 'requests_m', redirect: '/requests', id: 3 },
  { treeKey: 'passages_m', redirect: '/passages', id: 4 },
  { treeKey: 'reports_m', redirect: '/reports/main', id: 5 },
  { treeKey: 'objects_m', redirect: '/objects', id: 2 },
];

export const keyCheckMenu = [
  { treeKey: 'users_m', redirect: '/administration/users', id: 12 },
  { treeKey: 'directories_m', redirect: '/administration/reference-books', id: 19 },
  { treeKey: 'systems_m', redirect: '/administration/external-systems', id: 11 },
  { treeKey: 'devices_m', redirect: '/administration/equipments', id: 13 },
  { treeKey: 'zones_m', redirect: '/administration/access-zones', id: 14 },
  { treeKey: 'gates_m', redirect: '/administration/gates', id: 15 },
  { treeKey: 'roles_m', redirect: '/administration/roles', id: 16 },
  { treeKey: 'readers_m', redirect: '/administration/external-readers', id: 17 },
  { treeKey: 'servers_m', redirect: '/administration/servers', id: 18 },
  { treeKey: 'settings_m', redirect: '/administration/settings', id: 20 },
];

export const keyCheckNonCore = [
  { treeKey: 'users_m', redirect: '/reports/passes', id: 0 },
  { treeKey: 'users_m', redirect: '/reports/event', id: 0 },
];

export const generalKeyCheck = [...keyCheck, ...keyCheckMenu, ...keyCheckNonCore];
