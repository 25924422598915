import Vue from 'vue';
import Vuex from 'vuex';
import user from './modules/user';
import common from './modules/common';
import snack from './modules/snack';
import systemConfig from './modules/systemConfig.js';
import enums from './modules/enums.js';
import defaultDialog from './modules/defaultDialog';
import cars from './modules/cars';
import emergencyServices from './modules/emergencyServices';
import general from './modules/general';
import groupOpenType from './modules/groupOpenType';
import objectsToVisit from './modules/objectsToVisit';
import activeCode from './modules/activeCode';
import role from './modules/role';
Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user,
    common,
    snack,
    systemConfig,
    enums,
    defaultDialog,
    cars,
    emergencyServices,
    general,
    groupOpenType,
    objectsToVisit,
    activeCode,
    role,
  },
});
