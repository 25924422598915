import dateHelpers from "@/helpers/dateHelpers";
import {
  PASSAGE_OPEN_TYPE,
  FLAG_IS_PASSED
} from '@/constants';

export const tableHeaders = [
  {
    text: "Gate",
    value: "GATE",
    cellClass: "font-weight-light",
    sortable: true,
    width: "170px"
  },
  {
    text: "Total number of vehicles registered by the system",
    value: "AMOUNT_PASS",
    cellClass: "font-weight-light",
    sortable: true,
    width: "310px"
  },
  {
    text: "Total number of vehicles with recognized GRZ",
    value: "AMOUNT_GRZ",
    cellClass: "font-weight-light",
    sortable: true,
    width: "280px"
  },
  {
    text: "Total number of SMP vehicles",
    value: "AMOUNT_EMERGENCY",
    cellClass: "font-weight-light",
    sortable: true,
    width: "230px"
  },
  {
    text: "Number of manual barrier openings",
    value: "AMOUNT_MANUAL",
    cellClass: "font-weight-light",
    sortable: true,
    width: "190px"
  },
  {
    text: "Number of automatic gate openings",
    value: "AMOUNT_AUTOMATICAL",
    cellClass: "font-weight-light",
    sortable: true,
    width: "220px"
  },
];
export const tableHeaders2 = [
  {
    text: "Date of the event",
    value: "DATE",
    cellClass: "font-weight-medium",
    sortable: true,
    width: "180px"
  },
  {
    text: "SLP",
    value: "CODE",
    cellClass: "font-weight-medium",
    sortable: false,
    width: "120px"
  },
  {
    text: "Vehicle brand",
    value: "BRAND",
    cellClass: "font-weight-medium",
    sortable: false,
    width: "150px"
  },
  {
    text: "Vehicle Model",
    value: "MODEL",
    cellClass: "font-weight-medium",
    sortable: true,
    width: "150px"
  },
  {
    text: "The result of the recognition of the CGS of SMP cars",
    value: "EMERGENCY",
    cellClass: "font-weight-medium",
    sortable: true,
    width: "230px"
  },
  {
    text: "The method of opening the barrier",
    value: "OPEN_TYPE",
    cellClass: "font-weight-medium",
    sortable: true,
    width: "160px"
  },
  {
    text: "Gate",
    value: "GATE",
    cellClass: "font-weight-light",
    sortable: true,
    width: "300px"
  },
  {
    text: "Direction",
    value: "ENTRY",
    cellClass: "font-weight-medium",
    sortable: true,
    width: "140px"
  },
  {
    text: "The presence of a pass",
    value: "STATE",
    cellClass: "font-weight-medium",
    sortable: true,
    width: "170px"
  },
  {
    text: "Pass result",
    value: "PASSED",
    cellClass: "font-weight-medium",
    sortable: true,
    width: "100px"
  },
  {
    text: 'Actions',
    value: 'ACTIONS',
    align: 'end',
    filterable: false,
    sortable: false,
    class: 'fixed-right-cel',
    cellClass: 'fixed-right-cel',
  }
];
export const filterModel = [
  {
    name: "START_S",
    type: "date-time-picker",
    data: dateHelpers.getToday().startOf("day").toDate(),
    label: "Start of the period",
  },
  {
    name: "START_PO",
    type: "date-time-picker",
    data: dateHelpers.getToday().endOf("day").toDate(),
    label: "End of the period",
  },
  {
    name: "GATE",
    type: "multi-autocomplete",
    data: [],
    items: [],
    label: "Gate",
  },
  {
    name: 'IDENTIFIER',
    type: 'input-field',
    data: '',
    label: 'Plate num',
  },
  {
    name: 'CURRENT_BRAND_ID',
    type: 'autocomplete-translate',
    data: [],
    items: [],
    label: 'Car brand',
  },
  {
    name: 'CURRENT_MODEL_ID',
    type: 'autocomplete-translate',
    disabled:true,
    data: [],
    items: [],
    label: 'Car model',
  },
  {
    name: 'EMERGENCY_SERVICES',
    type: 'autocomplete',
    data: [],
    items: [],
    label: 'Emergency services',
  },
  {
    name: "OPEN_TYPE",
    type: "select",
    data: [],
    items: PASSAGE_OPEN_TYPE,
    label: "The method of opening the barrier",
  },
  {
    name: 'IS_ENTRY',
    type: 'select',
    data: null,
    items: [{ text: 'Entry', value: 1 }, { text: 'Exit', value: 2 }, { text: 'Not specified', value: 3 }],
    label: 'Direction',
  },
  {
    name: 'IS_PASSED',
    type: 'select',
    data: null,
    items: FLAG_IS_PASSED,
    label: 'The passage has been completed',
  },
  {
    name: 'IS_REQUEST',
    type: 'select',
    data: null,
    items: FLAG_IS_PASSED,
    label: 'The presence of a pass',
  },
];
