import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import { USER_CHECK_SESSION } from '../store/types/action-types';

import ReportsLayout from '../views/reports';
import ReportsMain from '../views/reports/reportMain';
import ReportPasses from '../views/reports/reportPasses';
import ReportEvent from '../views/reports/reportEvent';
import LoginPage from '../views/LoginPage.vue';
import PassagesPage from '../views/passages';
import AdministrationLayout from '../views/administration';
import AccessZonesPage from '../views/administration/accessZones';
import EquipmentsPage from '../views/administration/equipments';
import ExternalSystemsPage from '../views/administration/externalSystems';
import UsersPage from '../views/administration/users';
import ObjectsPage from '../views/objects';
import SettingsPage from '../views/administration/settings';
import SelfRegistrationPage from '../views/selfRegistration';
import CheckRequest from '../views/checkRequest';
import Gates from '../views/administration/gates';
import Roles from '../views/administration/roles';
import ExternalReaders from '../views/administration/externalReaders';
import Servers from '../views/administration/servers';
import ReferenceBooks from '../views/administration/referenceBooks';
import IconLibrary from '@/components/icons/library-icon.vue';
import RequestsPage from '@/views/requests';
import MobileRequestsPage from '@/views/mobileRequests';
import ObjectsToVisit from '@/views/mobileRequests/objectsToVisit';
import DialogMobileRequests from '@/views/mobileRequests/dialogMobileRequests/index.vue';

import middleware from './middleware/index';
import auth from './middleware/auth';
import common from './middleware/common'

Vue.use(VueRouter);

const isMobile = store.getters.isMobile();

const metaLayoutAdmin = { isNeedAuth: true, middleware: [common], layout: 'admin' }


const routes = [
  {
    path: '/',
    name: 'SelfRegistration',
    component: SelfRegistrationPage,
    meta: { layout: 'backgroundGradient' },
  },
  {
    path: '/login',
    name: 'Login',
    meta: { middleware: [auth] },
    component: LoginPage,
  },
  {
    path: '/administration',
    name: 'Administration',
    meta: { isNeedAuth: true, middleware: [common] },
    component: AdministrationLayout,
    redirect: '/administration/users',
    children: [
      {
        path: 'users',
        name: 'Users',
        meta: metaLayoutAdmin,
        component: UsersPage,
      },
      {
        path: 'external-systems',
        name: 'ExternalSystems',
        meta: metaLayoutAdmin,
        component: ExternalSystemsPage,
      },
      {
        path: 'equipments',
        name: 'Equipments',
        meta: metaLayoutAdmin,
        component: EquipmentsPage,
      },
      {
        path: 'access-zones',
        name: 'AccessZones',
        meta: metaLayoutAdmin,
        component: AccessZonesPage,
      },
      {
        path: 'gates',
        name: 'Gates',
        meta: metaLayoutAdmin,
        component: Gates,
      },
      {
        path: 'roles',
        name: 'Roles',
        meta: metaLayoutAdmin,
        component: Roles,
      },
      {
        path: 'external-readers',
        name: 'ExternalReaders',
        meta: metaLayoutAdmin,
        component: ExternalReaders
      },
      {
        path: 'servers',
        name: 'Servers',
        meta: metaLayoutAdmin,
        component: Servers
      },
      {
        path: 'reference-books',
        name: 'ReferenceBooks',
        meta: metaLayoutAdmin,
        component: ReferenceBooks
      },
      {
        path: 'settings',
        name: 'Settings',
        meta: metaLayoutAdmin,
        component: SettingsPage,
      },
    ],
  },
  {
    path: '/objects',
    name: 'Objects',
    meta: metaLayoutAdmin,
    component: ObjectsPage,
  },
  {
    path: '/requests',
    name: 'Pass',
    meta: metaLayoutAdmin,
    component: isMobile ? MobileRequestsPage : RequestsPage,
  },
  {
    path: '/requests/objects',
    name: 'ObjectsToVisit',
    component: ObjectsToVisit,
    meta: metaLayoutAdmin,
  },
  {
    path: '/requests/objects/add-pass/:object_id',
    name: 'DialogMobileRequests',
    component: DialogMobileRequests,
    meta: metaLayoutAdmin,
  },
  {
    path: '/requests/edit-pass/:id_pass',
    name: 'DialogMobileRequests',
    component: DialogMobileRequests,
    meta: metaLayoutAdmin,
  },
  {
    path: '/reports',
    name: 'Reports',
    meta: metaLayoutAdmin,
    component: ReportsLayout,
    redirect: '/reports/main',
    children: [
      {
        path: '/reports/main',
        name: 'ReportsMain',
        component: ReportsMain,
        meta: metaLayoutAdmin,
      },
      {
        path: 'passes',
        name: 'ReportPasses',
        meta: metaLayoutAdmin,
        component: ReportPasses,
      },
      {
        path: 'event',
        name: 'ReportEvent',
        meta: metaLayoutAdmin,
        component: ReportEvent,
      },
    ]
  },

  {
    path: '/passages',
    name: 'Passages',
    meta: metaLayoutAdmin,
    component: PassagesPage,
  },
  {
    path: '/check-request/:id',
    name: 'checkRequest',
    component: CheckRequest,
  },
  {
    path: '/icons',
    name: 'IconLibrary',
    component: IconLibrary,
  },
  {
    path: '/*',
    name: 'NotFound',
    redirect: '/',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = store.getters.isLoggedIn;
  const userRole = store.state.user.model.ROLE;
  if (to.meta.isNeedAuth) {
    if (!isLoggedIn) {
      return next('/login');
    }
    if (userRole === undefined) {
      return store.dispatch(USER_CHECK_SESSION).then(() => middleware(to, from, next));
    }
    return middleware(to, from, next);
  }

  if (isLoggedIn && userRole === undefined) {
    return store.dispatch(USER_CHECK_SESSION).then(() => middleware(to, from, next));
  }
  return middleware(to, from, next);
});
export default router;
