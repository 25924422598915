export const USER_STATUSES = [
  { text: 'Active', value: 1, colorClassName: 'green--text' },
  { text: 'Blocked', value: 2, colorClassName: 'red--text' },
];

export const USER_ROLES = [
  { text: 'Administrator', value: 0 },
  { text: 'Pass Bureau', value: 1 },
  { text: 'Organization (consideration)', value: 2 },
  { text: 'Organization (agreement)', value: 3 },
  { text: 'Creating passes', value: 4 },
];

export const SYSTEM_STATUSES = [
  { text: 'Active', value: 1, colorClassName: 'green--text' },
  { text: 'Blocked', value: 2, colorClassName: 'red--text' },
];

export const SYSTEM_TYPE = [
  { text: 'Локальная', value: 0 },
  { text: 'Внешняя', value: 1 },
];

export const CONFIRM_ENTRANCE = [
  { text: 'Yes', value: true },
  { text: 'No', value: false },
];

export const CONFIRM_EXIT = [
  { text: 'Yes', value: true },
  { text: 'No', value: false },
];

export const DEVICE_STATUSES = [
  { text: 'Active', value: 1, colorClassName: 'green--text' },
  { text: 'Blocked', value: 2, colorClassName: 'red--text' },
];

export const DEVICE_TYPE = [
  { text: 'Turnstile', value: 1 },
  { text: 'Electronic lock', value: 2 },
  { text: 'Barrier', value: 3 },
];

export const DEVICE_TYPE_VALUE = {
  turnstile: 1,
  electronicLock: 2,
  barrier: 3,
};

export const DEVICE_IS_EXTERNAL = [
  { text: 'Yes', value: true },
  { text: 'No', value: false },
];

// Используется для обработки данных пришедших с сервера
export const DEVICE_IS_ENTRY_in = [
  { text: 'Inside', value: true },
  { text: 'Out', value: false },
  { text: 'Not set', value: null },
];
// Используется для отправки фильтров
export const DEVICE_IS_ENTRY_out = [
  { text: 'Inside', value: 1 },
  { text: 'Out', value: 2 },
  { text: 'Not set', value: 0 },
];

// Используется для обработки данных пришедших с сервера
export const DEVICE_NO_PASS_EXIT_in = [
  { text: 'Yes', value: true },
  { text: 'No', value: false },
  { text: 'Systemic', value: null },
];
// Используется для отправки фильтров
export const DEVICE_NO_PASS_EXIT_out = [
  { text: 'Yes', value: 1 },
  { text: 'No', value: 2 },
  { text: 'Systemic', value: 0 },
];

// Используется для обработки данных пришедших с сервера
export const DEVICE_EXPIRED_EXIT_in = [
  { text: 'Yes', value: true },
  { text: 'No', value: false },
  { text: 'Systemic', value: null },
];
// Используется для отправки фильтров
export const DEVICE_EXPIRED_EXIT_out = [
  { text: 'Yes', value: 1 },
  { text: 'No', value: 2 },
  { text: 'Systemic', value: 0 },
];

// Используется для обработки данных пришедших с сервера
export const DEVICE_EXPIRED_OUT_in = [
  { text: 'Yes', value: true },
  { text: 'No', value: false },
  { text: 'Systemic', value: null },
];
// Используется для отправки фильтров
export const DEVICE_EXPIRED_OUT_out = [
  { text: 'Yes', value: 1 },
  { text: 'No', value: 2 },
  { text: 'Systemic', value: 0 },
];

export const ZONE_STATUSES = [
  { text: 'Active', value: 1, colorClassName: 'green--text' },
  { text: 'Blocked', value: 2, colorClassName: 'red--text' },
];

export const OBJECT_STATUSES = [
  { text: 'Active', value: 1, colorClassName: 'green--text' },
  { text: 'Blocked', value: 2, colorClassName: 'red--text' },
];

export const PERIOD_TYPES = [
  { text: 'Access period', value: 1 },
  { text: 'Creation period', value: 2 },
];
export const REQUEST_TYPES = [
  { text: 'Admission to the visit', value: 1 },
  { text: 'Parking pass', value: 2 },
];
export const ROLE_STATUSES = [
  { text: 'Active', value: 1, colorClassName: 'green--text' },
  { text: 'Blocked', value: 2, colorClassName: 'red--text' },
];

export const ROLE_TYPES = {
  functionalRole: 1,
  objectRole: 2,
};

export const OBJECT_SYSTEM_ID = { local: 0 };

export const REQUEST_STATUSES = [
  {
    text: 'systemValue',
    value: null,
    colorClassName: 'text-color-dark-primary',
    color: 'dark-primary',
    backgroundColor: 'background-color-dark-primary',
    border: 'border-color-dark-primary',
  },
  {
    text: 'Review',
    value: 0,
    colorClassName: 'text-color-dark-primary',
    color: 'dark-primary',
    backgroundColor: 'background-color-dark-primary',
    border: 'border-color-dark-primary',
  },
  {
    text: 'Reviewed',
    value: 1,
    colorClassName: 'text-color-primary',
    color: 'primary',
    backgroundColor: 'background-color-primary',
    border: 'border-color-primary',
  },
  {
    text: 'Agreed',
    value: 2,
    colorClassName: 'text-color-yellow',
    color: 'yellow',
    backgroundColor: 'background-color-yellow',
    border: 'border-color-yellow',
  },
  {
    text: 'Confirmed',
    value: 3,
    colorClassName: 'text-color-green',
    color: 'green',
    backgroundColor: 'background-color-green',
    border: 'border-color-green',
  },
  {
    text: 'Rejected',
    value: 4,
    colorClassName: 'text-color-red',
    color: 'red',
    backgroundColor: 'background-color-red',
    border: 'border-color-red',
  },
];

export const REQUEST_IS_MULTIPASS = [
  { text: 'Yes', value: true, disabled: false },
  { text: 'No', value: false, disabled: false },
];

export const FLAG_INSIDE = [
  {
    text: 'Not used',
    value: 0,
  },
  {
    text: 'Pass inside the building, entered through the device of the outer perimeter',
    value: 1,
  },
  {
    text: 'Pass inside the building, did not enter through the device of the outer perimeter',
    value: 2,
  },
  {
    text: 'The pass left the building through the outer perimeter device',
    value: 3,
  },
  {
    text: 'The pass left the building through the outer perimeter device after the expiration date',
    value: 4,
  },
];
// Используется в филтрах прохода
export const FLAG_PASSAGE = [
  {
    text: 'Inside',
    value: true,
  },
  {
    text: 'Outside',
    value: false,
  },
];

export const FLAG_IS_PASSED = [
  { text: 'Yes', value: true },
  { text: 'No', value: false },
];

// Используется для отправки фильтров
export const EMAIL_SEND_ERR_OUT = [
  { text: 'Sending was not', value: 0 },
  { text: 'Successful sending', value: 1 },
  { text: 'Error', value: 2 },
];
// Используется для обработки данных пришедших с сервера
export const EMAIL_SEND_ERR_IN = [
  { text: 'Sending was not', value: null },
  { text: 'Successful sending', value: false },
  { text: 'Error', value: true },
];

export const ALLOWED_PASS_TYPES = {
  systemValue: 0,
  singlePass: 1,
  multiPass: 2,
  singleAndMultiPass: 3,
};
export const REPORT_TYPES = {
  passReportId: '1',
  recognitionsReportId: '2',
  gateReportId: '3',
  visitorsReportId: '4',
};

export const PASSAGE_OPEN_TYPE = [
  { text: 'Automatic', value: 1 },
  { text: 'Manual', value: 2 },
  { text: 'Without opening', value: 3 },
];

export const ACCESS_TYPE = [
  { text: 'Allowed', value: 0 },
  { text: 'Banned', value: 1 },
];

export const READER_EXTERNAL = {
  combinedReader: 1,
  camera: 2,
};

export const MEANING_RIGHTS = {
  notSpecified: 0,
  allowed: 1,
  forbidden: 2,
};

export const FILTER_TYPES = {
  TEXT: 0,
  BIGINT: 2,
  TIMESTAMP: 4,
  DATE: 5,
  TIME: 6,
  NUMERIC: 7,
  ENUM: 8,
  SELECT: 10,
};
