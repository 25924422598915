<template>
  <v-dialog
    max-width="450"
    :value="isShow"
    @click:outside="$emit('close-dialog')"
    @keydown="keydownHandler"
  >
    <v-card>
      <v-icon size="16" class="card__close-btn" @click="$emit('close-dialog')">
        $close
      </v-icon>
      <v-card-title class="card__title-wrapper">
        <h3 class="card__title">
          {{
            isNewExternalForm
              ? $t("Adding an external reader")
              : $t("Editing an external reader")
          }}
        </h3>
      </v-card-title>

      <v-card-text
        style="max-width: 370px; margin: 0 auto"
        class="text-center pb-16"
      >
        <v-form
          ref="externalReaderForm"
          v-model="formValid"
          @keyup.native.enter="formSubmit"
        >
          <v-text-field
            v-model="externalForm.NAME"
            :rules="externalReaderFormRules.NAME"
            hide-details="auto"
            class="mb-5"
            outlined
          >
            <template #label>
              {{ $t("Name") }}<span class="red--text">*</span>
            </template>
          </v-text-field>

          <v-select
            v-model="externalForm.STATUS"
            :items="getFormattedEnums('ENUM.ReaderExternal.STATUS')"
            :rules="externalReaderFormRules.STATUS"
            :menu-props="{ offsetY: true }"
            class="mb-5"
            append-icon="$down"
            hide-details="auto"
            outlined
          >
            <template #label>
              {{ $t("Status") }}<span class="red--text">*</span>
            </template>
          </v-select>

          <v-select
            v-model="externalForm.TYPE"
            :items="getFormattedEnums('ENUM.ReaderExternal.TYPE')"
            :rules="externalReaderFormRules.TYPE"
            :menu-props="{ offsetY: true }"
            class="mb-5"
            append-icon="$down"
            hide-details="auto"
            outlined
          >
            <template #label>
              {{ $t("Reader type") }}<span class="red--text">*</span>
            </template>
          </v-select>

          <div
            class="card__direction"
            v-show="
             isTypeCamera()
            "
          >
            <div class="text-left card__direction-title">
              {{ $t("Confirmation of travel in the direction") }}:
            </div>
            <div class="card__direction-checkboxs">
              <v-checkbox
                :label="$t('to the camera')"
                v-model="externalForm.CONFIRM_IN"
              >
              </v-checkbox>
              <v-checkbox
                :label="$t('from the camera')"
                v-model="externalForm.CONFIRM_OUT"
              ></v-checkbox>
            </div>
          </div>

          <v-text-field
            v-model="externalForm.ADDRESS"
            :rules="
              isTypeCamera()
                ? [true] : externalReaderFormRules.ADDRESS
            "
            v-show="
             !isTypeCamera()
            "
            hide-details="auto"
            class="mb-5"
            outlined
          >
            <template #label>
              {{ $t("Reader address")}}
              <span
                v-show="
                  externalForm.TYPE ===
                  READER_EXTERNAL.combinedReader
                "
                class="red--text"
                >*</span
              >
            </template>
          </v-text-field>

          <v-select
            v-model="externalForm.IS_ENTRY"
            :items="translatedEntryTypes"
            :rules="externalReaderFormRules.DIRECTION"
            :menu-props="{ offsetY: true }"
            class="mb-5"
            append-icon="$down"
            hide-details="auto"
            outlined
          >
            <template #label>
              {{ $t("Direction") }}<span class="red--text">*</span>
            </template>
          </v-select>

          <v-text-field
            v-model="externalForm.SID"
            :rules="
             isTypeCamera()
                ? externalReaderFormRules.SID
                : [true]
            "
            hide-details="auto"
            class="mb-5"
            outlined
          >
            <template #label>
              {{ $t("Symbolic identifier") }}
              <span
                v-show="
                 isTypeCamera()
                "
                class="red--text"
                >*</span
              >
            </template>
          </v-text-field>

          <v-text-field
            v-model="externalForm.PARAMETERS"
            hide-details="auto"
            class="mb-5"
            outlined
          >
            <template #label>
              {{ $t("Parameter set") }}
            </template>
          </v-text-field>

          <v-textarea
            v-model="externalForm.DESCRIPTION"
            :label="$t('Description')"
            class="mb-5"
            rows="4"
            row-height="30"
            no-resize
            hide-details="auto"
            outlined
          />

          <v-btn
            depressed
            block
            color="primary"
            :class="{ btn_disabled: !formValid }"
            @click="formSubmit"
          >
            {{ isNewExternalForm ? $t("Add") : $t("Save") }}
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import api from "@/api";
import { SNACK_ADD_MESSAGE } from "@/store/types/action-types";
import { READER_EXTERNAL } from '@/constants/index';

export default {
  name: "ExternalReaderDialog",
  props: {
    isShow: {
      type: Boolean,
      required: true,
    },
    isNewExternalForm: {
      type: Boolean,
      required: true,
    },
    externalFormProps: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      externalForm: {
        CONFIRM_IN: false,
        CONFIRM_OUT: false,
      },
      READER_EXTERNAL,

      externalReaderFormRules: {
        NAME: [(v) => !!v || `${this.$t("You need to fill in «Name»")}.`],
        STATUS: [(v) => !!v || `${this.$t("You need to fill in «Status»")}.`],
        TYPE: [(v) => !!v || `${this.$t("You must select «Reader type».")}`],
        SID: [
          (v) =>
            !!v || `${this.$t("You need to fill in «Symbolic identifier»")}`,
        ],
        ADDRESS: [
          (v) =>
            !!v || `${this.$t("You need to fill in «Symbolic identifier»")}`,
        ],
        DIRECTION: [
          (v) =>
            !!v ||
            v === false ||
            `${this.$t("You need to fill in")} «${this.$t("Direction")}».`,
        ],
      },

      formValid: false,
      entryTypes: [
        { text: "Entry", value: true },
        { text: "Exit", value: false },
      ],
    };
  },
  computed: {
    translatedEntryTypes() {
      return this.entryTypes.map((elem) => ({
        ...elem,
        text: this.$t(elem.text),
      }));
    },
  },
  watch: {
    externalFormProps() {
      this.externalForm = this.externalFormProps;
    },
    isShow() {
      if (this.isShow && this.$refs.externalReaderForm) {
        this.$refs.externalReaderForm.resetValidation();
      }
    },
  },
  methods: {
    async addNewReader() {
      try {
        this.externalForm = {
          ...this.externalForm,
          CONFIRM_IN: this.externalForm.CONFIRM_IN ?? false,
          CONFIRM_OUT: this.externalForm.CONFIRM_OUT ?? false,
        };
        const res = await api.externalReaders.create(this.externalForm);
        this.$emit("add-reader", res.data.DATA);
      } catch (error) {
        this.$store.dispatch(
          SNACK_ADD_MESSAGE,
          this.$t(error.response.data.MESSAGE)
        );
      }
    },
    async editReader() {
      try {
        const res = await api.externalReaders.edit(this.externalForm);
        this.$emit("change-reader", res.data.DATA);
      } catch (error) {
        this.$store.dispatch(
          SNACK_ADD_MESSAGE,
          this.$t(error.response.data.MESSAGE)
        );
      }
    },
    async formSubmit() {
      if (this.isNewExternalForm) {
        this.addNewReader();
      } else {
        this.editReader();
      }

      this.$emit("close-dialog");
    },
    getFormattedEnums(enumName) {
      if (!this.$store.getters?.getEnums[enumName]?.length) {
        return [];
      }

      return this.$store.getters.getEnums[enumName].map((reader) => ({
        text: reader.NAME,
        value: reader.NUMBER,
      }));
    },
    keydownHandler(e) {
      if (("key", e.key === "Escape")) this.$emit("close-dialog");
    },
    isTypeCamera() {
      return this.externalForm.TYPE === READER_EXTERNAL.camera
    }
  },
};
</script>
