<script>
import { REQUEST_IS_MULTIPASS, ALLOWED_PASS_TYPES } from "@/constants/index.js";
export default {
  name: "MultiPassRadioBtnNew",
  props: {
    isMultiPassProp: {
      type: [Boolean, null],
      default: null,
    },
    allowedPassTypeProp: {
      type: Number,
      default: ALLOWED_PASS_TYPES.singleAndMultiPass,
    },
    defaultPassTypeProp: {
      type: Number,
      default: ALLOWED_PASS_TYPES.singlePass,
    },
    isDisabledAll: {
      type: Boolean,
      default: false,
    },
    isNewRequestFormProp: {
      type: Boolean,
      default: false,
    },
    rulesProps: {
      type: [Array, Boolean, String],
      default: [],
    },
  },
  data() {
    return {
      value: this.isMultiPassProp,
      multiPassData: REQUEST_IS_MULTIPASS,
      defaultPassType: this.defaultPassTypeProp,
      isNewRequestForm: this.isNewRequestFormProp,
    };
  },
  watch: {
    allowedPassTypeProp: {
      handler(currentValue) {
        if (this.isDisabledAll) {
          this.multiPassData[0].disabled = true;
          this.multiPassData[1].disabled = true;
        } else if (currentValue === ALLOWED_PASS_TYPES.singleAndMultiPass) {
          this.multiPassData[0].disabled = false;
          this.multiPassData[1].disabled = false;
        } else {
          const isActiveInput = currentValue === ALLOWED_PASS_TYPES.multiPass;
          this.multiPassData[0].disabled = !isActiveInput;
          this.multiPassData[1].disabled = isActiveInput;
        }
      },
      immediate: true,
    },
    defaultPassTypeProp: {
      handler() {
        this.defaultPassType = this.defaultPassTypeProp;
        this.updateMultiPass(this.defaultPassType);
        if (this.isNewRequestFormProp) {
          this.$emit("update-state-fabric", this.value);
        }
      },
      immediate: true,
    },
    isMultiPassProp: {
      handler(multiPass) {
        if (this.isNewRequestFormProp) {
          this.value = ALLOWED_PASS_TYPES.multiPass === this.defaultPassType;
          return;
        }
        this.value = multiPass;
      },
    },
  },
  methods: {
    updateMultiPass(currentValue) {
      if (this.isNewRequestFormProp) {
        this.value = ALLOWED_PASS_TYPES.multiPass === currentValue;
      }
    },
    classRadio(item) {
      return {
        "text--primary": !item.disabled,
        "text--disabled": item.disabled,
      };
    },
  },
};
</script>

<template>
  <v-radio-group
    v-model="value"
    hide-details="auto"
    class="mb-5 mt-1"
    :rules="rulesProps"
    row
    @change="$emit('update-state-fabric', value)"
  >
    <v-radio
      v-for="n in multiPassData"
      :key="n.text"
      :value="n.value"
      :label="n.text"
      :disabled="n.disabled"
    >
      <template #label>
        <span :class="classRadio(n)">
          {{ $t(n.text) }}
        </span>
      </template>
    </v-radio>
  </v-radio-group>
</template>
