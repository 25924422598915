import moment from 'moment';

export default {
  getDmyFormattedDate(date) {
    return moment.unix(date).format('DD.MM.YYYY');
  },
  getDmyHmFormattedDate(date) {
    return moment(date).format('DD.MM.YYYY HH:mm');
  },
  getStartOfDay(date) {
    return moment(date).startOf('day').format('DD-MM-YYYY HH:mm');
  },
  getEndOfDay(date) {
    return moment(date).endOf('day').format('DD-MM-YYYY HH:mm');
  },
  getFormattedDateCustom(date, format = 'DD.MM.YYYY HH:mm') {
    return moment(date).format(format);
  },
  getToday() {
    return moment();
  },
  getTomorrow(date) {
    return moment(date).add(1, 'days');
  },
  getDaysAgo(date) {
    return moment(date).subtract(1, 'days');
  },
  getMonthAgo(date) {
    return moment(date).subtract(1, 'months');
  },
  getWeekAgo(date) {
    return moment(date).subtract(1, 'week');
  },
  sortDateByAsc(a, b) {
    return moment(a) - moment(b);
  },
  getStartDateByPeriod(PERIOD) {
    return moment(PERIOD.toString().split('-')[0], 'DD.MM.YYYY HH:mm');
  },

  getTomorrowTime({ date, hour }) {
    return this.getTomorrow(date).set({ hour, minute: 0, second: 0, millisecond: 0 }).toDate();
  },
};
