<template>
  <div class="passages scrolling-tables-static">
    <v-row class="mb-5">
      <v-col cols="auto" align-self="center">
        <h2 class="text-h2">{{ $t('Event log') }}</h2>
      </v-col>
      <v-spacer />
      <v-col cols="auto" class="d-flex flex-grow-1 justify-end">
        <v-btn
          depressed
          class="ml-4"
          color="primary"
          :outlined="filterIsEmpty"
          width="150"
          @click="showFilters = !showFilters"
        >
          <template v-if="!showFilters">
            <v-icon class="mr-2" size="20"> $filter </v-icon>
            {{ $t('Filters') }}
          </template>
          <template v-else>
            <v-icon class="mr-2" size="20"> $up </v-icon>
            {{ $t('Collapse') }}
          </template>
        </v-btn>
      </v-col>
    </v-row>

    <auto-filters-component
      v-if="filterModel.length > 0"
      ref="refAutoFiltersComponent"
      v-model="filterModel"
      :show="showFilters"
      :data="filterModel"
      :is-filters-loading="isFiltersLoading"
      @getDataFilters="applyFilter"
      @filterIsEmpty="filterIsEmpty = $event"
      @changeFilterElement="changeFilterElement"
    />

    <v-data-table
      ref="refTableObjects"
      :headers="translatedTableHeaders"
      :items="tableItems"
      sort-by="DATE"
      :sort-desc="true"
      :header-props="{ 'sort-icon': '$swap' }"
      :loading="tableLoading"
      :no-data-text="$t('Data not found')"
      :no-results-text="$t('Data not found')"
      :footer-props="{
        'items-per-page-text': $t('Records per page') + ':',
        'items-per-page-options': [15, 25, 50, 100],
      }"
      class="table-striped passages-table"
      :options.sync="tableOptions"
    >
      <template #[`item.DATE`]="{ item }">
        <span>{{
          dateHelpers.getFormattedDateCustom(item.DATE, 'DD.MM.YYYY HH:mm:ss')
        }}</span>
      </template>
      <template #[`item.IDENTIFIER`]="{ item }">
        {{
          item.DEVICE_TYPE === DEVICE_TYPE_VALUE.barrier ? item.IDENTIFIER : ''
        }}
      </template>
      <template #[`item.REQUEST_ID`]="{ item }">
        <div class="passages-link" @click="routerLink(requestsMenu.link, item)">
          {{ item.REQUEST_ID }}
        </div>
      </template>
      <template #[`item.REQUEST_V_NAME`]="{ item }">
        <div class="passages-link" @click="routerLink(requestsMenu.link, item)">
          {{ item.REQUEST_V_NAME }}
        </div>
      </template>
      <template #[`item.IS_PASSED`]="{ item }">
        <v-icon v-if="item.IS_PASSED">$check</v-icon>
      </template>
      <template #[`item.DEVICE_TYPE`]="{ item }">
        {{
          $t(DEVICE_TYPE.find((elem) => elem.value == item.DEVICE_TYPE)?.text)
        }}
      </template>
      <template #[`item.IS_ENTRY`]="{ item }">
        <span
          :class="{
            'green--text': item.IS_ENTRY,
            'red--text': !item.IS_ENTRY,
          }"
        >
          {{ directionText(item) }}
        </span>
      </template>
      <template #[`item.ACTIONS`]="{ item }">
        <div class="d-inline-flex">
          <div v-if="item.DATA" @click="showVideoDialog(item)">
            <v-icon class="mr-4" color="primary" size="24"> $videoIcon </v-icon>
          </div>
          <div
            v-if="item.DATA?.car_shot"
            class="d-block"
            @click="showImgDialog(item)"
          >
            <v-tooltip bottom color="primary">
              <template #activator="{ on, attrs }">
                <v-icon
                  class="mr-4"
                  v-bind="attrs"
                  color="primary"
                  size="24"
                  v-on="on"
                >
                  $pictureIcon
                </v-icon>
              </template>
              {{ $t('Car photo') }}
            </v-tooltip>
          </div>
          <v-tooltip v-if="item.DATA" bottom color="primary">
            <template #activator="{ on, attrs }">
              <v-icon
                class="mr-4"
                v-bind="attrs"
                color="primary"
                size="24"
                v-on="on"
                @click="showInfoDialog(item)"
              >
                $informationIcon
              </v-icon>
            </template>
            {{ $t('Information') }}
          </v-tooltip>
        </div>
      </template>
      <template #footer.page-text="{ pageStart, pageStop, itemsLength }">
        {{ pageStart }} - {{ pageStop }} {{ $t('of') }} {{ itemsLength }}
      </template>
    </v-data-table>

    <InfoDialog
      :is-dialog-active="isInfoDialogShow"
      :information="currentPassageInformation"
      @close-dialog="isInfoDialogShow = false"
    />
    <AppDialogImg
      :is-dialog-img-show="isDialogImgShow"
      :img-url="imgUrl"
      @close-dialog="isDialogImgShow = false"
    />
    <AppDialogWrapper
      :is-dialog-show="isDialogVideoShow"
      :setting="dialogWrapperSetting"
      @close-dialog="isDialogVideoShow = false"
    >
      <VideoWebSocket :item="objectItemDialog" />
    </AppDialogWrapper>
  </div>
</template>

<script>
import dateHelpers from '@/helpers/dateHelpers';
import api from '@/api';
import { requestsMenu } from '@/components/main/data.header';
import { mapGetters, mapActions } from 'vuex';
import { COMMON_SET_LOADING } from '@/store/types/mutation-types';
import { DEVICE_TYPE, DEVICE_TYPE_VALUE } from '@/constants';
import AutoFiltersComponent from '@/components/blocks/AutoFiltersComponent.vue';
import { isJsonString } from '@/helpers/helpers';
import AppDialogImg from '@/components/blocks/AppDialogImg.vue';
import AppDialogWrapper from '@/components/blocks/AppDialogWrapper.vue';
import VideoWebSocket from '@/components/blocks/VideoWebSocket.vue';
import { SNACK_ADD_MESSAGE } from '@/store/types/action-types';
import InfoDialog from './infoDialog.vue';
import { tableHeaders } from './data.passages';

export default {
  name: 'PassagesPage',
  components: {
    AutoFiltersComponent,
    InfoDialog,
    AppDialogImg,
    AppDialogWrapper,
    VideoWebSocket,
  },
  data() {
    return {
      tableOptions: {
        page: 1, // Переменная для отслеживания текущей страницы
        itemsPerPage: 15, // Количество элементов на странице (можно изменить по необходимости)
      },
      DEVICE_TYPE,
      DEVICE_TYPE_VALUE,
      dateHelpers,
      tableHeaders,
      filterModel: [],
      dataFilters: AutoFiltersComponent.props.data.type,
      isFiltersLoading: false,
      filterDateStart: null,
      filterDateEnd: null,
      filterByStatus: '',
      filterIsEmpty: false,
      organizations: [],
      showFilters: false,
      tableItems: [],
      tableLoading: false,
      tableCurrentItems: [],
      selectedBrandElement: [],
      isDialogImgShow: false,
      objectItemDialog: {},
      isDialogVideoShow: false,
      dialogWrapperSetting: {
        dialogMaxWidth: '1400px',
      },
      isInfoDialogShow: false,
      currentPassageInformation: '',
      requestsMenu,
    };
  },
  computed: {
    translatedTableHeaders() {
      return this.tableHeaders.map((elem) => ({
        ...elem,
        text: this.$t(elem.text),
      }));
    },
    dataTest() {
      return this.tableItems.filter((item) => item.DATA);
    },
    imgUrl() {
      const url =
        (this.objectItemDialog.RECOGNITION?.ip ?? '') +
        this.objectItemDialog.DATA?.car_shot;
      return url ?? this.objectItemDialog.DATA?.car_shot;
    },
    ...mapGetters([
      'carsGroupedByBrand',
      'carsBrands',
      'getEmergencyServices',
      'getListGroupOpenType',
    ]),
  },
  mounted() {
    const params = this.$route.query;

    const paramsData = Object.entries(params).reduce((acc, [key, value]) => {
      acc[key] = { key, data: value };
      return acc;
    }, {});
    this.getFilterList();
    this.applyFilter(paramsData);
    if (Object.keys(params).length) {
      if (!this.getListGroupOpenType?.length) {
        api.groupOpenType.getList().then((res) => {
          this.updateListGroupOpenType(res.data.DATA);
          this.addGroupOpenType();
        });
      } else {
        this.addGroupOpenType();
      }

      if (this.getEmergencyServices.length) {
        this.addEmergencyServicesToFilter();
      } else {
        this.$store
          .dispatch('getEmergencyServices')
          .then(() => this.addEmergencyServicesToFilter());
      }
    }
  },
  methods: {
    ...mapActions(['updateListGroupOpenType']),
    directionText(item) {
      if (item.DEVICE_TYPE === DEVICE_TYPE_VALUE.barrier) {
        return item.IS_ENTRY ? this.$t('Entry') : this.$t('Exit');
      }
      return item.IS_ENTRY ? this.$t('Entrance') : this.$t('Exit people');
    },
    addGroupOpenType() {},
    applyFilter(data = {}) {
      this.tableOptions.page = 1;
      this.dataFilters = data;
      this.$store.commit(COMMON_SET_LOADING);

      const searchData = Object.fromEntries(
        Object.keys(data).map((key) => [data[key].key, data[key].data]),
      );

      api.passages
        .searchData(searchData)
        .then((res) => (this.tableItems = res.data.DATA))
        .finally(() => this.$store.commit(COMMON_SET_LOADING, false));
    },
    changeFilterElement(element) {
      if (element.name === 'CURRENT_BRAND_ID') {
        this.selectedBrandElement = element;
        this.addModelToFilter();
        filterModel[this.getIndexFromFilter('CURRENT_MODEL_ID')].data = null;
      }
    },
    carsModels() {
      if (!this.selectedBrandElement.data) {
        filterModel[this.getIndexFromFilter('CURRENT_MODEL_ID')].disabled =
          true;
        return [];
      }

      filterModel[this.getIndexFromFilter('CURRENT_MODEL_ID')].disabled = false;
      return this.$store.getters.carsGroupedByBrand[
        this.selectedBrandElement.data
      ].map((car) => ({
        text: car.NAME,
        value: car.ID,
        translate: car.MULTI_DATA,
      }));
    },
    getIndexFromFilter(field) {
      return this.filterModel.findIndex((item) => item.name === field);
    },
    showInfoDialog(item) {
      this.currentPassageInformation = isJsonString(item.DATA)
        ? item.DATA
        : JSON.stringify(item.DATA, null, 2);
      this.isInfoDialogShow = true;
    },
    showImgDialog(item) {
      this.objectItemDialog = item;
      this.isDialogImgShow = true;
    },
    showVideoDialog(item) {
      this.objectItemDialog = item;
      this.isDialogVideoShow = true;
    },
    addEmergencyServicesToFilter() {},
    routerLink(path, item) {
      this.$router.push({ path, query: { identifier: item.IDENTIFIER } });
    },
    async getFilterList() {
      try {
        this.isFiltersLoading = true;
        const { data } = await api.filters.getData({
          i_section: 'passages',
          i_system: true,
        });
        this.filterModel = JSON.parse(data.DATA);
      } catch (error) {
        this.$store.dispatch(
          SNACK_ADD_MESSAGE,
          this.$t(error.response.data.MESSAGE),
        );
      } finally {
        this.isFiltersLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.passages-link {
  font-weight: 500;
  color: #0060ae;
  cursor: pointer;
}
</style>
