<template>
  <svg
    viewBox="0 0 94 93"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="47" cy="46.5" r="46.5" fill="#0060AE" fill-opacity="0.06" />
    <circle cx="46.2261" cy="30.2245" r="10.0757" fill="#0060AE" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M50.0372 69.2364C51.3283 70.5275 53.4063 70.5275 57.5624 70.5275C61.7184 70.5275 63.7964 70.5275 65.0875 69.2364C66.3786 67.9453 66.3786 65.8673 66.3786 61.7113C66.3786 57.5553 66.3786 55.4772 65.0875 54.1861C63.7964 52.895 61.7184 52.895 57.5624 52.895C53.4063 52.895 51.3283 52.895 50.0372 54.1861C48.7461 55.4772 48.7461 57.5553 48.7461 61.7113C48.7461 65.8673 48.7461 67.9453 50.0372 69.2364ZM56.093 60.2419H53.644C52.8325 60.2419 52.1746 60.8998 52.1746 61.7113C52.1746 62.5228 52.8325 63.1807 53.644 63.1807H56.093H59.0317H61.4807C62.2922 63.1807 62.9501 62.5228 62.9501 61.7113C62.9501 60.8998 62.2922 60.2419 61.4807 60.2419H59.0317H56.093Z"
      fill="#0060AE"
    />
    <path
      opacity="0.5"
      d="M61.5785 52.9735C60.5084 52.895 59.1956 52.895 57.5615 52.895C53.4054 52.895 51.3274 52.895 50.0363 54.1862C48.7452 55.4773 48.7452 57.5553 48.7452 61.7113C48.7452 64.6492 48.7452 66.5486 49.2013 67.8658C48.2341 67.9597 47.2402 68.0086 46.2263 68.0086C36.4881 68.0086 28.5938 63.4976 28.5938 57.9329C28.5938 52.3682 36.4881 47.8572 46.2263 47.8572C52.8093 47.8572 58.5498 49.9187 61.5785 52.9735Z"
      fill="#0060AE"
    />
  </svg>
</template>

<script>
export default {
  name: "noUserRole",
};
</script>
