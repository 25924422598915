<template>
  <div v-show="show">
    <div
      v-show="!isFiltersLoading"
      class="mb-4 pa-1"
      :style="`background-color: ${backgroundColor}`"
    >
      <div class="filter-grid">
        <div
          v-for="(filter, index) in preparedFilters"
          :key="index"
          :class="{ 'hidden-field': !filter.is_active || filter.type === null }"
        >
          <v-text-field
            v-if="isNumericOrTextField(filter.type)"
            :value="filter?.data"
            :label="filter.name"
            dense
            outlined
            hide-details="auto"
            clearable
            clear-icon="$closeCircle"
            class="filter-item white"
            :type="getFieldType(filter.type)"
            :rules="getFieldRules(filter)"
            :disabled="!!filter.disabled"
            @input="updateFilterData($event, filter)"
            @click:clear="clearField(filter)"
          />
          <date-time-picker
            v-if="filter.type === FILTER_TYPES.TIMESTAMP"
            :value="filter?.data"
            :placeholder="filter.name"
            class="filter-item__date"
            dense
            :disabled="!!filter.disabled"
            @getDate="(date) => (filter.data = date)"
          />
          <date-time-picker
            v-if="filter.type === FILTER_TYPES.DATE"
            :value="filter?.data"
            :placeholder="filter.name"
            class="filter-item__date"
            dense
            :setting="{
              format: 'YYYY.MM.DD',
            }"
            :disabled="!!filter.disabled"
            @getDate="(date) => (filter.data = date)"
          />
          <date-time-picker
            v-if="filter.type === FILTER_TYPES.TIME"
            :value="filter?.data"
            :placeholder="filter.name"
            class="filter-item__date"
            dense
            :setting="{
              type: 'time',
              format: 'HH:mm',
              iconCalendar: '$timeIcon',
            }"
            :disabled="!!filter.disabled"
            @getDate="(date) => (filter.data = date)"
          />
          <v-select
            v-if="
              filter.type === FILTER_TYPES.ENUM ||
              filter.type === FILTER_TYPES.SELECT
            "
            :value="filter?.data"
            :items="filter.possible_values"
            :label="filter.name"
            item-text="name"
            item-value="id"
            :menu-props="{
              offsetY: true,
              closeOnClick: true,
              closeOnContentClick: !filter.is_array,
            }"
            class="filter-item white"
            append-icon="$down"
            hide-details="auto"
            :multiple="filter.is_array"
            outlined
            dense
            clearable
            clear-icon="$closeCircle"
            :disabled="!!filter.disabled"
            @input="onSelect($event, filter.key)"
          />
        </div>
      </div>
      <div class="filter__actions">
        <v-btn
          depressed
          color="primary"
          class="filter-item"
          @click="getDataFilters"
        >
          {{ $t('Apply') }}
        </v-btn>
        <v-btn v-if="true" depressed color="primary" text @click="clearFilters">
          <v-icon size="18" class="mr-2"> $close </v-icon>
          {{ $t('Clear') }}
        </v-btn>
      </div>
    </div>
    <div v-if="isFiltersLoading" class="filters__skeleton">
      <v-skeleton-loader
        v-for="(_, index) in 16"
        :key="index"
        type="image"
        width="calc(25% - 10px)"
        height="40px"
      />
    </div>
  </div>
</template>
<script>
import DateTimePicker from '@/components/blocks/DateTimePicker.vue';
import { FILTER_TYPES } from '@/constants/index.js';

export default {
  name: 'AutoFiltersComponent',
  components: {
    DateTimePicker,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    backgroundColor: {
      type: String,
      default: '#eff6fa',
    },
    show: {
      type: Boolean,
      default: true,
    },
    data: {
      type: Array,
      default: () => [],
    },
    isFiltersLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      FILTER_TYPES,
      filterRules: {
        limits: (param) => [
          (v) => {
            if (param.max === undefined && param.min === undefined) {
              return true;
            }
            if (param.max === undefined) {
              return (
                v >= param.min ||
                param.error ||
                `${this.$t('The value must be greater than or equal to')} ${
                  param.min
                }`
              );
            }
            if (param.min === undefined) {
              return (
                v <= param.max ||
                param.error ||
                `${this.$t('The value must be less than or equal to')} ${
                  param.max
                }`
              );
            }
            return (
              (v <= param.max && v >= param.min) ||
              param.error ||
              `${this.$t('The value must be between')} ${param.min} ${this.$t(
                'and',
              )} ${param.max}`
            );
          },
          (v) => {
            if (!param.is_not_null) {
              return true;
            }
            return (
              (v !== null && v !== undefined && v !== '') ||
              this.$t('The field cannot be empty')
            );
          },
        ],
      },
      preparedFilters: [],
    };
  },
  created() {
    this.preparedFilters = this.value.map((filter) => {
      const preparedFilter = this.prepareFilter(filter);

      if (filter.child && filter.child.length > 0) {
        filter.child = filter.child.map((child) => this.prepareFilter(child));
      }

      return preparedFilter;
    });
  },
  methods: {
    clearFilters() {
      this.preparedFilters = this.preparedFilters.map((filter) => {
        const defaultValue = filter?.limits?.DEFAULT;

        if (filter.disabled !== undefined) {
          filter.disabled = true;
        }

        return {
          ...filter,
          data: defaultValue || null,
        };
      });
    },
    onSelect(selectedValue, parentKey) {
      const parentFilter = this.preparedFilters.find(
        (filter) => filter.key === parentKey,
      );

      if (!parentFilter) return;

      this.$set(parentFilter, 'data', selectedValue);

      if (parentFilter.child && parentFilter.child.length > 0) {
        const childFiltersMap = this.preparedFilters.reduce((acc, filter) => {
          if (filter.key) {
            acc[filter.key] = filter;
          }
          return acc;
        }, {});

        parentFilter.child.forEach((child) => {
          const childFilter = childFiltersMap[child.key];
          if (!childFilter) return;

          const parentValue = childFilter.parent_value;

          this.updatePossibleValues(childFilter, selectedValue);
          this.toggleChildFilterState(childFilter, selectedValue, parentValue);
        });
      }
    },
    updatePossibleValues(childFilter, selectedValue) {
      if (childFilter.possible_values) {
        childFilter.possible_values =
          childFilter.original_possible_values.filter(
            (item) => item.if_parent_is === selectedValue,
          );

        if (
          !childFilter.possible_values.some(
            (item) => item.value === childFilter.data,
          )
        ) {
          this.$set(childFilter, 'data', null);
        }
      }
    },
    toggleChildFilterState(childFilter, selectedValue, parentValue) {
      const isParentValid =
        selectedValue !== null &&
        (parentValue.includes(selectedValue) || parentValue.includes(-1));

      this.$set(childFilter, 'disabled', !isParentValid);

      if (isParentValid) {
        this.$set(childFilter, 'disabled', false);
      } else {
        this.$set(childFilter, 'data', null);
      }
    },

    isNumericOrTextField(type) {
      return [
        FILTER_TYPES.TEXT,
        FILTER_TYPES.BIGINT,
        FILTER_TYPES.NUMERIC,
      ].includes(type);
    },
    getFieldType(type) {
      return type === 0 ? 'text' : 'number';
    },
    getDataFilters() {
      this.$emit('getDataFilters', this.preparedFilters);
      this.$emit('applyFilter');
    },
    updateFilterData(event, filter) {
      if (filter.type === FILTER_TYPES.BIGINT && event) {
        this.$set(filter, 'data', event.replace(/[^0-9]/g, ''));
      } else if (filter.type === FILTER_TYPES.NUMERIC) {
        this.$set(
          filter,
          'data',
          event.replace(/[^0-9.]/g, '').replace(/\.(?=.*\.)/g, ''),
        );
      } else {
        this.$set(filter, 'data', event);
      }
    },
    clearField(filter) {
      this.$set(filter, 'data', null);
    },
    getFieldRules(filter) {
      return this.filterRules.limits({
        max: filter?.limits?.MAX,
        min: filter?.limits?.MIN,
        error: filter.limits_error,
        is_not_null: filter.is_not_null,
      });
    },
    prepareFilter(filter) {
      const params = this.$route.query;

      if (params[filter.key]) {
        this.$set(filter, 'data', params[filter.key]);
      }

      if (!filter.original_possible_values && filter.possible_values) {
        this.$set(filter, 'original_possible_values', [
          ...filter.possible_values,
        ]);
      }

      if (filter.data === undefined || filter.data === null) {
        this.$set(filter, 'data', filter?.limits?.DEFAULT || null);
      }

      if (filter.parent_value) {
        this.$set(filter, 'disabled', true);
      }

      return filter;
    },
  },
};
</script>

<style lang="scss">
.hidden-field {
  display: none;
}

.v-select {
  &__selections {
    flex-wrap: nowrap;
  }

  &__selections-item {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.filters__skeleton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
  padding: 10px;
}

.error-field {
  display: none;
}

.filter-item {
  margin: 8px;

  &__date {
    padding: 0 8px;
    margin-top: 8px;
  }
}

.filter__actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.filter-grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(200px, 1fr));
  align-items: start;
}
</style>
