<template>
  <v-dialog
    :value="showDialog"
    max-width="1070"
    @click:outside="closeDialog"
    @keydown="keydownHandler"
  >
    <v-card>
      <v-icon size="16" class="card__close-btn" @click="closeDialog">
        $close
      </v-icon>
      <v-card-title class="card__title-wrapper">
        <h2 class="font-weight-medium">
          {{ title }}
        </h2>
      </v-card-title>
      <div class="card__body card__body_permissions">
        <v-radio-group
          @change="onChangeUserType"
          row
          class="card__row"
          :label="$t('User type')"
          v-model="chekedUserType"
        >
          <v-radio
            v-for="(userType, index) in permissionList[7]"
            :label="`${$t(userType.name)}`"
            :key="index"
            :value="userType.name"
            :disabled="!isEditable"
          >
          </v-radio>
        </v-radio-group>
        <MessageBlockBig
          v-show="!permissionList[7].type_local.value"
          nameInLocalStorage="userTypePermissions"
        >
          <template #body>
            <span class="card__message-text"
              >{{
                $t(
                  "The user is not local. Editing permissions is not available"
                )
              }}.</span
            >
          </template>
        </MessageBlockBig>
        <div class="card__permissions-table-wrapper">
          <v-simple-table class="table-striped">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left card__table-title card__table-title">
                    {{ $t("Displaying interface sections") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="permission in permissionList[0]">
                  <td>
                    <div class="card__permission-status">
                      <div>{{ $t(`${permission.name}`) }}</div>
                      <div v-show="isEditable">
                        <v-checkbox
                          v-model="permission.value"
                          :hide-details="true"
                          color="primary"
                        />
                      </div>

                      <div v-show="!isEditable">
                        <v-icon
                          v-if="permission.value"
                          size="16"
                          color="#2DB34A"
                        >
                          $check
                        </v-icon>
                        <v-icon v-else color="red" size="14"> $close </v-icon>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div
            v-show="!permissionList[7].type_local.value"
            class="card__disabled-table"
          ></div>
        </div>

        <div class="card__permissions-table-wrapper">
          <v-simple-table class="table-striped">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left card__table-title">
                    {{ $t("Working with gates") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="permission in permissionList[1]">
                  <td>
                    <div class="card__permission-status">
                      <div>{{ $t(`${permission.name}`) }}</div>
                      <div v-show="isEditable">
                        <v-checkbox
                          v-model="permission.value"
                          :hide-details="true"
                          color="primary"
                        />
                      </div>

                      <div v-show="!isEditable">
                        <v-icon
                          v-if="permission.value"
                          size="16"
                          color="#2DB34A"
                        >
                          $check
                        </v-icon>
                        <v-icon v-else color="red" size="14"> $close </v-icon>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <MessageBlock
            v-show="canChangeAdmin"
            nameInLocalStorage="checkpointPermissions"
          >
            <template #body>
              <span class="card__message-text"
                >{{
                  $t("Editing rights to work with gates is not available")
                }}.</span
              >
              {{
                $t(
                  "Display of the “Administration” interface section is prohibited"
                )
              }}.
            </template>
          </MessageBlock>
          <div
            v-show="
              !permissionList[0].web_admin.value ||
              !permissionList[7].type_local.value
            "
            class="card__disabled-table"
          ></div>
        </div>

        <div class="card__permissions-table-wrapper">
          <v-simple-table class="table-striped">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left card__table-title">
                    {{ $t("Working with access zones") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="permission in permissionList[2]">
                  <td>
                    <div class="card__permission-status">
                      <div>{{ $t(`${permission.name}`) }}</div>
                      <div v-show="isEditable">
                        <v-checkbox
                          v-model="permission.value"
                          :hide-details="true"
                          color="primary"
                        />
                      </div>

                      <div v-show="!isEditable">
                        <v-icon
                          v-if="permission.value"
                          size="16"
                          color="#2DB34A"
                        >
                          $check
                        </v-icon>
                        <v-icon v-else color="red" size="14"> $close </v-icon>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <MessageBlock
            v-show="canChangeAdmin"
            nameInLocalStorage="accessZonesPermissions"
          >
            <template #body>
              <span class="card__message-text"
                >{{
                  $t(
                    "Editing rights to work with access zones is not available"
                  )
                }}.</span
              >
              {{
                $t(
                  "Display of the “Administration” interface section is prohibited"
                )
              }}.
            </template>
          </MessageBlock>
          <div
            v-show="
              !permissionList[0].web_admin.value ||
              !permissionList[7].type_local.value
            "
            class="card__disabled-table"
          ></div>
        </div>

        <div class="card__permissions-table-wrapper">
          <div class="card__permission-wrapper">
            <v-simple-table class="table-striped mb-5">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left card__table-title">
                      {{ $t("Working with visiting objects") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="permission in permissionList[3]">
                    <td>
                      <div class="card__permission-status">
                        <div>{{ $t(`${permission.name}`) }}</div>
                        <div v-show="isEditable">
                          <v-checkbox
                            v-model="permission.value"
                            :hide-details="true"
                            color="primary"
                          />
                        </div>

                        <div v-show="!isEditable">
                          <v-icon
                            v-if="permission.value"
                            size="16"
                            color="#2DB34A"
                          >
                            $check
                          </v-icon>
                          <v-icon v-else color="red" size="14"> $close </v-icon>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <MessageBlock
              v-show="
                !permissionList[0].web_objects.value &&
                permissionList[7].type_local.value
              "
              nameInLocalStorage="visitingObjectsPermissions"
            >
              <template #body>
                <span class="card__message-text"
                  >{{
                    $t(
                      "Editing rights to work with objects to visit is not available"
                    )
                  }}.</span
                >
                {{
                  $t(
                    "Displaying the “Objects to Visit” interface section is prohibited"
                  )
                }}.
              </template>
            </MessageBlock>
            <div
              v-show="
                !permissionList[0].web_objects.value ||
                !permissionList[7].type_local.value
              "
              class="card__disabled-table"
            ></div>
          </div>

          <div>
            <v-simple-table class="table-striped">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left card__table-title">
                      {{ $t("External user") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="permission in permissionList[6]">
                    <td>
                      <div class="card__permission-status">
                        <div>{{ $t(`${permission.name}`) }}</div>
                        <div v-show="isEditable">
                          <v-checkbox
                            v-model="permission.value"
                            :hide-details="true"
                            color="primary"
                          />
                        </div>

                        <div v-show="!isEditable">
                          <v-icon
                            v-if="permission.value"
                            size="16"
                            color="#2DB34A"
                          >
                            $check
                          </v-icon>
                          <v-icon v-else color="red" size="14"> $close </v-icon>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <MessageBlock
              v-show="canChangeAdmin"
              nameInLocalStorage="externalUserPermissions"
            >
              <template #body>
                <span class="card__message-text"
                  >{{ $t("Editing external users is not available") }}.</span
                >
                {{
                  $t(
                    "Display of the “Administration” interface section is prohibited"
                  )
                }}.
              </template>
            </MessageBlock>
            <div
              v-show="
                !permissionList[0].web_admin.value ||
                !permissionList[7].type_local.value
              "
              class="card__disabled-table"
            ></div>
          </div>
        </div>

        <div class="card__permissions-table-wrapper">
          <v-simple-table class="table-striped">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left card__table-title">
                    {{ $t("Changes in the status of the pass") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="permission in permissionList[4]">
                  <td>
                    <div class="card__permission-status">
                      <div>{{ $t(`${permission.name}`) }}</div>
                      <div v-show="isEditable">
                        <v-checkbox
                          v-model="permission.value"
                          :hide-details="true"
                          color="primary"
                        />
                      </div>

                      <div v-show="!isEditable">
                        <v-icon
                          v-if="permission.value"
                          size="16"
                          color="#2DB34A"
                        >
                          $check
                        </v-icon>
                        <v-icon v-else color="red" size="14"> $close </v-icon>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <MessageBlock
            v-show="canChangeRequest"
            nameInLocalStorage="requestStatusesPermissions"
          >
            <template #body>
              <span class="card__message-text"
                >{{
                  $t(
                    "Editing the rights to change the status of the pass is not available"
                  )
                }}.</span
              >
              {{
                $t(
                  "It is forbidden to display the “Omissions” section of the interface"
                )
              }}.
            </template>
          </MessageBlock>
          <div
            v-show="
              !permissionList[0].web_requests.value ||
              !permissionList[7].type_local.value
            "
            class="card__disabled-table"
          ></div>
        </div>

        <div class="card__permissions-table-wrapper">
          <v-simple-table class="table-striped">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left card__table-title">
                    {{ $t("Working with passes") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="permission in permissionList[5]">
                  <td>
                    <div class="card__permission-status">
                      <div>{{ $t(`${permission.name}`) }}</div>
                      <div v-show="isEditable">
                        <v-checkbox
                          class="card__checkbox"
                          v-model="permission.value"
                          :hide-details="true"
                          color="primary"
                        />
                      </div>

                      <div v-show="!isEditable">
                        <v-icon
                          v-if="permission.value"
                          size="16"
                          color="#2DB34A"
                        >
                          $check
                        </v-icon>
                        <v-icon v-else color="red" size="14"> $close </v-icon>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <MessageBlock
            v-show="canChangeRequest"
            nameInLocalStorage="requestsPermissions"
          >
            <template #body>
              <span class="card__message-text"
                >{{
                  $t(
                    "Editing the rights to change the status of the pass is not available"
                  )
                }}.</span
              >
              {{
                $t(
                  "It is forbidden to display the “Omissions” section of the interface"
                )
              }}.
            </template>
          </MessageBlock>
          <div
            v-show="
              !permissionList[0].web_requests.value ||
              !permissionList[7].type_local.value
            "
            class="card__disabled-table"
          ></div>
        </div>
      </div>

      <v-btn
        v-if="isEditable"
        depressed
        color="primary"
        width="100%"
        @click="savePermissions"
      >
        {{ $t("Save") }}
      </v-btn>
    </v-card>
  </v-dialog>
</template>

<script>
import api from "@/api";
import { permissionList } from "@/views/administration/roles/data.roles";
import { COMMON_SET_LOADING } from "@/store/types/mutation-types";
import MessageBlock from "@/components/blocks/MessageBlock.vue";
import MessageBlockBig from "@/components/blocks/MessageBlockBig.vue";

export default {
  name: "PermissionsAndProhibition",
  components: { MessageBlock, MessageBlockBig },
  props: {
    title: {
      type: String,
      required: true,
    },
    isEditable: {
      type: Boolean,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      permissionList,
      showDialog: true,
      checkbox: false,
      chekedUserType: "Local user",
    };
  },
  computed: {
    canChangeAdmin() {
      return (
        !permissionList[0].web_admin.value && permissionList[7].type_local.value
      );
    },
    canChangeRequest() {
      return (
        !permissionList[0].web_requests.value &&
        permissionList[7].type_local.value
      );
    },
  },

  async beforeMount() {
    try {
      this.$store.commit(COMMON_SET_LOADING);
      const response = await api.roles.functionalRoleGetPermission(this.id);
      const permissions = response.data.DATA.PERMISSION;
      for (const key in permissions) {
        for (const permission of permissionList) {
          if (!permission.hasOwnProperty(key)) {
            continue;
          }
          permission[key].value = permissions[key];
          const typeName = permission[key].name;
          const typeVal = permission[key].value;

          if (!typeVal) {
            continue;
          }

          if (
            typeName === "Local user" ||
            typeName === "Loading objects and passes" ||
            typeName === "External SKD"
          ) {
            this.chekedUserType = typeName;
          }
        }
      }
    } finally {
      this.$store.commit(COMMON_SET_LOADING, false);
    }
  },

  methods: {
    closeDialog() {
      this.showDialog = false;
      setTimeout(() => this.$emit("close-permissions-dialog", false), 200);
    },
    keydownHandler(e) {
      if (("key", e.key === "Escape")) this.closeDialog();
    },
    onChangeUserType(val) {
      Object.values(this.permissionList[7]).forEach((element) => {
        element.value = element.name === val;
      });
    },
    async savePermissions() {
      const permissionsObject = {};
      for (const permission of this.permissionList) {
        for (const [key, value] of Object.entries(permission)) {
          permissionsObject[`${key}`] = value.value;
        }
      }
      await api.roles.functionalRoleChangePermission(
        this.id,
        JSON.stringify(permissionsObject)
      );
      this.$emit("close-permissions-dialog");
    },
  },
};
</script>

<style lang="scss" scoped>
.v-input--selection-controls {
  padding: 0;
  margin: 0;
}
</style>
