import * as root from "./rest/root";
import * as session from "./rest/session";
import * as users from "./rest/users";
import * as config from "./rest/config";
import * as requestsConfig from "./rest/requests-config";
import * as requests from "./rest/requests";
import * as reports from "./rest/report";
import * as roles from "./rest/roles";
import * as passages from "./rest/passages";
import * as zones from "./rest/zones";
import * as devices from "./rest/devices";
import * as objects from "./rest/objects";
import * as externalSystems from "./rest/external-systems";
import * as externalReaders from './rest/external-readers';
import * as service from './rest/service.js';
import * as enums from './rest/enums.js';
import * as gates from './rest/gates.js';
import * as filters from './rest/filters.js';
import * as servers from './rest/servers';
import * as referenceBooks from './rest/reference-books';
import * as groupOpenType from './rest/group-open-type';

export default {
  root,
  session,
  users,
  config,
  requestsConfig,
  requests,
  reports,
  roles,
  passages,
  zones,
  devices,
  objects,
  externalSystems,
  externalReaders,
  service,
  enums,
  gates,
  servers,
  referenceBooks,
  groupOpenType,
  filters,
};
