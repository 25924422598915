<template>
  <div
    class="role-resolution__item role-resolution-item"
    v-if="roleStructureItem.element_id !== -1"
  >
    <div class="role-resolution__box">
      <v-icon
        v-if="roleStructureItem.child.length"
        class="role-resolution-item__item-icon mr-3"
        :class="{
          'role-resolution-item__item-icon-rotate': showList,
        }"
        size="24"
        @click="showList = !showList"
      >
        $roundArrow
      </v-icon>
      <div v-else class="role-resolution__item-circle mr-3"></div>
      <div class="role-resolution__item-content">
        <div
          class="role-resolution__item-name"
          @click="toggleShowList()"
          :class="{
            'role-resolution__item-name_click': roleStructureItem.child.length,
          }"
        >
          {{ roleStructureItem.title }}
          <v-tooltip
            v-if="roleStructureItem.description"
            bottom
            color="white"
            content-class="role-resolution__item-tooltip-shadow"
          >
            <template #activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                class="ml-2"
                color="primary"
                v-on="on"
                @click="$emit('showZones', item)"
              >
                $informationIcon
              </v-icon>
            </template>
            {{ roleStructureItem.description }}
          </v-tooltip>
        </div>
        <div class="role-resolution__item-buttons">
          <div v-if="roleStructureItem.is_element && editing">
            <v-btn
              @click="$emit('openObjectsToVisitDialog', roleStructureItem)"
              outlined
              color="primary"
              height="24px"
            >
              <v-icon class="mr-2" color="primary" size="12"> $plus </v-icon>
              {{ $t("Add an object") }}
            </v-btn>
          </div>
          <v-icon
            v-if="showDelete"
            color="red"
            @click="deleteRoleStructureItem(roleStructureItem)"
          >
            $delete
          </v-icon>
          <template v-if="roleStructureItem.is_value">
            <div class="role-resolution__hr-vertically"></div>
            <div class="role-resolution__buttons">
              <template v-for="(button, index) in buttonsRole">
                <button
                  v-if="editing || roleStructureItem.value === button.value"
                  @click="toggleRole(button.value)"
                  :class="[
                    'role-resolution__button',
                    button.class,
                    {
                      [button.activeClass]:
                        roleStructureItem.value === button.value,
                    },
                  ]"
                  :key="index"
                >
                  {{ $t(button.label) }}
                </button>
              </template>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div
      class="role-resolution__item-child"
      v-if="showChild(roleStructureItem.child)"
    >
      <role-resolution-list
        :roleStructure="roleStructureItem.child"
        :parentElement="getParentElement()"
        :editing="editing"
        @openObjectsToVisitDialog="openObjectsToVisitDialog"
        @requestRemoveConfirm="requestRemoveConfirm"
      ></role-resolution-list>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { buttonsRole } from "@/views/administration/roles/data.roles";
import { traverseTree } from "@/helpers/helpers.js";

export default {
  name: "RoleResolutionItem",
  components: {
    RoleResolutionList: () => import("./RoleResolutionList.vue"),
  },
  props: {
    roleStructureItem: {
      type: Object,
      default: () => ({}),
    },
    parentElement: {
      type: [Object, null],
      default: null,
    },
    editing: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      buttonsRole,
      showList: this.showListProps,
    };
  },
  computed: {
    ...mapState({
      showListСommon: (state) => state.role.showListСommon,
      expandAllCounter: (state) => state.role.expandAllCounter,
      roleListCommon: (state) => state.role.roleListCommon,
    }),
    showDelete() {
      return (
        (this.roleStructureItem.element_id ||
          this.roleStructureItem.element_id === 0) &&
        this.editing
      );
    },
  },
  watch: {
    expandAllCounter: {
      immediate: true,
      handler() {
        this.showList = this.showListСommon;
      },
    },
  },
  methods: {
    ...mapMutations(["updateRoleListCommon"]),

    getParentElement() {
      const structureItem = this.roleStructureItem.element_id
        ? this.roleStructureItem
        : null;
      return this.parentElement ?? structureItem;
    },

    openObjectsToVisitDialog(roleElement) {
      this.$emit("openObjectsToVisitDialog", roleElement);
    },
    toggleShowList() {
      if (!this.roleStructureItem.child.length) {
        return;
      }
      this.showList = !this.showList;
    },
    showChild(child) {
      return Array.isArray(child) && child.length && this.showList;
    },
    toggleRole(value) {
      const isMatchingNode = (node) =>
        node.element_id === this.roleStructureItem.element_id &&
        node.key === this.roleStructureItem.key;
      const applyValueUpdate = (node) => {
        node.value = value;
      };

      if (this.parentElement) {
        const updatedChildTree = traverseTree(
          this.parentElement.child,
          (node) => {
            if (isMatchingNode(node)) {
              applyValueUpdate(node);
            }
          }
        );
        const updatedRoleListCommon = traverseTree(
          _.cloneDeep(this.roleListCommon),
          (node) => {
            if (node.element_id === this.parentElement.element_id) {
              node.child = updatedChildTree;
            }
          }
        );
        this.updateRoleListCommon(updatedRoleListCommon);
      } else {
        const updatedRoleListCommon = traverseTree(
          _.cloneDeep(this.roleListCommon),
          (node) => {
            if (isMatchingNode(node)) {
              applyValueUpdate(node);
            }
          }
        );
        this.updateRoleListCommon(updatedRoleListCommon);
      }
    },
    requestRemoveConfirm(show, roleRemove) {
      this.$emit("requestRemoveConfirm", show, roleRemove);
    },
    deleteRoleStructureItem(roleStructureItem) {
      this.$emit("requestRemoveConfirm", true, roleStructureItem);
    },
  },
};
</script>

<style lang="scss">
.role-resolution {
  &__item {
    position: relative;
    display: flex;
    flex-direction: column;

    &:not(:last-child)::before {
      position: absolute;
      top: 37.5px;
      bottom: -10.5px;
      left: 14px;
      width: 1px;
      content: "";
      background-color: $stroke;
    }
  }

  &__item-tooltip-shadow {
    color: $text-black;
    box-shadow: 0 4px 13px 0 rgb(142 142 142 / 25%);
  }

  &__box {
    display: flex;
    padding: 12px 0 12px 3px;
  }

  &__hr-vertically {
    width: 1px;
    height: 24px;
    margin: 0 16px;
    background: #e3e8ea;
  }

  &__item-circle {
    width: 10px;
    height: 10px;
    margin: 7px;
    background: $primary;
    border-radius: 10px;
  }

  &__item-content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100%);

    &::after {
      position: absolute;
      bottom: -12px;
      width: 100%;
      height: 1px;
      content: "";
      background-color: $stroke;
    }
  }

  &__item-child {
    padding-left: 30px;
  }

  &__item-name {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 700;

    &_click {
      cursor: pointer;
    }
  }

  &__item-child &__item-name {
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
  }

  &__item-buttons {
    display: flex;
    gap: 4px;
  }

  &__button {
    min-height: 24px;
    padding: 0 12px;
    font-size: 12px;
    line-height: 16px;
    border-radius: 4px;

    &_green {
      color: $white;
      background-color: $accent-green;
    }

    &_red {
      color: $white;
      background-color: $accent-red;
    }

    &_gray {
      color: $white;
      background-color: $text-secondary;
    }
  }
}

.role-resolution-item {
  & &__item-icon {
    color: $primary;
    transform: rotate(180deg);
  }

  & &__item-icon-rotate {
    transform: rotate(0deg);
  }
}
</style>
