<template>
  <div>
    <v-row class="mb-5">
      <v-col cols="auto" align-self="center">
        <h2 class="text-h2">
          {{ $t("External readers") }}
        </h2>
      </v-col>
      <v-col cols="auto">
        <v-btn
          v-if="isValueStraightTreeMixin('readers_edit')"
          depressed
          color="primary"
          @click="addExternalReader"
        >
          <v-icon size="18" class="mr-2">$plus</v-icon>
          {{ $t("Add") }}
        </v-btn>
      </v-col>
      <v-col cols="auto" class="d-flex flex-grow-1 justify-end">
        <v-btn
          depressed
          class="ml-4"
          color="primary"
          :outlined="filterIsEmpty"
          width="150"
          @click="showFilters = !showFilters"
        >
          <template v-if="!showFilters">
            <v-icon class="mr-2" size="20">$filter</v-icon>
            {{ $t("Filters") }}
          </template>
          <template v-else>
            <v-icon class="mr-2" size="20">$up</v-icon>
            {{ $t("Collapse") }}
          </template>
        </v-btn>
      </v-col>
    </v-row>

    <filter-component
      v-model="filterModel"
      :show="showFilters"
      :data="filterModel"
      @getDataFilters="applyFilter"
      @filterIsEmpty="filterIsEmpty = $event"
    />
    <v-data-table
      :headers="translatedTableHeaders"
      :items="tableItems"
      sort-by="ID"
      :sort-desc="true"
      :items-per-page="-1"
      :loading="tableLoading"
      :header-props="{ 'sort-icon': '$swap' }"
      :loading-text="`${$t('Loading')}...`"
      class="table-striped"
      :no-data-text="$t('Data not found')"
      :no-results-text="$t('Data not found')"
      :footer-props="{
        'items-per-page-text': $t('Lines per page'),
        'items-per-page-options': [15, 25, 50, 100],
      }"
    >
      <template #[`item.STATUS`]="{ item }">
        <span :class="item.STATUS !== 1 ? 'red--text' : 'green--text'">
          {{ getTextFromEnum("ENUM.ReaderExternal.STATUS", item.STATUS) }}
        </span>
      </template>
      <template #[`item.PARAMETERS`]="{ item }">
        {{ item.PARAMETERS ?? "" }}
      </template>
      <template #[`item.TYPE`]="{ item }">
        <span>
          {{ getTextFromEnum("ENUM.ReaderExternal.TYPE", item.TYPE) }}
        </span>
      </template>
      <template #[`item.IS_ENTRY`]="{ item }">
        {{ item.IS_ENTRY ? $t("Entry") : $t("Exit") }}
      </template>
      <template #[`item.ACTIONS`]="{ item }">
        <div
          class="d-inline-flex"
          v-if="isValueStraightTreeMixin('readers_edit')"
        >
          <v-icon
            class="mr-4"
            color="red"
            @click="showRemoveRequestConfirm(item)"
          >
            $delete
          </v-icon>
          <v-icon
            class="mr-4"
            :color="item.STATUS !== 2 ? 'red' : 'green'"
            @click="toggleReaderStatus(item)"
          >
            $lock
          </v-icon>
          <v-icon color="primary" @click="editExternalReader(item)">
            $edit
          </v-icon>
        </div>
      </template>
      <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
        {{ pageStart }} - {{ pageStop }} {{ $t("of") }} {{ itemsLength }}
      </template>
    </v-data-table>

    <external-reader-dialog
      :is-show="isExternalFormActive"
      :is-new-external-form="isNewExternalForm"
      :external-form-props="externalReaderForm"
      @close-dialog="isExternalFormActive = false"
      @add-reader="addNewExternalReader"
      @change-reader="applyExternalReaderChange"
    />
    <confirm-remove-dialog
      v-if="requestRemoveConfirm"
      @closeDialog="requestRemoveConfirm = false"
      @confirm="removeReader"
    >
      <template #title>
        {{ $t("Remove the external reader") }} «{{ activeItem.NAME }}»?
      </template>
      <template #default>
        {{
          $t(
            "After deletion, it will be impossible to restore the data of the external reader."
          )
        }}
      </template>
    </confirm-remove-dialog>
  </div>
</template>

<script>
import api from "@/api";
import { SNACK_ADD_MESSAGE } from "@/store/types/action-types";
import { getFilterModels, tableHeaders } from "./data.externalReaders";
import FilterComponent from "@/components/blocks/FilterComponent.vue";
import ExternalReaderDialog from "@/views/administration/externalReaders/externalReaderDialog.vue";
import ConfirmRemoveDialog from "@/components/blocks/ConfirmRemoveDialog.vue";
import isValueStraightTreeMixin from "@/mixins/isValueStraightTreeMixin.js";
import { showSnackbarMessage } from '@/helpers/helpers';

export default {
  name: "ExternalReader",
  components: { FilterComponent, ExternalReaderDialog, ConfirmRemoveDialog },
  mixins: [isValueStraightTreeMixin],
  data() {
    return {
      tableHeaders,

      // filter
      filterModel: getFilterModels(),
      filterIsEmpty: true,
      showFilters: false,

      // table
      tableItems: [],
      tableLoading: false,
      requestRemoveConfirm: false,
      activeItem: null,

      // external reader dialog
      isNewExternalForm: false,
      isExternalFormActive: false,

      externalReaderForm: this.createExternalReaderForm(),

      externalReaders: [],
    };
  },
  computed: {
    translatedTableHeaders() {
      return this.tableHeaders.map((elem) => ({
        ...elem,
        text: this.$t(elem.text),
      }));
    },
  },
  async mounted() {
    const response = await api.externalReaders.getAll();
    this.externalReaders = response.data.DATA;
    this.tableItems = this.externalReaders;
    this.filterModel = getFilterModels();
    api.devices.getAll().then((res) => {
      res.data.DATA.forEach((device) => {
        this.filterModel[this.getIndexFromFilter("DEVICE")]?.items.push({
          text: device.NAME,
          value: device.ID,
        });
      });
    });
  },
  methods: {
    createExternalReaderForm(externalReader = {}) {
      return {
        ID: externalReader.ID ?? null,
        SID: externalReader.SID ?? "",
        NAME: externalReader.NAME ?? "",
        STATUS: externalReader.STATUS ?? null,
        TYPE: externalReader.TYPE ?? null,
        CONFIRM_IN: externalReader.CONFIRM_IN,
        CONFIRM_OUT: externalReader.CONFIRM_OUT,
        ADDRESS: externalReader.ADDRESS ?? "",
        PARAMETERS: JSON.stringify(externalReader.PARAMETERS) ?? "",
        IS_ENTRY: externalReader.IS_ENTRY ?? "",
        DESCRIPTION: externalReader.DESCRIPTION ?? "",
      };
    },
    async applyFilter(filterData) {
      const sid = filterData.find((item) => item.name === "SID").data;
      const name = filterData.find((item) => item.name === "NAME").data;
      const status = filterData.find((item) => item.name === "STATUS").data;
      const isEntry = filterData.find((item) => item.name === "IS_ENTRY").data;
      const device = filterData.find((item) => item.name === "DEVICE").data;
      const res = await api.externalReaders.getAll({
        sid,
        name,
        status,
        is_entry: isEntry,
        device_id: device,
      }).catch((error) => {
        showSnackbarMessage(error.response.data.MESSAGE);
      });
      this.tableItems = res.data.DATA;
    },
    getTextFromEnum(enumName, statusNumber) {
      return this.$store.getters?.getEnums[enumName]?.find(
        (status) => status.NUMBER === statusNumber
      ).NAME;
    },
    async toggleReaderStatus(item) {
      try {
        const response = await api.externalReaders.changeStatus(
          item.ID,
          item.STATUS === 1 ? 2 : 1
        );
        item.STATUS = response.data.DATA.STATUS;
      } catch (error) {
        showSnackbarMessage(error.response.data.MESSAGE);
      }
    },
    async removeReader() {
      try {
        await api.externalReaders.remove(this.activeItem.ID);
        const currentReaderIndex = this.tableItems.findIndex((reader) => {
          return reader.ID === this.activeItem.ID;
        });
        this.tableItems.splice(currentReaderIndex, 1);
      } catch (error) {
        showSnackbarMessage(error.response.data.MESSAGE);
      } finally {
        this.requestRemoveConfirm = false;
      }
    },
    addExternalReader() {
      this.externalReaderForm = this.createExternalReaderForm();
      this.isNewExternalForm = true;
      this.isExternalFormActive = true;
    },
    editExternalReader(externalReader) {
      this.externalReaderForm = this.createExternalReaderForm(externalReader);
      this.isNewExternalForm = false;
      this.isExternalFormActive = true;
    },
    addNewExternalReader(externalReader) {
      this.externalReaders.push(externalReader);
    },
    applyExternalReaderChange(externalReader) {
      const readerIndex = this.externalReaders.findIndex(
        (reader) => reader.ID === externalReader.ID
      );
      this.$set(this.tableItems, readerIndex, externalReader);
    },
    showRemoveRequestConfirm(item) {
      this.activeItem = item;
      this.requestRemoveConfirm = true;
    },
    getIndexFromFilter(field) {
      return this.filterModel.findIndex((item) => item.name === field);
    },
  },
};
</script>
