<template>
  <v-data-table
    class="table-striped requests-table"
    loading-text="Загрузка..."
    sort-by="ID"
    :footer-props="{
      'items-per-page-text': $t('Records per page') + ':',
      'items-per-page-options': [15, 25, 50, 100],
    }"
    :headers="translatedTableHeaders"
    :header-props="{ 'sort-icon': '$swap' }"
    :items="objects"
    :items-per-page="-1"
    :loading="tableLoading"
    :sort-desc="true"
    :no-data-text="$t('Data not found')"
    :no-results-text="$t('Data not found')"
    :options.sync="tableOptions"
    @update:options="handleOptionsChange"
  >
    <template #[`item.ACTIONS`]="{ item }">
      <div class="d-inline-flex">
        <v-icon color="primary" v-if="showPrinterIcon(item)" @click="printContent(item)">
          $printerIcon
        </v-icon>
        <v-tooltip bottom color="primary">
          <template #activator="{ on, attrs }">
            <router-link :to="linkPassages(item)">
              <v-icon v-bind="attrs" class="ml-4" color="primary" v-on="on">
                $document
              </v-icon>
            </router-link>
          </template>
          {{ item.TYPE_ID === $store.getters.getEnums['ENUM.Request.TYPE'].find(
            type => type.NAME === 'Пропуск на парковку')?.NUMBER
          ? $t('Driveways')
          : $t('Passages')}}
        </v-tooltip>
        <template v-if="item.SYSTEM_ID === 0">
          <v-icon class="ml-4" color="red" @click="$emit('delete', item)">
            $delete
          </v-icon>
          <v-icon class="ml-4" color="primary" @click="$emit('edit', item)">
            $edit
          </v-icon>
        </template>
      </div>
    </template>
    <template #[`item.STATE`]="{ item }">
      <span :class="REQUEST_STATUSES.find((elem) => elem.value == item.STATE)?.colorClassName">
        {{ item.STATE_NAME }}
      </span>
    </template>
    <template #[`item.CODE`]="{ item }">
      {{ isPass(item) ? '' : item.CODE }}
    </template>
    <template #[`item.MULTI_PASS`]="{ item }">
      {{ item.MULTI_PASS_NAME }}
    </template>
    <template #[`item.INSPECTION`]="{ item }">
      <v-icon v-if="item.INSPECTION" class="requests-table__inspection" size="24">
        $check
      </v-icon>
    </template>
    <template #[`item.LOGO`]="{ item }">
      <img
        :src="
          item.LOGO ? convertUserLogo(item.LOGO) : require('../../assets/images/default-avatar.png')
        "
        style="width: 25px; height: 25px"
        alt="logo"
      >
    </template>
    <template #[`item.LOGO_WALLET`]="{ item }">
      <img
        :src="
          item.LOGO
            ? convertUserLogo(item.LOGO_WALLET)
            : require('../../assets/images/default-avatar.png')
        "
        style="width: 25px; height: 25px"
        alt="logo"
      >
    </template>
    <template #[`item.DATA.CAR_BRAND`]="{ item }">
      {{ item.DATA.CAR_BRAND_NAME }}
    </template>
    <template #[`item.DATA.CAR_MODEL`]="{ item }">
      {{ item.DATA.CAR_MODEL_NAME }}
    </template>
    <template #[`item.DATE_BEGIN`]="{ item }">
      {{ getDateText(item.DATE_BEGIN) }}
    </template>
    <template #[`item.DATE_END`]="{ item }">
      {{ getDateText(item.DATE_END) }}
    </template>
    <template #[`item.DATE_FIRST_PASS`]="{ item }">
      {{ getPassageDateText(item.DATE_FIRST_PASS) }}
    </template>
    <template #[`item.DATE_LAST_EXIT`]="{ item }">
      {{ getPassageDateText(item.DATE_LAST_EXIT) }}
    </template>
    <template #[`item.INSIDE`]="{ item }">
      {{ item.INSIDE_NAME }}
    </template>
    <template #[`item.REQUEST_V_NAME`]="{ item }">
      {{ item.DATA.V_NAME }}
    </template>
    <template #[`item.SEND_ERR`]="{ item }">
      <div class="d-flex justify-space-between">
        {{ $t(EMAIL_SEND_ERR_IN.find((elem) => elem.value === item.SEND_ERR)?.text) }}
        <v-tooltip v-if="item.SEND_ERR === true" bottom color="primary">
          <template #activator="{ on, attrs }">
            <v-icon v-bind="attrs" color="error" v-on="on">
              $attention
            </v-icon>
          </template>
          {{ item.SEND_MSG || "Неизвестная ошибка" }}
        </v-tooltip>
      </div>
    </template>
    <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
      {{ pageStart }} - {{ pageStop }} {{ $t('of') }} {{ itemsLength }}
    </template>
  </v-data-table>
</template>

<script>
import api from '@/api';
import {
  REQUEST_STATUSES,
  REQUEST_IS_MULTIPASS,
  FLAG_INSIDE,
  EMAIL_SEND_ERR_IN,
  REQUEST_TYPES,
} from '@/constants';
import dateHelpers from '@/helpers/dateHelpers';
import { mapGetters } from 'vuex';

export default {
  name: 'LocalObjectsTable',
  props: {
    objects: {
      type: Array,
      default() {
        return [];
      },
    },
    tableHeaders: {
      type: Array,
      required: true,
    },
    tableOptionsProps: {
      type: Object,
      default: {
        page: 1, // Переменная для отслеживания текущей страницы
        itemsPerPage: 15, // Количество элементов на странице (можно изменить по необходимости)
      },
    }
  },
  data() {
    return {
      tableOptions: this.tableOptionsProps,
      REQUEST_STATUSES,
      REQUEST_IS_MULTIPASS,
      FLAG_INSIDE,
      dateHelpers,
      EMAIL_SEND_ERR_IN,
      REQUEST_TYPES,
      tableLoading: false,
      allUsers: [],
    };
  },
  computed: {
    ...mapGetters([
      'getEnums',
    ]),
    translatedTableHeaders() {
      return this.tableHeaders.map((elem) => ({ ...elem, text: this.$t(elem.text) }));
    },
  },
  watch: {
    tableOptionsProps(newValue) {
      this.tableOptions = newValue;
    }
  },
  mounted() {
    api.users.getShortList().then((res) => res.data.DATA.forEach((user) => this.allUsers.push({
      text: user.NAME,
      value: user.ID,
    })));
  },
  methods: {
    linkPassages(item) {
      return this.isPass(item) ? `/passages?id=${item.ID}` : `/passages?identifier=${item.CODE}`;
    },
    showPrinterIcon(item) {
      const confirmedValue =  REQUEST_STATUSES.find((e) => e.text === 'Confirmed').value;
      return (this.isPass(item) && item.STATE === confirmedValue);
    },
    isPass(item) {
      return item.TYPE_ID === REQUEST_TYPES.find((type) => type.text === 'Admission to the visit').value
    },
    async printContent(item) {
      this.$emit('print', item);
    },
    convertUserLogo(logo) {
      if (!logo) return '';
      if (logo.includes('data:image/png;base64,')) return logo;
      return `data:image/png;base64, ${logo}`;
    },
    getDateText(date) {
     return date === null ? this.$t('not limited') : this.dateHelpers.getDmyHmFormattedDate(date);
    },
    getPassageDateText(date) {
     return date === null ? '' : this.dateHelpers.getFormattedDateCustom(date, 'DD.MM.YYYY HH:mm:ss');
    },
    handleOptionsChange(newOptions) {
      this.tableOptions = newOptions;
      this.$emit('table-options', newOptions)
    }
  },
};
</script>

<style lang="scss">

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  height: 36px;
}

.requests-table {
 & &__inspection {
  color: $accent-green;
 }
}
</style>
