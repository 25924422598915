<script>
import upIcon from '../icons/up-icon.vue';
import api from '@/api'
import { REQUEST_TYPES } from '@/constants/index'
import { showSnackbarMessage } from '@/helpers/helpers';
export default {
  components: { upIcon },
  name: 'SelectedCarItem',
  props: {
    car: {
      type: Object,
      required: true,
    },
    filteredSelectedCar: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      enumRequestState: [],
      openFillInformation: false,
    }
  },
  mounted() {
    if (this.$store.getters.getEnums['ENUM.Request.STATE']?.length) {
      const enumState = [];
      this.$store.getters.getEnums['ENUM.Request.STATE'].forEach((item) => {
        enumState.push({
          text: item.NAME,
          value: item.NUMBER,
        });
      });
      this.enumRequestState = enumState;
    }
  },
  methods:{
    updateStatus(car, state) {
      api.requests.changeState(car.ID, state).then((res)=>{
        this.$emit('updateSelectedCar', {oldCar: car, newCar: res.data.DATA});
        showSnackbarMessage(res.data.MESSAGE);

      }).catch((error) => {
        showSnackbarMessage(error.response.data.MESSAGE);
      })
    },
    clickIconDown() {
      this.openFillInformation = !this.openFillInformation
    },
    isParkingPass() {
      return REQUEST_TYPES.find((type) => type.text === 'Parking pass').value
    },
    isFullName() {
      return this.car.DATA.V_NAME && this.car.TYPE_ID === this.isParkingPass()
    },
  },
  computed: {
    showItemChangeStatus() {
      const isCanUpgradeLevel = Object.values(this.car.IS_MOD.edit_status).includes(Math.min(this.car.STATE + 1, 3));

      if (!this.car.IS_MOD.parameters && !isCanUpgradeLevel ) {
        return false
      }
      return this.car.STATE !== 3 && this.car.STATE !== 4;
    },
    isStatusesQuantitativeChoice() {
      const isValidState = (state) => state <= 3 && Object.values(this.car.IS_MOD.edit_status).includes(state);

      return isValidState(this.car.STATE + 1) && isValidState(this.car.STATE + 2);
    },
    incrementStatusAndGetState() {
      const statusId = Math.min(this.car.STATE + 1, 3);
      return this.enumRequestState.find(x => x.value === statusId)
    },
    requestStatuses() {
      return this.enumRequestState
        .filter(status => status.value !== null
        && this.car.STATE <= status.value
        && status.value !== 4
        && this.car.IS_MOD?.edit_status.includes(status.value))
        .map(status => ({
          ...status,
          text: this.$t(status.text)
        }));
    },
  },
}
</script>

<template>
  <div>
    <div class="selected-car__item-header">
      <div
        class="selected-car__status"
        :class="[
            car.colorClassName,
            car.backgroundColor,
        ]"
      >
        {{ car.STATE_NAME }}
      </div>
      <v-icon
        class="selected-car__status-change"
        size="20"
        @click="$emit('editPass', car)"
        v-if="car.STATE !== 4"
      >
        $edit
      </v-icon>
    </div>
    <div class="selected-car__item-main">
      <div class="item-main__box" v-if="car.DATE_BEGIN || car.DATE_END">
        <div class="item-main__label">{{ $t('Validity') }}</div>
        <div class="item-main__meaning">
          <span v-if="car.DATE_BEGIN">{{ car.DATE_BEGIN }}</span>
          <span v-if="car.DATE_BEGIN && car.DATE_END"> - </span>
          <span v-if="car.DATE_END">{{ car.DATE_END }}</span>
        </div>
      </div>
      <div class="item-main__box" v-if="car.OBJECT_NAME">
        <div class="item-main__label">{{ $t('Object of visit') }}</div>
        <div class="item-main__meaning">{{ car.OBJECT_NAME }}</div>
      </div>
      <div v-show="openFillInformation" class="selected-car__hiden-item-main">
        <div class="item-main__box" v-if="car.DATA.CAR_BRAND_NAME || car.DATA.CAR_MODEL_NAME">
          <div class="item-main__label">{{ $t('Make and model') }}</div>
          <div class="item-main__meaning">
            <span v-if="car.DATA.CAR_BRAND_NAME">{{ car.DATA.CAR_BRAND_NAME }}</span>
            <span v-if="car.DATA.CAR_BRAND_NAME && car.DATA.CAR_MODEL_NAME">, </span>
            <span v-if="car.DATA.CAR_MODEL_NAME">{{ car.DATA.CAR_MODEL_NAME }}</span>
          </div>
        </div>
        <div class="item-main__box" v-if="isFullName()">
          <div class="item-main__label">{{ $t('Full name of the visitor') }}</div>
          <div class="item-main__meaning">
            {{ car.DATA.V_NAME }}
          </div>
        </div>
        <div class="item-main__box" v-if="car.CREATE_USER_NAME">
          <div class="item-main__label">{{ $t('The user who created the pass') }}</div>
          <div class="item-main__meaning">
            {{ car.CREATE_USER_NAME }}
          </div>
        </div>
        <div class="item-main__box" v-if="car.DATA.V_PHONE">
          <div class="item-main__label">{{ $t('Phone number') }}</div>
          <div class="item-main__meaning">
            {{ car.DATA.V_PHONE }}</div>
        </div>
        <div class="item-main__box" v-if="car.DATA.V_EMAIL">
          <div class="item-main__label">{{ $t('Email') }}</div>
          <div class="item-main__meaning">
            {{ car.DATA.V_EMAIL }}
          </div>
        </div>
      </div>
      <v-menu
        :close-on-content-click="false"
        v-model="car.menu"
        max-width="170"
        offset-x
        z-index="1"
      >
        <template v-slot:activator="{ on, attrs }">
          <div
            class="selected-car__item-change-status"
            v-if="showItemChangeStatus"
            @click="isStatusesQuantitativeChoice? null : updateStatus(car, Math.min(car.STATE + 1, 3))"
            v-bind="isStatusesQuantitativeChoice && attrs"
            v-on="isStatusesQuantitativeChoice && on"
          >
            {{ $t('Transfer to the status') }}
            <span v-if="isStatusesQuantitativeChoice">
              ...
              <v-icon
                class="selected-car__item-change-status_icon"
                size="16"
              >
                $doubleAltArrowIcon
              </v-icon>
            </span>
            <span v-else>
              &nbsp;-&nbsp;
              <span class="selected-car__item-change-status_bold">
                {{ incrementStatusAndGetState?.text }}
              </span>
            </span>
          </div>
        </template>
        <div class="selected-car__dropdown-list">
          <div
            v-for="status in requestStatuses"
            :key="status.value + status.text"
            class="selected-car__dropdown-item"
            @click="updateStatus(car, status.value)"
          >
            {{ status.text }}
          <v-icon
            class="selected-car__dropdown-item-icon"
            v-if="status.value === car.STATE"
            size="20"
          >
            $check
          </v-icon>
          </div>
        </div>
      </v-menu>
    </div>
    <div class="selected-car__item-footer" @click="clickIconDown()">
      <v-icon class="mt-1 mb-1 primary--text" :class="{'selected-car__item-footer_active': openFillInformation}" size="20">
        $down
      </v-icon>
    </div>
  </div>
</template>

<style lang="scss">
.selected-car {
  &__item-main {
    padding: 8px 12px;

    .item-main__box:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__item-header {
    display: flex;
    justify-content: space-between;
    padding: 8px 12px;
    background-color: $background-secondary;
    border-radius: 8px 8px 0 0;
  }

  &__item-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $background-secondary;
    border-radius: 0 0 8px 8px;
  }

  &__item-footer_active {
    transform: rotate(180deg);
  }

  &__status {
    display: flex;
    align-items: center;
    padding: 4px 8px;
    border-radius: 50px;
  }

  &__status-text {
    font-size: 14px;
    line-height: 16px;
    color: $primary;
  }

  & &__status-change {
    color: $primary;
    fill: $primary;
  }

  &__item-change-status {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7.5px;
    margin-top: 8px;
    font-size: 14px;
    line-height: 16px;
    color: $primary;
    background: #E1EDF4;
    border-radius: 6px;
  }

  &__item-change-status_bold {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
  }

  & &__item-change-status_icon {
    color: $primary;
    fill: $primary;
  }

  &__dropdown-list {
    background-color: $white;
  }

  &__dropdown-item {
    display: flex;
    gap: 12px;
    align-items: center;
    padding: 10px 12px;
    font-size: 14px;
    line-height: 16px;
  }

  &__dropdown-item &__dropdown-item-icon {
    color: $primary;
    fill: $primary;
  }
}
</style>
